.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  /*background: url('./assets/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;*/
  background-color: var(--color-white);
}

.notfound__bg {
	background-color: var(--color-white) !important;	
}
.pprogress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--color-primary);
  transform-origin: 0%;
  z-index: 2;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--color-primary2);
  transform-origin: 0%;
  z-index: 2;
}

.mprogress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--color-primary3);
  transform-origin: 0%;
  z-index: 2;
}

.restaurant_bg{	
	z-index: 50;
}

.wave {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 75vh;
	--fill: #e9edf1;
	z-index: 0;
}
.wave svg {
	height: 320px;
	width: 100%;
	transform: translateY(16px);
}
.wave svg path {
	fill: var(--fill);
}
.wave div {
	height: 100%;
	width: 100%;
	background: var(--fill);
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  border-bottom: 1px solid var(--color-primary2);
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
	background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 18px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-black);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 25px;
  text-transform: uppercase;
  padding: 0px !important;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white) !important;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 18px;
  padding: 0px !important;
  cursor: pointer;
}

.p__opensans:hover {
	border-bottom: 1px solid #fff;
}

.p__mont {
  font-family: var(--font-mont);
  color: var(--custom-dark);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 25px;
  font-size: 18px;
  padding: 0px !important;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--custom-dark);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 45px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 2rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
	
	.section__padding {
    padding: 0;
  }
}



.h3, .h3-responsive, h3, h3-responsive {
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
}
.h3, h3.landing__section-list-header {
  font-size: 18px;
  line-height: 24px;
  text-transform: none;
}
.h3, h3 {
  font-size: 1.75rem;
}
.h2, h2 {  
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: bold;
  line-height: 1.2;
}
/*
p {
  font-size: 1.15rem;
  line-height: 28px;
  font-weight: bolder;
  margin-bottom: 1rem;
}

ul, span {
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 16px;
  font-weight: 400;
}
*/

.app__navbar-logo{
	font-family: var(--font-mont);
}



.form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 10px;
}


.app__header {
    background-color: var(--color-white);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-primary2);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 100px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }	
	.form {
		width: 30rem;
		padding: 20px;
	}	
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }	
	.form {
		width: 80%;
		padding: 5px;
	}	
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
	.form {
		width: 95%;
		padding: 5px;
	}	
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }	
	.form {
		width: 100%;
		padding: 2px;
	}	
}




.maincontainer {
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.svgimg{
	margin-top: -250px;
}
@media (max-width: 62.22rem) {
  .maincontainer {
    width: 100%;
    flex-direction: column;
  }
  .svgimg{
	margin-top: -100px;
	padding-bottom: 500px;
  }
}
@media (max-width: 30rem) {
  .maincontainer {
    margin-top: 0.5rem;
  }
  .svgimg{
	margin-top: -100px;
	padding-bottom: 500px;
  }
}

.card {
  position: relative;
  width: 23rem;
  height: 16rem;
  margin-bottom: 3rem;
}
@media (max-width: 30rem) {
  .card {
    width: 20.22rem;
    height: 12.75rem;
    margin-bottom: 1.75rem;
  }
}
.card > .card-top, .card > .card-bottom {
  width: 21rem;
  height: 13rem;
  border-radius: 0.6rem;
}

.card > .card-top {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: linear-gradient(120deg, #0ECB81, #257A80);
  box-shadow: 1rem 1rem 2.75rem #D7BCC4;
}

.card > .card-top, .card > .card-top * {
  color: var(--color-white);
  font-family: var(--font-mont);
}
.card > .card-top::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: no-repeat center;
  background-size: cover;
  opacity: 0.04;
}
.card > .card-top > .logo {
  position: absolute;
  top: 0.85rem;
  right: 0.55rem;
}
.card > .card-top > .logo > svg {
  width: 4rem;
}
.card > .card-top > .card-number {
  position: absolute;
  top: 45%;
  left: 2rem;
  width: 70%;
  opacity: 0.75;
  font-size: 1.25rem;
}
.card > .card-top > .row-container {
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}
.card > .card-top > .row-container > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card > .card-top > .row-container > div > span {
  height: 1rem;
  margin-bottom: 0.5rem;
  padding: 0;
}

.card > .card-bottom {
  position: absolute;
  content: "";
  top: 2.75rem;
  left: 2.75rem;
  background: #EFF1F4;
  box-shadow: 0.5rem 0.5rem 2.75rem #ADA9AB;
}
@media (max-width: 30rem) {
  .card > .card-bottom {
    display: none;
  }
}
.card > .card-bottom::after {
  position: absolute;
  content: "";
  top: 2.55rem;
  width: 100%;
  height: 2.55rem;
  background: #E2E0E1;
}
.row-flex {
  flex-direction: row;
  display: flex;
}
.form-header{
	font-size: 35px;
	font-family: var(--font-base);
	text-transform: uppercase;
	color: var(--custom-dark); 
}
.form fieldset {
  border: none;
  margin-top: 1rem;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.form fieldset input, .form fieldset select {
  width: 100%;
  padding: 0.5rem 1rem;
  outline: none;
}

.form fieldset input.text-input-half, .form fieldset select.text-input-half {
  width: 98%;
  padding: 0.5rem 1rem;
  outline: none;
  background-color: var(--color-white);
}
.form fieldset.card-number input, .form fieldset.card-holder input {  
  background: transparent;
}
.form .row-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.form .row-container fieldset {
  flex: 1;
  margin-right: 1.25rem;
}
.form .row-container fieldset:nth-child(3) {
  margin-right: 0;
}
.form .payment-details {
  margin-top: 1rem;
  width: 100%;
}
.form .payment-details > div {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.form button {
  color: var(--color-primary);
  cursor: pointer;
  outline: none;
  background: white;
  border: solid 1px var(--color-primary);
  border-radius: 0.2rem;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  text-transform: uppercase;
  font-weight: bolder;
  font-family: var(--font-alt);
}
.form button:hover {
  color: var(--color-white);
  background: var(--color-primary);
}


.incsizespansms {
    border: 1px solid var(--custom-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--custom-dark);
	background: var(--custom-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: flow-root;
	width: 100%;
	font-size: 18px;
}


.incsizespan {
    border: 1px solid var(--custom-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--custom-dark);
	background: var(--custom-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: none;
	font-size: 18px;
}

.incsizespan_error {
    border: 1px solid var(--error-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--error-dark);
	background: var(--error-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: block;
	font-size: 18px;
}

.incsizespan_success{
	border: 1px solid var(--success-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--success-dark);
	background: var(--success-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: block;
	font-size: 18px;
}

.incsizespan_info{
	border: 1px solid var(--info-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--info-dark);
	background: var(--info-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: block;
	font-size: 18px;
}

.text-input {  
	border: 1px solid var(--color-primary);
	border-radius: 5px;
	position: relative;
	z-index: 0;	
	padding: 0 20px;
	color: var(--color-primary);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 100%;
	font-family: var(--font-base);
	letter-spacing: 0.2rem;
	
}

.text-input-half {  
	border: 1px solid var(--color-primary);
	border-radius: 5px;
	position: relative;
	z-index: 0;	
	padding: 0 20px;
	color: var(--color-primary);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 49%;
	font-family: var(--font-base);
	letter-spacing: 0.2rem;
}

section {
	padding: 60px 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.contact-area {
    border-bottom: 1px solid var(--color-white);
	background: #0ECB81;
    color: #f2f2f2;
}

.contact-content p {
    font-size: 15px;
    margin: 30px 0 60px;
    position: relative;
}

.contact-content p::after {
    background: var(--color-white);
    bottom: -30px;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    width: 80%;
}

.contact-content h6 {
    color: #8b9199;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}

.contact-content span {
    color: #353c47;
    margin: 0 10px;
}

.contact-social {
    margin-top: 30px;
}

.contact-social > ul {
    display: inline-flex;
}

.contact-social ul li a {
    border: 1px solid var(--color-white);
    color: var(--color-white);
    display: inline-block;
    height: 40px;
    margin: 0 10px;
    padding-top: 7px;
    transition: all 0.4s ease 0s;
    width: 40px;
}

.contact-social ul li a:hover {
    border: 1px solid #FAB702;
    color: #FAB702;
}

.contact-content img {
    max-width: 210px;
}

footer p {
    padding: 40px 0;
    text-align: center;
}

footer img {
    width: 44px;
}
.container {
	max-width: 100%;
}
@media (max-width: 62.22rem) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 30rem) {
  .container {
    max-width: 100%;
  }
}

.text-headingColor{
	color: var(--custom-dark);
}

.card-holder, .card-number{
	color: var(--custom-dark) !important;
	font-size: 18px;
}

legend, input:placeholder, textarea:placeholder{
	color: var(--custom-dark) !important;
	font-size: 18px;
}

.isfocus {
    border-bottom: 2px solid #0ECB81;
    color: #257A80;
	font-weight: bold;
}
.learn-more {
	color: #257A80;
  font-weight: 700;
  text-decoration: underline;
  margin-top: 26px;
  display: inline-flex !important;
  cursor: pointer;
}
.learn-more span{
	display: inline-flex !important;
}
.arrow-right--icon{
	margin-top: 5px;
	margin-left: 10px;
}


body {
  background: #f8f8f8;
  font-size: 1.6rem;
  font-family: var(--font-base);
}
body, .typology-action-button .sub-menu {
  color: #444444;
}
body {
  line-height: 1.6;
}
body, fieldset {
  margin: 0;
}

.typology-post:after, .section-title:after, .typology-pagination:before {
  background: rgba(51,51,51,0.2);
}
.section-title:after, .typology-post:after {
  content: "";
  height: 1px;
  width: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -25px;
  background: #333;
}
.typology-layout-a {
  width: 100%;
}
.typology-post {
  position: relative;
  margin-bottom: 9rem;
  padding-bottom: 10rem;
}
article, aside, details, figcaption, figure, footer, header, legend, main, menu, nav, section, summary {
  display: block;
}
p a{
	color: blue;
}
.entry-footer, .entry-header {
  position: relative;
  z-index: 2;
}
.entry-header {
  margin-bottom: 2.6rem;
}
h1, .h1 {
  font-size: 4.8rem;
}
.h1, .typology-cover .entry-title, .typology-cover h1, h1 {
  line-height: 1.2;
}
.entry-meta {
  margin-top: .8rem;
}

.meta-item:after {
  content: "/";
  right: -7px;
  position: relative;
}

.meta-item {
  text-transform: none;
}
.meta-item, .meta-item span, .meta-item a, .comment-metadata a {
  color: #888888;
}
.typology-layout-b .post-date-hidden, .meta-item {
  font-size: 1.3rem;
}
.meta-item {
  margin-right: 15px;
}
.meta-item {
  display: inline-table;
  vertical-align: top;
}

.entry-footer {
  margin-top: 3rem;
}
.entry-footer, .entry-header {
  position: relative;
  z-index: 2;
}
.entry-footer .typology-button {
  margin-right: 1.5rem;
}
.typology-button {
  text-transform: uppercase;
}
.submit, .mks_read_more a, input[type="submit"], input[type="button"], a.mks_button, .typology-button, .submit, .typology-button-social, .page-template-template-authors .typology-author .typology-button-social, .widget .mks_autor_link_wrap a, .widget .meks-instagram-follow-link a, .widget .mks_read_more a, button, body div.wpforms-container-full .wpforms-form input[type="submit"], body div.wpforms-container-full .wpforms-form button[type="submit"], body div.wpforms-container-full .wpforms-form .wpforms-page-button {
  color: var(--color-white);;
  background: #83b541;
  border: 1px solid #83b541;
}
.mks_read_more a, .submit, .typology-button, .widget .mks_autor_link_wrap a, .widget .mks_read_more a, a.mks_button, body div.wpforms-container-full .wpforms-form .wpforms-page-button, button[type="submit"], input[type="submit"], button, input[type="button"] {
  height: 40px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 0 20px;
  line-height: 42px;
  min-width: 160px;
  font-size: 1.1rem;
  border: 1px solid;
  font-weight: 400;
  outline: 0;
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all .1s ease-in-out;
  -moz-transition: all .1s ease-in-out;
  -ms-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

[role="button"], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.post-letter {
  font-size: 26.0rem;
}
.post-letter {
  line-height: 1.2;
  color: rgba(0,0,0,.07);
  z-index: 1;
  position: absolute;
  top: 50%;
  left: -9rem;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  transform: translate(0,-50%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.typology-fake-bg .typology-section:first-child {
  position: relative;
  z-index: 900;
  margin-bottom: 0;
}
.typology-sidebar, .typology-section {
  background: var(--color-white);;
}
.typology-section {
  background: transparent !important;
  padding: 60px 0px !important;
}
.typology-section {
  -webkit-box-shadow: 0 30px 50px 0 rgba(1,1,1,.15);
  box-shadow: 0 30px 50px 0 rgba(1,1,1,.15);
  max-width: 1170px;
  margin: 0 auto 10rem;
  width: 95%;
}
.typology-fake-bg {
  background: #f8f8f8;
}
.typology-fake-bg {
  position: relative;
  /*z-index: 9000;*/
}
.typology-posts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.single .typology-section:first-child .section-
content, .section-content-page, .section-content.section-content-a {
  max-width: 720px;
}
.typology-section > :last-child {
  margin-bottom: 0;
}
.section-content {
  max-width: 720px;
  margin: 0 auto 12rem;
}

btn-group-vertical > .btn-group:after, .btn-toolbar:after, .clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .form-horizontal .form-group:after, .modal-footer:after, .nav:after, .navbar-collapse:after, .navbar-header:after, .navbar:after, .pager:after, .panel-body:after, .row:after {
  clear: both;
}
btn-group-vertical > .btn-group:after, .btn-group-vertical > .btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
  content: " ";
  display: table;
}
html :selection {
  background: #623499;
  color: var(--color-white);;
}


.mb-md-0, .my-md-0 {
  margin-bottom: 0 !important;
}
.mb-5, .my-5 {
  margin-bottom: 2rem !important;
}
.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto, .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-auto, .col-xxl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-auto, .col-xxs, .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12, .col-xxs-auto, .col-xxxs, .col-xxxs-1, .col-xxxs-2, .col-xxxs-3, .col-xxxs-4, .col-xxxs-5, .col-xxxs-6, .col-xxxs-7, .col-xxxs-8, .col-xxxs-9, .col-xxxs-10, .col-xxxs-11, .col-xxxs-12, .col-xxxs-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}
.col-md-6 {
  width: 50%;
}
.col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
  float: left;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  position: relative;
  min-height: 1px;
}
.regular-28 {
  font-size: 1rem;
  line-height: 28px;
}
.landing .h1, .landing .h2, .landing .h3, .landing .h4, .landing .h5, .landing .h6, .landing h1, .landing h2, .landing h3, .landing h4, .landing h5, .landing h6 {
  margin: 32px 0 24px;
}
.landing__section-list-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.list-bullets-styled {
  padding-left: 0;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.list-bullets-styled li {
  line-height: inherit;
  margin-bottom: 8px;
  padding-left: 15px;
  position: relative;
  list-style-type: none;
}

ul.list-bullets-styled li:before {
  background-color: #00a5ef;
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  left: 0;
  top: 10px;
  width: 8px;
}
.list-bullets-styled li li:before {
  background-color: #84cff5;
}


.app__laurels_awards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 3rem;
}

.app__laurels_awards-card {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
}

@media screen and (min-width: 1900px) {
    .app__laurels_awards-card {
        min-width: 390px;
    }
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

@media screen and (max-width: 450px) {
    .app__laurels_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
}




.app__menuitem {
    width: 100%;
    margin: 1rem 0;

    display: flex;
    flex-direction: column;
}

.app__menuitem-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__menuitem-sub {
    width: 100%;
    margin-top: 0.2rem;
}

.app__menuitem-name {
    flex: 1;
}

.app__menuitem-dash {
    width: 90px;
    height: 1px;
    background: var(--color-golden);
    margin: 0 1rem;
}

.app__menuitem-price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}



.app__specialMenu {
    flex-direction: column;
    background: var(--color-white);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: bolder;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-black);
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu-menu_img {
    width: 410px;
    margin: 0 2rem;
}
.smiling_section{
	width: 100%;
    margin: 0 2rem;
}

.app__specialMenu-menu_img img {
    width: 100%;
    height: auto;
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 100%;
}

@media screen and (min-width: 2000px) {
    .app__specialMenu-menu_img {
        width: 650px;	
    }

    .app__specialMenu-menu_img img {
        height: 920px;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
}


body .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  background: rgba(0,0,0,0.6);
  position: fixed;
  inset: 0;
  z-index: 200;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  animation-name: k3;
  animation-duration: 0.25s;
  animation-timing-function: ease;
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(4px);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0,0);
  -ms-transform: translate(0,0);
  -o-transform: translate(0,0);
  transform: translate(0,0);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-title {
  color: var(--color-white);
}


@media screen and (min-width: 768px) {
	.modal-content {
	  -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.125);
	  box-shadow: 0 2px 3px rgba(0,0,0,0.125);
	}
	.modal-dialog {
	  width: 600px;
	  margin: 30px auto;
	}
	.modal-content {
	  -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
	  box-shadow: 0 5px 15px rgba(0,0,0,.5);
	}
}
.modal-content {
  border-radius: 0;
  -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.125);
  box-shadow: 0 2px 3px rgba(0,0,0,0.125);
  border: 0;
}
.modal-content {
  position: relative;
  background-color: var(--color-white);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
}


.btn-group-vertical > .btn-group::after, .btn-toolbar::after, .clearfix::after, .container-fluid::after, .container::after, .dl-horizontal dd::after, .form-horizontal .form-group::after, .modal-footer::after, .modal-header::after, .nav::after, .navbar-collapse::after, .navbar-header::after, .navbar::after, .pager::after, .panel-body::after, .row::after {
  clear: both;
}
.btn-group-vertical > .btn-group::after, .btn-group-vertical > .btn-group::before, .btn-toolbar::after, .btn-toolbar::before, .clearfix::after, .clearfix::before, .container-fluid::after, .container-fluid::before, .container::after, .container::before, .dl-horizontal dd::after, .dl-horizontal dd::before, .form-horizontal .form-group::after, .form-horizontal .form-group::before, .modal-footer::after, .modal-footer::before, .modal-header::after, .modal-header::before, .nav::after, .nav::before, .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before, .pager::after, .pager::before, .panel-body::after, .panel-body::before, .row::after, .row::before {
  display: table;
  content: " ";
}
::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bg-light-blue-active, .modal-primary .modal-header {
  background-color: var(--color-black) !important;
}
.bg-red, .bg-yellow, .bg-aqua, .bg-blue, .bg-light-blue, .bg-green, .bg-navy, .bg-teal, .bg-olive, .bg-lime, .bg-orange, .bg-fuchsia, .bg-purple, .bg-maroon, .bg-black, .bg-red-active, .bg-yellow-active, .bg-aqua-active, .bg-blue-active, .bg-light-blue-active, .bg-green-active, .bg-navy-active, .bg-teal-active, .bg-olive-active, .bg-lime-active, .bg-orange-active, .bg-fuchsia-active, .bg-purple-active, .bg-maroon-active, .bg-black-active, .callout.callout-danger, .callout.callout-warning, .callout.callout-info, .callout.callout-success, .alert-success, .alert-danger, .alert-error, .alert-warning, .alert-info, .label-danger, .label-info, .label-warning, .label-primary, .label-success, .modal-primary .modal-body, .modal-primary .modal-header, .modal-primary .modal-footer, .modal-warning .modal-body, .modal-warning .modal-header, .modal-warning .modal-footer, .modal-info .modal-body, .modal-info .modal-header, .modal-info .modal-footer, .modal-success .modal-body, .modal-success .modal-header, .modal-success .modal-footer, .modal-danger .modal-body, .modal-danger .modal-header, .modal-danger .modal-footer {
  color: var(--color-white) !important;
}
.modal-primary .modal-header, .modal-primary .modal-footer {
  border-color: #307095;
}
.modal-header {
  border-bottom-color: #f4f4f4;
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
    border-bottom-color: rgb(229, 229, 229);
}

.modal-header .close {
  /*margin-top: -20px;*/
  margin-top: 10px;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  /*font-size: 60px;*/
  font-size: 30px;
  color: rgb(133, 133, 133);
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  filter: alpha(opacity=20);
}

.bg-light-blue, .label-primary, .modal-primary .modal-body {
  background-color: var(--color-white) !important;
}
.bg-red, .bg-yellow, .bg-aqua, .bg-blue, .bg-light-blue, .bg-green, .bg-navy, .bg-teal, .bg-olive, .bg-lime, .bg-orange, .bg-fuchsia, .bg-purple, .bg-maroon, .bg-black, .bg-red-active, .bg-yellow-active, .bg-aqua-active, .bg-blue-active, .bg-light-blue-active, .bg-green-active, .bg-navy-active, .bg-teal-active, .bg-olive-active, .bg-lime-active, .bg-orange-active, .bg-fuchsia-active, .bg-purple-active, .bg-maroon-active, .bg-black-active, .callout.callout-danger, .callout.callout-warning, .callout.callout-info, .callout.callout-success, .alert-success, .alert-danger, .alert-error, .alert-warning, .alert-info, .label-danger, .label-info, .label-warning, .label-primary, .label-success, .modal-primary .modal-body, .modal-primary .modal-header, .modal-primary .modal-footer, .modal-warning .modal-body, .modal-warning .modal-header, .modal-warning .modal-footer, .modal-info .modal-body, .modal-info .modal-header, .modal-info .modal-footer, .modal-success .modal-body, .modal-success .modal-header, .modal-success .modal-footer, .modal-danger .modal-body, .modal-danger .modal-header, .modal-danger .modal-footer {
  color: var(--color-black) !important;
}
.modal-body {
  position: relative;
  padding: 15px;
}

.modal-header > h4{
	font-family: var(--font-base) !important;
}

.modal-footer{
	float: right;
	flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-base);
}

.modal-footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: var(--color-black);
  color: var(--color-white);
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: var(--color-white);
  color: var(--color-black);
}


.modal-text-input {  
	border: 1px solid var(--color-primary);
	border-radius: 5px;
	position: relative;
	z-index: 0;	
	padding: 0 20px;
	color: var(--color-primary);
	height: 3rem;
	line-height: 3rem;
	margin: auto;
	font-size: 1.23rem;
	text-align: left;
	width: 100%;
	font-family: var(--font-base);
	letter-spacing: 0.2rem;
	outline: none;
}



[data-mesh-id="comp-jbex5qnkinlineContent"] {
  height: auto; /* calc(100vh - 100px);*/
  width: 100%;
  background-color: #b6efd9; 
  padding-bottom: 200px;
  
}

.font_3 {
	font: var(--font-base);
	letter-spacing: 0.01em;
	padding-bottom: 0 !important;
	margin-top: 0.1em !important;
	margin-bottom: 0.1em !important;
}

[data-mesh-id="comp-jbex5qnkinlineContent-gridContainer"] {
  position: static;
  display: grid;
  height: auto;
  width: 100%;
  min-height: 560px;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 100%;
}

#comp-jbex5qnm1 {
  height: auto;
  width: 552px;
}

[data-mesh-id="comp-jbex5qnkinlineContent-gridContainer"] > [id="comp-jbex5qnm1"] {
  position: relative;
  margin: 128px 0px 18px calc((100% - 629px) * 0.5);
  left: 77px;
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
  align-self: start;
}

#comp-jbex5qnq {
  min-height: 119px;
  height: auto;
  width: 473px;
}

[data-mesh-id="comp-jbex5qnkinlineContent-gridContainer"] > [id="comp-jbex5qnq"] {
  position: relative;
  margin: 0px 0px 10px calc((100% - 629px) * 0.5);
  left: 77px;
  grid-area: 2 / 1 / 3 / 2;
  justify-self: start;
  align-self: start;
}

.font_8 {

    letter-spacing: 0.05em !important;
	padding: 0;
	font-size: 25px !important;
	line-height: 40px !important;
	margin-bottom: 20px !important;
}
.color_18{
	font-size: 46px;
	color: var(--color-primary2);
}


@media screen and (max-width: 650px) {	
	[data-mesh-id="comp-jbex5qnkinlineContent"], #comp-jbex5qnq, #comp-jbex5qnm1{
		width: 100% !important;
		height: auto !important;
		padding: 20px !important;
	}
	[data-mesh-id="comp-jbex5qnkinlineContent-gridContainer"] > [id="comp-jbex5qnq"] {
		position: relative;
		margin: 20px;
		left: 0;
		grid-area: 2 / 1 / 3 / 2;
		justify-self: start;
		align-self: start;
	}
	[data-mesh-id="comp-jbex5qnkinlineContent-gridContainer"] > [id="comp-jbex5qnm1"] {
		position: relative;
		margin: 50px 20px;
		left: 0;
		grid-area: 1 / 1 / 2 / 2;
		justify-self: start;
		align-self: start;
	}
}
.footer-logo {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 80%;
}