.deliveryAddressInputSuggest {
  appearance: none;
  background: #f5f4f1;
  border-radius: 40px;
  box-shadow: none;
  color: #142138;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0 !important;
  position: relative;
  height: 48px !important;
  padding: 12px 50px;
  width: 100%;
  outline: none;
  border: 1px solid #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.deliveryAddress {
  position: relative;
  width: 100%;
  padding: 16px 0 16px 24px;
  min-width: 50px;
}

.deliveryAddressWrapper {
  position: relative;
}

.deliveryLocationIcon {
  position: absolute;
  top: 41px;
  left: 45px;
  transform: translate(-50%,-50%);
}

.deliveryClearButton {
  top: 20px;
  right: 22px;
  position: absolute;
  border: none;
  background: 0 0;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
	.deliveryClearButton {
	  top: 20px;
	  right: 22px;
	}
}
@media only screen and (max-width: 768px) {
	.deliveryAddress {
	  padding: 16px;
	}
	.deliveryClearButton {
	  top: 20px;
	  right: 35px;
	}
}


button.deliveryClearButton {
	min-width: 0px !important;
}


.delivery_app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    padding: 1rem 2rem;
}

.delivery_app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
	min-width: 60px;
}

.delivery_app__navbar-logo img {
  width: 100px;
  height: 60px;
}

.delivery_app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.delivery_app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.delivery_app__navbar-links li:hover {
    color: var(--color-grey);
}

.delivery_app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.delivery_app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.delivery_app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.delivery_app__navbar-login div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.delivery_app__navbar-smallscreen {
    display: none;
}

.delivery_app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: .5s ease;
    flex-direction: column;
    z-index: 5;
}

.delivery_app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.delivery_app__navbar-smallscreen_links {
    list-style: none;
}

.delivery_app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.delivery_app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    
	.delivery_app__navbar-logo img {
	  width: 90px;
	  height: 60px;
	}
}

@media screen and (max-width: 1150px) {
    .delivery_app__navbar-links {
        display: none;
    }   
}

@media screen and (min-width: 1150px) {
	.delivery_app__navbar-logo img {
	  width: 90px;
	  height: 60px;
	}
}


@media screen and (max-width: 768px) {
	.delivery_app__navbar-smallscreen {
        display: flex;
    }  
	
	.delivery_app__navbar-links {
        display: none;
    }
	
    .delivery_app__navbar {
        padding: 1rem;
    }

    .delivery_app__navbar-login {
        display: none;
    }

    .delivery_app__navbar-logo img {
	  width: 80px;
	  height: 60px;
	}
}