.opt-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.campaign-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.campaign-screen h1 {
	margin-top: 20px;	
  margin-bottom: 20px;	
	font-weight: bold;
	font-size: 20px;
}

.campaign-screen p {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .opt-in-form {
    max-width: 90%;
  }
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.campaign-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.opt-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
	font-family: 'Montserrat';
}

.opt-in-form h2 {
  margin-bottom: 10px;	
	font-weight: bold;	
}

.opt-in-form p {
  margin-bottom: 20px;
	font-size: 14px;
}

.opt-in-form span {
	font-size: 12px;
}

.opt-in-form label {
  margin-bottom: 10px;
}

.opt-in-form input[type="text"],
.opt-in-form input[type="checkbox"] {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
}

.opt-in-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.opt-in-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.opt-in-form button:hover {
  background-color: #0056b3;
}

