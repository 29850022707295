@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Tangerine:wght@300;400;500;600;700&display=swap");

:root {
	--font-base: 'Cormorant Upright', serif;
	--font-alt: 'Open Sans', sans-serif;
	--font-mont: 'Montserrat', sans-serif;
	--font-tang: 'Tangerine', serif;

	--color-golden: #dcca87;
	--color-black: #201e1e;
	--color-gray: #687282;
	--color-gray2: #aaaaaa;
	--color-crimson: #f5efdb;
	--color-white: #ffffff;
	--color-boxgreen: #ccdccd;
	--color-lightgreen: #ccdccd;
	
	--color-primary: #0ecb81;
	--color-primary2: #257a80;
	--color-primary3: #3e5725;

	--info-dark: rgba(37,122,128);  
	--info-med: rgba(140,198,255);
	--info-light: rgba(232,243,255);
	
	--success-dark: rgba(0,111,1);  
	--success-med: rgba(127,183,128);
	--success-light: rgba(229,240,229);
	
	--custom-dark: rgba(37,122,128);  
	--custom-med: rgba(145,168,189);
	--custom-light: rgba(158,234,204);
	
	--dark-custom-dark: rgba(10,13,16);
	--dark-custom-med: rgba(108,109,111);
	--dark-custom-light: rgba(181,182,183);
	
	--error-dark: rgba(209,53,41);
	--error-med: rgba(232,154,148);
	--error-light: rgba(250,234,233);
	--positive-medium: rgba(42, 148, 70);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

