@import 'bulma/css/bulma.css';

.wrapper {
  max-width: 720px;
  min-height: 1024px;
}

.header {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-left: 20px;
  overflow: hidden;
}

.header img {
  border: none;
}

.contentWrapper {
  padding: 20px 30px;
  max-width: 620px;
  background: #FEFFFF;
  min-height: 250px;
  border-radius: 1px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.contentWrapper a {
  color: #3997fc;
}

.subcontentWrapper {
  padding: 20px 30px;
  opacity: 0.8;
}

.genericSubcontentWrapper {
  padding: 20px 30px;
  opacity: 0.8;
}

.subcontentWrapper h2 {
  font-size: 18px;
  opacity: 0.8;
}

.sidebar {
  padding: 20px 30px;
  opacity: 0.8;
}

.sidebar p {
  font-size: 16px;
}

.sidebar li { 
  margin: 10px 0;
}

.sidebar img { 
  height: 22px;
  filter: invert(32%) sepia(6%) saturate(295%) hue-rotate(251deg) brightness(92%) contrast(89%);
}

.sidebar a {
  padding-left: 6px;
  color: #545054;
}

.subcontentWrapper a {
  line-height: 22px
}

.subcontentWrapper h2 a {
  text-decoration: none;
  color: #545054;
}

.faqTitle {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #7C7B7F;
}

.faqTitle:hover, .faqTitle:active {
  color: #545054;
}

.backIcon {
  position: relative;
  top: 4px;
  filter: invert(74%) sepia(6%) saturate(102%) hue-rotate(188deg) brightness(87%) contrast(97%);
  width: 16px;
  height: 16px;
}

.meta a {
  color: #A5A6A9;
  font-size: 14px;
}

.meta .sourceLink, .meta .editLink {
  float: right;
  margin-left: 5px;
}

.relatedTags {
  padding: 10px 30px;
}

.relatedTags h2 {
  color: #7C7B7F;
}

.relatedTags a {
  color: #7C7B7F;
  display: block;
  padding-bottom: 6px;
}

.disclaimer {
  margin: 5px auto;
  color: #7C7B7F;
  font-size: 14px;
}

.disclaimer a {
  color: #7C7B7F;
}

.tagcloud {
  margin: 30px auto 50px auto;
  max-width: 450px;
  text-align: center;
}

.tagcloud {
}

.tagcloud > div > span {
  font-size: 16px;
  margin: 0 auto;
}


.genericSubcontentWrapper a {
  color: #7C7B7F;
}

.genericSubcontentWrapper a:hover {
  color: #545054;
}

/* 320, 418, 641, 961, 1025, 1281 */

/* cube: start */

.cube {
  pointer-events: none;
  position: absolute;
  width: 95px;
  right: -21px;
  top: -14px;
  display: none;
}


@media (min-width:380px)  {
  .cube {
    display: block;
  }

}

@media (min-width:641px)  {
  .wrapper {
    margin-left: 135px;
  }

  .cube {
    width: 210px;
    left: -80px;
    top: -19px;
  }
}

@media (min-width:961px)  {
  .wrapper {
    margin-left: 170px;
  }

  .cube {
    width: 250px;
    left: -80px;
    top: -30px;
  }
}

@media (min-width:1025px)  {
  .wrapper {
    margin-left: 223px;
  }

  .cube {
    width: 300px;
    left: -80px;
    top: -80px;
  }
}

@media (min-width:1281px)  {
  .wrapper {
    margin-left: 294px;
  }
	
  .cube {
    width: 372px;
    left: -80px;
    top: -80px;
  }
}


/* cube: end */

@media (min-width:1281px)  {
  .sidebar {
    position: absolute;
    top: 350px;
    left: -60px;
    width: 350px;
  }
}

@media (min-width:1281px)  {
  .subcontentWrapper {
    position: absolute;
    top: 100px;
    left: 1020px;
    bottom: 20px;    
    padding-right: 0px;
    padding-top: 0px;
		overflow-x: hidden;
		overflow-y: auto;
  }

  .contentWrapper .subcontentWrapper {
    position: inherit;
  }
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: #0ecb81;
}

.content h1{
	font-size: 1.5em;
}

a {
  color: #4062D6;
  text-decoration: underline;
}

a:hover {
  color: #4062D6;
}

* {
  box-sizing: border-box;
}

.content p {
  font-size: 19px;
  font-weight: 400;
}

.navbar {
  height: 80px;
  z-index: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 10px;
  background: #0ecb81;
  position: absolute;
}

.content blockquote {
  background: #F2F4F7;
  font-style: italic;
  font-size: 20px;
  line-height: 31px;
  padding: 20px 30px;
  border-color: #CDD1D4;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.errandlocal {
  /*background: url("https://elocalcdn.com/?publicasset=favicon21&type=png") 0px 1px no-repeat;*/
  background: url("https://www.elocalcdn.com/public/assets/favicon21.png") 0px 1px no-repeat;
  padding-left: 22px;
  display: inline-block;
}

.tags {
  margin-bottom: 20px;
}

.tag {
  margin-right: 14px;
}

.tag a {
  color: #7C7B7F;
  text-decoration: none;
}


/* 320, 418, 641, 961, 1025, 1281 */


ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 8px;  
  color: #4c4c4c;
}

.active-ul li {
	cursor: pointer;
  color: #007bff;
}

.active-ul li:hover {
  text-decoration: underline;
}

.related-ul li {
	cursor: pointer;
  color: #6e6e6e;
}

.related-ul li:hover {
  text-decoration: underline;
}

