:root {
 --primaryColor:#40196d;
 --blackColor:#000;
 --successColor:#48d38a;
 --negativeColor:#f7685b;
 --textColor:#979797;
 --whiteColor:#fff;
 --whiteAlt:#fff;
 --offWhite:#f9f9f9;
 --offWhiteTrans:hsla(228,7%,87%,.3);
 --borderOff:hsla(228,7%,87%,.5);
 --offText:rgba(0,0,0,.5);
 --bgOffset:#f4f4f4;
 --placeHolder:#979797;
 --offBlue:#eff1ff;
 --boxShadow:rgba(64,25,109,.07);
 --offBlueTran:rgba(223,227,255,.3);
 --primaryAlt:#40196d;
 --offGreen:rgba(72,211,138,.5);
 --ctaOffBlue:rgba(64,25,109,.3);
 --overlay:rgba(64,25,109,.32);
 --successColorTrans:#dcffec;
 --lightBlue:#1dcbef
}

/* App.css */

.App-download {
  text-align: center;
  margin: 100px auto;
  max-width: 400px;
}

.App-download-header {
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #40196d;
}

/* You can add additional styling for your loading spinners or other content here */

ul.service {
	list-style-type: disc;
	margin-left: 20px;
	font-weight: 500;
}

ul.countryList>li{
	margin-bottom: 10px;
  padding: 7px 5px 0 5px;
}

ul.countryList>li:hover{
	background: #aebcc8;
	border-radius: 10px;
}
.el-account {
  min-width:auto
}
.desktop-header .el-menu--text {
 align-items:center;
 display:flex
}
.desktop-header {
  display:none!important
}
.el-brand svg {
  height:16px;
  width:auto
}
#el-dashboard-container, .el-global-wrapper, .el-entry {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.el-entry{
	height: 100%;
}
.el-header--wrap {
  display: block;
  margin: 0 auto;
  max-width: 1500px;
  padding-left: 60px;
  padding-right: 60px;
}
.el-header {
  background: var(--color-black);
  box-shadow: 0 4px 13px rgba(0,0,0,0.5);
  /*box-shadow: 0 16px 24px rgb(51 51 51 / 8%);*/
  height: 100px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.el-mobile-header--wrap {
  display: none !important;
}
.el-account {
  cursor: pointer;
  height: 70px;
  position: relative;
}
.el-account, .el-brand {
  min-width: 100px;
}
.el-menu--text {
  font-size: .875rem;
}
.el-nav--icon a, .el-nav--icon p {
  align-items: center;
  color: var(--textColor);
  display: flex;
  font-size: .875rem;
  margin-right: 58px;
}
.el-nav--icon .active .el-nav--icon svg,
.el-nav--icon .filled,
.el-nav--icon a[aria-current=page] .el-nav--icon svg {
 display:none
}
.el-nav--icon .active .filled,
.el-nav--icon a[aria-current=page] .filled {
 display:inline-flex
}
.el-nav--icon .active svg:not(.filled),
.el-nav--icon a[aria-current=page] svg:not(.filled) {
 display:none
}
@media only screen and (min-width: 768px)
{
	.el-hamburger {
	  display: none !important;
	}
	.el-hamburger {
	  display: inline-block;
	  height: auto;
	  position: relative;
	  top: 3px;
	  width: 23px;
	}
}
.el-hamburger .el-hamburger--inner::after, .el-hamburger .el-hamburger--inner::before {
  background-color: #40196d;
  background-color: var(--primaryAlt);
  border-radius: 4px;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transition-duration: .15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 20px;
}
.el-hamburger .el-hamburger--inner::before {
  top: -9px;
  transition: top 75ms ease .12s,opacity 75ms ease;
}
.el-page--transition .el-preview {
  min-height: calc(100vh - 120px);
  transform: translateY(0) !important;
}
.el-preview {
  transition: transform .8s;
}
.el-preview {
  margin-top: 70px;
  padding: 63px 40px;
}
.member-profile {
  background-color: #e8e8e8;
  background-position: top;
  background-size: cover;
  border-radius: 100px;
  height: 30px;
  margin-right: 15px;
  width: 30px;
}
.text-bold {
  font-weight: 700;
}
.align-center {
  align-items: center !important;
}
.flex {
  display: flex !important;
}
.justify-between {
  justify-content: space-between !important;
}
.column--100, .row--100 {
  width: 100%;
}
.margin-left-auto, .mx-auto {
  margin-left: auto !important;
}
.margin-right-auto, .mx-auto {
  margin-right: auto !important;
}
.margin-bottom-4, .my-4 {
  margin-bottom: 1.5rem !important;
}
.margin-bottom-2, .my-2 {
  margin-bottom: .5rem !important;
}
.margin-top-1, .my-1 {
  margin-top: .1rem !important;
}
.el-overflow--hidden {
    overflow-x: hidden;
}

.auth-wrap, .phoneupdate-wrap {
    background: var(--whiteAlt);
    border: 1px solid var(--borderOff);
    border-radius: 5px;
    box-shadow: 15px 30px 40px var(--boxShadow);
    padding: 40px 55px 49px;
}
.elAnimated {
    animation-duration: .8s;
    animation-fill-mode: both;
    position: relative;
}
.elFadeInUp {
    animation-name: elFadeInUp;
}
.text-center {
    text-align: center;
}
.el__viewport {
  backface-visibility: hidden;
  perspective: 1000px;
  position: relative;
}
.margin-top-4, .my-4 {
  margin-top: 1.5rem !important;
}
.el-single--slide, .el__container {
  backface-visibility: hidden;
  display: flex;
  perspective: 1000px;
}
.customer-cards--list .el-single--slide {
  min-width: 100% !important;
}
.el-single--slide:only-child {
  padding: 0;
}
.el-single--slide {
  flex-direction: column;
  min-width: 81% !important;
}
.card-animation--wrap {
  display: flex;
  height: 189px;
  justify-content: center;
  position: relative;
  width: 100%;
}
.card {
  transition: transform .8s;
    transition-delay: 0s;
  transition-delay: .5s;
  z-index: 1;
}
.card {
  height: 189px;
  perspective: 800px;
  position: relative;
  width: 120px;
}
.card .flippy {
  height: 100%;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: all 1s;
  width: 100%;
}
.card .front {
  transform: rotateX(0);
}
.card .back, .card .front {
  align-items: center;
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.invisible-card-back {
  opacity: 0;
  transition: .5s ease;
}

.card-animation--wrap:not(.active-card) .card-secret-details {
  pointer-events: none;
}
.card-secret-details {
  max-width: 145px;
  z-index: 0;
}
.card-secret-details {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  transition: opacity .2s,transform 1s;
  transition-delay: .6s;
}
.card-animation--wrap .secret-wrap {
  margin-bottom: 11px;
}
.secret-wrap {
  cursor: copy;
}
.secret-icon {
  margin-left: 12px;
}
.text-11 {
  font-size: .9rem !important;
}
.text-xlbold {
  font-weight: 900;
}
.text-semi-bold {
  font-weight: 600;
}
.color-black {
  color: var(--blackColor) !important;
}
.text-left {
  text-align: left;
}
.margin-bottom-5, .my-5 {
  margin-bottom: 3rem !important;
}
.margin-top-5, .my-5 {
  margin-top: 3rem !important;
}
.block-manage-button button {
  border-radius: 4px;
  font-size: .75rem;
  font-weight: 800;
  height: 24px;
  line-height: 15px;
  background: var(--color-black);
  padding: 15px 0 30px;
}
.card-illustration svg {
 padding-bottom: 50px !important;
 width:auto;
}
.physical-card svg {
 margin:0;
 position:static;
 top:auto;
 -webkit-transform:none;
 transform:none;
 width:100%
}
.physical-card>svg {
 width:auto;
}
.gwipAY {
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 25px;
  border-radius: 4px;
  padding: 13px 23px;
  margin-bottom: 15px !important;
  max-width: 357px;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  border: medium none;
  min-height: 55px;
  max-height: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.gwipAY .list-card {
  width: 100%;
}
.gwipAY {
  cursor: pointer;
}
.color-black {
  color: var(--blackColor) !important;
}
.margin-top-1, .my-1 {
  margin-top: .1rem !important;
}
.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}
.list-card-heading{
	line-height: 0;
}


 
 
.transaction-additional--details p {
 font-size:.9375rem
}
.modal-transactions .modal-content {
 background-color:var(--whiteAlt);
 max-width:517px;
 left:35%;
}

/*
.modal-content {
  padding:35px 48px 48px
 }
.el-cta-lg {
  max-width:142px
 }
 .modal-transactions .el-cta-lg {
  max-width:172px
 }

.modal-transactions .modal-content {
  max-width:410px
 }
 */

.jyXLKt{
	background-color:var(--color-primary) !important;
}
/*
.modal-backdrop {
 align-items:center;
 background:var(--overlay);
 bottom:0;
 display:flex;
 height:100vh;
 justify-content:center;
 left:0;
 left:50%;
 margin-left:-50vw;
 padding:30px;
 position:fixed;
 right:0;
 top:0;
 transition:opacity .6s cubic-bezier(.455,.03,0,1)!important;
 visibility:hidden;
 width:100vw;
 z-index:999
}
*/
.text-lg {
 font-size:2.5rem!important;
 line-height:1.3
}
.text-l {
 font-size:2rem!important;
 line-height:1.3
}
.text-md {
 font-size:2.15rem;
 line-height:1.4
}
.text-s {
 font-size:.9125rem
}
.text-sm {
 font-size:.815rem
}
.text-xs {
 font-size:.73rem
}
.positive {
 color:#48d38a;
 color:var(--successColor)
}
.positive:before {
 content:"+"
}
.transaction-left {
  padding-right:15px
}
.status-icon svg {
 height:9px;
 width:9px
}
.margin-right-1,
.mx-1 {
 margin-right:.25rem!important
}
.modal-content>h4.text-xbold {
 font-size:1.275rem
}
.modal-content {
 background:#fff;
 background:var(--whiteAlt);
 border:1px solid hsla(228,7%,87%,.5);
 border:1px solid var(--borderOff);
 border-radius:5px;
 box-shadow:15px 30px 40px rgba(64,25,109,.07);
 box-shadow:15px 30px 40px var(--boxShadow);
 max-width:517px;
 padding:42px 48px 54px;
 position:relative;
 width:100%
}
.small-modal .modal-content {
 max-width:455px
}
.transaction-header {
 background:#f4f5fe!important;
 border-radius:4px;
 display:flex;
 margin-bottom:23px;
 margin-top:38px;
 padding:20px
}
.transaction-icon {
 border-radius:100px;
 height:43px;
 margin-right:15px;
 min-width:43px;
 overflow:hidden;
 width:43px
}
.transaction-icon img {
 height:100%;
 object-fit:cover;
 width:100%
}
.transaction-icon {
 border-radius:100px;
 box-shadow:0 2px 5px rgba(0,0,0,.07)
}
.transaction-additional--details .save-title {
 font-size:.875rem;
 margin-bottom:5px!important
}
.transaction-additional--details {
 border-bottom:1px solid var(--offWhiteTrans);
 display:flex;
 justify-content:space-between;
 /*padding-bottom:15px;*/
 padding-top:15px
}
.transaction-right {
 position:relative;
 text-align:right
}
.generateReceipt {
 margin-bottom:0;
 margin-top:37px;
 max-width:200px
}
.closeModal {
 cursor:pointer;
 left:17px;
 position:absolute;
 top:17px
}
.closeModal svg {
 height:29px;
 width:auto
}
.modal-content>.closeModal+h4.text-center {
 font-size:1.25rem
}
.balance-title {
 margin-top:2px
}
.el-heading--illustration svg {
 display:block;
 height:98px;
 margin:0 auto;
 width:98px
}

.transaction-additional--details .el-dropdown-header {
 height:auto
}
.transaction-additional--details .el-dropdown-title {
 font-weight:800;
 line-height:1.5;
 padding-left:0;
 padding-right:9px;
 width:100%
}
.transaction-additional--details .el-dropdown-icon {
 width:auto
}
.transaction-additional--details .el-dropdown-icon svg {
 height:auto;
 position:relative;
 top:2px;
 width:10px
}
.transaction-right .el-dropdown-list {
 left:auto;
 max-height:260px;
 min-width:190px;
 padding-bottom:5px;
 padding-top:5px;
 right:0;
 width:auto
}
.transaction-additional--details .el-dropdown-item {
 padding:10px 0
}
.transaction-additional--details .dropdown-item--title {
 font-size:.8rem
}
.transaction-additional--details .avatar-wrap {
 margin-right:13px
}

.addAction {
 margin-bottom:8px
}



.el-cta {
 background:#40196d;
 background:var(--color-black);
 border-radius:10px;
 color:#fff;
 color:var(--whiteColor)
}
.el-cta,
.el-cta-light {
 align-items:center;
 cursor:pointer;
 display:flex;
 font-size:16px;
 font-weight:700;
 height:48px;
 justify-content:center;
 line-height:1;
 padding:15px;
 transition:-webkit-transform .6s;
 transition:transform .6s;
 transition:transform .6s,-webkit-transform .6s
}
.flex-column {
 flex-direction:column!important
}










.list-card .arrow-wrap{
	font-weight: 700;
	color: var(--color-black);
	opacity: 0.8;
}

h4.list-card-heading {
  font-size: 1.05rem !important;
  line-height: 1.3 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  color: var(--color-black);
}

.color-black div{
	opacity: 0.6;
	font-size: 14px;
}
@media only screen and (max-width:767px) {
 body,
 html {
  font-size:14px
 }
 .personal-form h5 {
  font-size:1.28rem
 }
 .auth-layout--header+.el-preview,
 .auth-wrap,
 .el-header--wrap,
 .el-layout---card,
 .el-preview,
 .phoneupdate-wrap,
 footer .container {
  padding-left:20px;
  padding-right:20px
 }
 .el-preview {
  padding-bottom:100px
 }
 .auth-header,
 .phoneupdate-header {
  margin-left:0
 }
 .el-header {
  height:64px
 }
 .el-preview {
  display:block
 }
 h1 {
  font-size:1.6rem
 }
 .el-cta {
  font-size:14px;
  height:48px
 }
 .el-account--summary .addAction {
  height:44px
 }
 .el-brand svg {
  height:16px
 }
 .denomination-icon svg {
  height:9px;
  width:auto
 }
 .balance-summary {
  height:43px
 }
 .balance-title {
  margin-top:0
 }
 .el-cta-lg,
 .el-cta-long {
  max-width:240px
 }
 .text-xs {
  font-size:.78rem
 }
 .el-account--summary .addAction {
  margin-bottom:2px
 }
 .budget-card {
  min-height:76px
 }
 .displayWrap>* {
  padding-left:0;
  padding-right:0
 }
 .el-transaction--details {
  height:100%
 }
 .transaction-name {
  -webkit-line-clamp:1;
  -webkit-box-orient:vertical;
  display:-webkit-box;
  font-size:12px;
  -webkit-hyphens:auto;
  hyphens:auto;
  line-height:1.4;
  margin-top:0;
  overflow:hidden!important;
  text-overflow:ellipsis;
  width:100%
 }
 .transaction-date {
  margin-top:6px
 }
 .el-transaction--tabs>li {
  font-size:12px
 }
 .el-menu--wrap {
  display:none!important
 }
 .auth-layout--header+.el-preview {
  align-items:flex-start
 }
 .summary-card {
  width:100%
 }
 .back svg {
  height:11px;
  margin-right:6px;
  position:relative
 }
 .el-layout---card .back {
  left:20px;
  top:20px
 }
 .el-account {
  min-width:auto
 }
 .account-title {
  display:none
 }
 .el-inner--heading {
  font-size:1.55rem
 }
 .el-layout---card {
  padding-bottom:30px
 }
 .formCard {
  padding-bottom:20px;
  padding-left:22px;
  padding-right:22px
 }
 .el-card--wrap,
 .el-card--wrap.payment-wrap,
 .el-deposit--wrap,
 .payment-wrap .el-card--wrap,
 .payment-wrap .el-card--wrap.el-bill--wrap {
  padding-bottom:40px!important;
  padding-left:22px!important;
  padding-right:22px!important
 }
 .request-edge--fix>div {
  margin-left:-22px;
  margin-right:-22px;
  padding-left:22px;
  padding-right:22px
 }
 .request-edge--fix .card-text {
  right:22px
 }
 .sign-out {
  margin-top:30px
 }
 .el-savings .el-card--wrap {
  padding:40px 22px
 }
 .el-heading--illustration svg {
  height:86px;
  width:86px
 }
 .percentage-summary {
  height:44px;
  min-height:44px
 }
 .el-preview {
  padding-top:30px
 }
 .el-add--cardForm {
  margin-bottom:35px!important
 }
 .bill-item--icon svg {
  height:35px
 }
 .bill-item {
  padding-left:21px;
  padding-right:21px
 }
 .el-transaction--tabs {
  border-radius:0;
  margin-top:22px;
  overflow-x:scroll;
  overflow-y:hidden
 }
 .el-payment--tabs li {
  min-width:33.33%
 }
 .payoneerTab li {
  min-width:50%
 }
 .percentage-save {
  height:64px
 }
 .balance.margin-top-5 {
  margin-top:30px!important
 }
 .percentage-toggle svg {
  height:25px;
  width:auto
 }
 .modal-backdrop {
  align-items:center;
  margin-top:0;
  padding:20px
 }
 .modal-content {
  margin-bottom:100px;
  padding-bottom:40px;
  padding-left:20px;
  padding-right:20px;
  position:relative
 }
 .el-cta-md {
  max-width:115px
 }
 .transaction-title {
  margin-left:15px
 }
 .viewSavingsSummary .displayWrap>* {
  padding-left:0;
  padding-right:0
 }
 .viewSavingsSummary .displayWrap>div>* {
  padding-left:10px;
  padding-right:10px
 }
 .el-savings--header {
  margin-bottom:40px!important
 }
 .el-card--wrap {
  margin-top:25px
 }
 .member-profile {
  margin-right:11px
 }
 .el-account--summary>div,
 .el-block--nav {
  width:100%
 }
 .el-account--summary,
 .el-block--navWrapper {
  flex-direction:column
 }
 .block-item {
  margin-bottom:15px
 }
 .el-bills--nav {
  margin-bottom:-23px
 }
 .el-account--wrap {
  margin-left:auto;
  margin-right:auto;
  padding-left:22px!important;
  padding-right:22px!important
 }
 .el-share--earn {
  margin-top:30px
 }
 .account-image {
  height:85px;
  min-height:85px;
  min-width:85px;
  width:85px
 }
 .summary-labels {
  margin-left:20px
 }
 .el-invite--cta {
  font-size:12px;
  height:42px;
  margin-top:15px
 }
 .profile-account+.el-share--earn {
  margin-top:33px!important
 }
 .el-share--earn {
  padding-left:21px;
  padding-right:21px
 }
 .block-item {
  padding:10px 21px
 }
 .earnings {
  font-size:2rem
 }
 .inactivity-buttons {
  flex-direction:column
 }
 .inactivity-buttons>div {
  margin-bottom:20px;
  max-width:100%!important;
  width:100%
 }
 .el-share--earn {
  flex-direction:column-reverse
 }
 .el-invite--cta,
 .share-earn--50 {
  width:100%!important
 }
 .share-earn--50 * {
  text-align:center!important
 }
 .earnings {
  justify-content:center
 }
 .share-earn--50:last-child {
  margin-bottom:15px
 }
 .earned-amount {
  font-size:2.5rem!important
 }
 .el-account--wrap {
  padding-top:80px!important
 }
 .el-block--navWrapper h1 {
  text-align:center
 }
 .home-account {
  padding-top:35px!important
 }
 .el-split--wrap .card-group {
  width:100%
 }
 .el-split--wrap {
  flex-direction:column;
  margin-bottom:28px
 }
 .back-to {
  left:22px
 }
 .el-cards .el-cta-lg {
  max-width:100%
 }
 .el-radio--wrapper {
  width:calc(50% - 10px)
 }
 .outer {
  height:23px;
  width:23px
 }
 .inner {
  height:11px;
  width:11px
 }
 .el-limit--pad {
  padding-left:0;
  padding-right:0
 }
 .limit-fields>div {
  height:auto;
  padding-bottom:14px;
  padding-top:14px
 }
 .el-prev span,
 .limit-fields .card-text,
 .limit-fields .card-type {
  line-height:1.4
 }
 .el-add--money .card-fields>div {
  align-items:center!important
 }
 .panel__label span:first-child {
  padding-right:30px
 }
 .panel__label span {
  line-height:1.45
 }
 .el-margin--form .edit-account--wrap {
  margin-top:0!important
 }
 .accordion {
  margin-top:20px!important
 }
 .share-header--wrap {
  flex-direction:column-reverse;
  margin-top:0
 }
 .share-header--wrap>* {
  width:100%!important
 }
 .share-enty--text {
  margin-top:30px
 }
 .share-entry--content {
  margin-top:20px
 }
 .el-transaction--tabs::-webkit-scrollbar {
  display:none
 }
 .mobile-header--wrap {
  display:flex!important
 }
 .desktop-header {
  display:none!important
 }
 .mobile-header--wrap .account-title,
 .mobile-toggle {
  align-items:center;
  display:flex
 }
 .el-mobile--menu {
  background:rgba(64,25,109,.2);
  bottom:0;
  height:100%;
  left:0;
  position:fixed;
  right:0;
  top:0;
  width:100%;
  z-index:99999
 }
 .el-mobile--menu ul.el-menu--wrap {
  align-items:flex-start!important;
  -webkit-animation:slide-in-out .6s cubic-bezier(.455,.03,0,1) forwards;
  animation:slide-in-out .6s cubic-bezier(.455,.03,0,1) forwards;
  background:#fff;
  display:flex!important;
  flex-direction:column;
  height:100vh;
  margin-left:auto;
  max-width:0;
  overflow:hidden;
  padding:6px 22px 22px;
  width:100%
 }
 @-webkit-keyframes slide-in-out {
  0% {
   max-width:70px;
   opacity:0
  }
  to {
   max-width:240px;
   opacity:1
  }
 }
 @keyframes slide-in-out {
  0% {
   max-width:70px;
   opacity:0
  }
  to {
   max-width:240px;
   opacity:1
  }
 }
 .el-mobile--menu .el-nav--icon {
  width:100%
 }
 .el-mobile--menu .el-nav--icon svg {
  width:20px
 }
 .el-mobile--menu .el-nav--icon a,
 .el-mobile--menu .el-nav--icon p {
  align-items:center;
  display:flex;
  font-size:14px!important;
  padding-bottom:18px;
  padding-top:18px
 }
 .el-mobile--menu .el-nav--icon:not(:first-child) a {
  margin-right:0
 }
 .el-mobile--menu .member-profile {
  height:20px;
  width:20px
 }
 .el-account--summary .el-cta-lg {
  max-width:115px!important
 }
 .el-account--summary .addAction {
  margin-bottom:0!important
 }
 .account-input,
 .amount-input {
  font-size:16px
 }
 .transaction-price {
  font-size:13px;
  margin-right:12px
 }
 .el-transaction--details {
  padding-right:10px
 }
 .el-search--field,
 .el-searchbox input {
  font-size:16px
 }
 .flex.justify-between .el-cta-lg-sm,
 .flex.justify-between .el-cta-md {
  max-width:calc(50% - 8px)!important
 }
 .el-search--bank+.displayResults .el-result--item {
  padding-left:10px;
  padding-right:10px
 }
 .card-check {
  margin-bottom:35px
 }
 .el-search--bank+.displayResults .el-result--heading {
  padding-left:10px
 }
 .withdrawButton {
  height:46px;
  max-width:120px;
  -webkit-transform:none!important;
  transform:none!important
 }
 .card-fields+.accept {
  margin-top:35px
 }
 .percentage-save {
  margin-right:12px
 }
 .single-savings .singleTransaction .transaction-price {
  top:4px!important
 }
 .goal-actions .el-cta:not(:last-child) {
  margin-right:15px
 }
 .review-summary.card-fields>div {
  align-items:center!important
 }
 .el-account--summary {
  margin-bottom:20px
 }
 .savedCard {
  width:100%
 }
 .empty-savings--card {
  padding-left:20px;
  padding-right:20px
 }
 .el-set--status .addAction {
  max-width:120px
 }
 .referral-summary .transaction-name {
  overflow:auto;
  text-overflow:clip;
  white-space:normal
 }
 .el-referral--card .empty-summary {
  padding-left:0;
  padding-right:0
 }
 .el-referral--card .el-transaction--details,
 .el-referral--card .el-transaction--details .transaction-title {
  max-width:100%;
  padding-right:0;
  width:100%!important
 }
 .el-referral--card .el-result--heading {
  padding-left:10px;
  padding-right:10px
 }
 .el-referral--card .el-result--item {
  padding-left:10px;
  padding-right:10px;
  width:100%!important
 }
 .skeleton-ui .balance-summary {
  height:auto
 }
 .el-home-loader--wrap {
  margin-bottom:21px
 }
 .loader-30 {
  width:50px
 }
 .loader-30 span[style*="height: 52px"] {
  height:50px!important
 }
 .icons--el a:not(:last-child),
 .icons--el span:not(:last-child),
 .socialIcon {
  margin-right:10px!important
 }
 .icons--el span {
  height:44px;
  width:44px
 }
 .formInput,
 .pin-input input {
  font-size:16px!important
 }
 .budget-heading--summary {
  margin-bottom:7px!important;
  margin-left:0;
  margin-right:0
 }
 .el-budget--filter {
  flex-wrap:wrap
 }
 .date-wrap {
  margin-bottom:10px;
  width:100%
 }
 .filter-field {
  width:100%
 }
 .budget-card--header+.budget-heading--summary,
 .budget-heading--summary {
  margin-bottom:20px;
  margin-left:-20px;
  margin-right:-20px;
  overflow-x:scroll
 }
 .budget-card {
  min-width:calc(45% - 15px)
 }
 .budget-cards:after,
 .budget-cards:before,
 .budget-heading--summary .budget-cards:after,
 .budget-heading--summary .budget-cards:before,
 .category-budget .budget-cards:after,
 .category-budget .budget-cards:before {
  content:"";
  display:block;
  padding-right:20px!important
 }
 .budget-cards::-webkit-scrollbar {
  display:none
 }
 .budget-cards {
  -ms-overflow-style:-ms-autohiding-scrollbar;
  -webkit-overflow-scrolling:touch;
  scroll-snap-points-y:repeat(50%);
  overflow-y:hidden;
  scroll-padding-left:20px;
  scroll-snap-type:mandatory;
  scroll-snap-type:x mandatory
 }
 .budget-cards>* {
  scroll-padding-left:20px;
  scroll-snap-align:start!important
 }
 .category-budget .budget-heading--summary {
  margin-top:0
 }
 .budget-card--semiHeading {
  font-size:.85rem
 }
 .budget-card:not(:last-child) {
  margin-right:15px
 }
 .DateRangePicker,
 .el-budget--filter .DateRangePickerInput {
  width:100%!important
 }
 .date-wrap .next-calendar--control:last-child {
  margin-right:0!important
 }
 .budget-card .card-main--heading {
  font-size:1.1rem
 }
 .DayPicker_transitionContainer {
  min-height:310px
 }
 .el-budgeting .el-result--item {
  padding-left:10px!important;
  padding-right:10px!important
 }
 .el-budgeting:not(.category-budget) .transaction-price {
  height:32px
 }
 .el-budgeting:not(.category-budget) .transaction-name {
  margin-bottom:0;
  margin-top:-3px
 }
 .el-item--chart .savings-progress {
  width:calc(100% - 55px)
 }
 .el-budget--excerpt .transaction-price {
  position:static
 }
 .el-budget--excerpt .transaction-percentage {
  bottom:18px;
  left:50%;
  padding-left:64px;
  position:absolute;
  text-align:left;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  width:100%
 }
 .el-budget--excerpt {
  padding-bottom:40px
 }
 .budget-card--header .card-main--heading,
 .budget-heading--summary {
  margin-top:25px
 }
 .header-icon {
  display:flex
 }
 .budgetTab {
  margin-top:0!important
 }
 .budget-heading--summary {
  -ms-overflow-style:none;
  scrollbar-width:none
 }
 .budget-heading--summary::-webkit-scrollbar {
  display:none
 }
 .budget-cards {
  padding-bottom:15px
 }
 .DateInput_input {
  width:105px!important
 }
 .el-wrap--wrap--holder>div {
  margin-bottom:25px;
  width:100%
 }
 .el-wrap--wrap--holder {
  flex-wrap:wrap;
  padding-left:20px;
  padding-right:20px
 }
 .history>div {
  width:100%
 }
 .history {
  flex-wrap:wrap
 }
 .history-text {
  margin-bottom:25px
 }
 .el-history--items .el-transaction--tabs {
  margin-right:0
 }
 .budget-result--holder {
  margin-top:26px
 }
 .plan-budget--wrap svg.recharts-surface {
  margin-left:-38px!important;
  margin-top:-25px;
  min-width:220px
 }
 .plan-budget--wrap .recharts-wrapper {
  margin-bottom:20px
 }
 .budgetTab+.tabs .el-search.el-search--bank {
  margin-top:0
 }
 .create-budget--modal {
  padding:0
 }
 .create-budget--modal .modal-content {
  border-radius:0;
  height:auto;
  margin:auto;
  padding-bottom:25px;
  padding-top:33px
 }
 .el-budget--form {
  height:calc(100vh - 306px)!important
 }
 .backdrop-init .el-entry {
  overflow:hidden
 }
 .edit--budget .el-cta {
  max-width:100%!important
 }
 .el-budget--form+.block-buttons {
  margin-top:20px!important
 }
 .el-wrap--wrap--holder:last-child {
  margin-top:-5px
 }
 .category-budget .budget-cards:after,
 .category-budget .budget-cards:before {
  display:none
 }
 .category-budget .budget-cards {
  margin-bottom:5px;
  margin-top:25px
 }
 .edit--budget .el-cta:not(:last-child) {
  margin-right:20px
 }
 .el-dayLight--toggle {
  left:-30px
 }
 .el-header--actions .el-dayLight--toggle {
  left:-35px
 }
 .el-tran--items {
  max-width:157px
 }
 .el-tran--items .el-quick--statement {
  margin-bottom:0
 }
 .el-quick--statement svg {
  height:32px;
  width:32px
 }
 .budget-create--bg svg {
  height:auto;
  width:48px
 }
 .budget-heading {
  font-size:.875rem
 }
 .budget-create--bg {
  bottom:-20px;
  left:-18px;
  position:absolute
 }
 .percentage-summary {
  top:0
 }
 .percentage-save {
  margin-bottom:0!important
 }
 .el-page--loader {
  margin-bottom:40%;
  margin-left:30px
 }
 .el-page--loader img,
 .el-page--loader svg {
  height:auto!important;
  margin-left:0;
  width:190px!important
 }
 .el-404--wrap {
  padding:50px 40px 40px
 }
 .coming-soon--downloads {
  flex-wrap:wrap;
  justify-content:space-between;
  max-width:500px
 }
 .coming-soon--downloads>* {
  width:calc(25% - 5px)
 }
 .coming-soon--downloads>* img {
  height:auto;
  width:100%
 }
 .account-skeleton .balance {
  margin-bottom:20px
 }
 .account-skeleton .balance-second--half {
  display:block
 }
 .account-skeleton .el-account--summary {
  flex-direction:column;
  margin-bottom:20px
 }
 .account-skel--items .el-skeleton-span:last-child {
  display:none
 }
 .el-footer--conditions p {
  font-size:10.5px
 }
 .category-budget .budget-heading--summary>div:after {
  content:"";
  display:block;
  padding-right:20px!important
 }
 .category-budget .budget-heading--summary>div:before {
  content:"";
  display:block;
  padding-left:0!important
 }
 .account-skeleton .balance-icon {
  display:block!important;
  height:65px;
  min-width:65px;
  width:65px
 }
 .account-skeleton .balance.skeleton-ui>div:last-child span {
  width:80%!important
 }
 .denomination-icon {
  margin-right:2px
 }
 .el-header--wrap .el-cta {
  font-size:14px;
  height:42px;
  width:150px
 }
 .banks-ussd {
  margin-top:28px;
  padding-bottom:22px
 }
 .el-form--ussd {
  margin-bottom:0
 }
 .addCard-skeleton {
  padding-top:5px
 }
 .plan-margin {
  margin-top:8px
 }
 .account-skeleton .balance {
  display:none!important
 }
 .el-share--earnAlt {
  margin-top:20px
 }
 .share-header--wrap .el-share--earn {
  padding-bottom:25px!important
 }
 .linked-active {
  padding-bottom:35px!important
 }
 .add-product--wrap,
 .savedCards {
  margin-top:10px
 }
 .el-referral--card .edit-account--wrap {
  margin-top:0
 }
 .el-balance--wrap {
  min-height:100%;
  width:100%
 }
 .payoneer-heading {
  margin-bottom:27px;
  margin-top:30px
 }
 .el-budget--illustration svg,
 .revoke-content .el-modal-illustration svg {
  height:65px!important;
  width:65px!important
 }
 .product-skeleton .el-account--summary>div {
  width:100%!important
 }
 .product-skeleton .el-account--summary,
 .savedCard {
  margin-bottom:20px
 }
 .el-share--earn {
  margin-top:20px
 }
 .addCard-skeleton {
  padding-top:25px
 }
 .el-account--wrap {
  padding-bottom:25px!important
 }
 .budget-card--header+.budget-heading--summary {
  margin-bottom:0!important
 }
 .share-header--wrap .el-share--earn {
  padding-bottom:30px!important
 }
 .share-header--wrap .el-share--earnAlt {
  padding-bottom:27px!important
 }
 .justify-center.inactivity-buttons>div {
  margin-left:auto;
  margin-right:auto;
  max-width:200px!important
 }
 .el-auth--layout {
  margin-top:-25px
 }
 .el-announcement--card {
  padding:14px 20px
 }
 .error-auth--label {
  padding:12px 15px
 }
 .auth-layout--header+.el-preview {
  min-height:calc(100vh - 88px)!important
 }
 .el-page--transition .el-preview {
  min-height:calc(100vh - 80px)
 }
 .no-result {
  padding:15px 5px 10px
 }
 .request-wrap .el-cta-lg,
 .saveFields {
  max-width:205px!important
 }
 .saveFields {
  margin-top:43px
 }
 .back-to+.edit-account--wrap {
  margin-top:0
 }
 .linked-active .el-cta-lg {
  max-width:175px
 }
 .personal-form .login-button.el-cta-md {
  max-width:170px!important
 }
 .overdraftResults {
  margin-left:-22px;
  margin-right:-22px
 }
 .overdraftResults>div>* {
  padding-left:20px;
  padding-right:20px
 }
 .modal-overdraft .modal-content {
  height:100vh;
  max-width:100%!important
 }
 .modal-overdraft .el-budget--form {
  height:calc(100vh - 170px)!important
 }
 .backArrow {
  display:flex
 }
 .backArrow svg {
  -webkit-transform:translateY(-.9px);
  transform:translateY(-.9px)
 }
 .card {
  height:156px;
  width:100px
 }
 .physical-card .flippy svg {
  height:165px;
  width:auto
 }
 .active-card .card {
  -webkit-transform:translate3d(-70px,0,0);
  transform:translate3d(-70px,0,0)
 }
 .active-card .card-secret-details {
  -webkit-transform:translateX(-10px);
  transform:translateX(-10px)
 }
 .card-animation--wrap,
 .physical-card>svg {
  height:156px
 }
 .new-card--illustration svg {
  height:auto;
  max-width:135px
 }
 .flex.justify-between>.el-cta-lg-md {
  padding-left:7px;
  padding-right:7px;
  width:calc(50% - 8px)
 }
 .el__button--next {
  left:auto!important;
  right:0!important
 }
 .carousel-pagination--wrap .el__button {
  left:0
 }
 .el-full--slide {
  padding-left:20px!important;
  padding-right:20px!important
 }
 .el-no-sidePadding {
  padding-left:0!important;
  padding-right:0!important
 }
 .el__button {
  display:none
 }
 .el-no--bg {
  padding:0!important
 }
 .el-no--bg.flex.justify-between .el-cta {
  max-width:130px!important
 }
 .owe-tab {
  border-radius:5px!important;
  -webkit-border-radius:5px!important;
  -moz-border-radius:5px!important;
  -ms-border-radius:5px!important;
  -o-border-radius:5px!important;
  display:flex;
  height:56px!important;
  margin-left:0!important;
  margin-right:0!important;
  padding-left:12px!important;
  padding-right:12px!important
 }
 .owe-tab .borrow-progress {
  margin-top:9px
 }
 .loan-icon {
  margin-right:11px
 }
 .loan-icon svg {
  height:28px;
  width:28px
 }
 .owe-tab--max {
  margin-right:0!important
 }
 .left-seperator {
  margin-left:11px
 }
 .overdraft-edit--amount {
  height:44px;
  max-width:100px
 }
 .borrow-progress {
  margin-top:15px
 }
 .percentage-toggle {
  left:3px
 }
 .next {
  left:auto;
  right:3px
 }
 .payment-wrap .el-card--wrap {
  padding-top:34px
 }
 .el-no--bg .el-cta {
  height:44px
 }
 .loan-skeleton {
  margin-top:22px!important
 }
 .loan-skeleton .react-loading-skeleton {
  height:56px!important;
  min-height:56px!important
 }
 .el-borrow-card--content {
  margin-top:0
 }
 .el-reset--card {
  padding:35px 20px 49px
 }
 .el-input--slider {
  padding-left:15px;
  padding-right:15px
 }
 .agreement-side--padding {
  margin-left:-22px;
  margin-right:-22px;
  padding-left:20px;
  padding-right:20px
 }
 .card-fields .manage-limit--card {
  align-items:center!important;
  height:auto!important
 }
 .manage-limit--card .bill-item-wrap {
  padding-right:20px
 }
 .basic-padding {
  padding-left:15px!important;
  padding-right:15px!important
 }
 .manage-channels--wrapper .bill-item {
  height:52px
 }
 .el-request--wrap.select-card--request,
 .select-card--request .request-edge--fix>div {
  padding-left:22px!important;
  padding-right:22px!important
 }
 .select-card--request .request-edge--fix>div {
  margin-left:-22px!important;
  margin-right:-22px!important
 }
 .select-card--request .request-edge--fix .card-text {
  right:22px!important
 }
 .restricted-account--wrap {
  overflow:hidden;
  padding:20px 60px 20px 20px
 }
 .restricted-image--wrap {
  display:none;
  position:absolute;
  right:-150px
 }
 .restricted-image--wrap img {
  height:100%!important
 }
 .card-check {
  padding:22px 20px
 }
 .mobile-padded--card .px-5 {
  padding-left:0!important;
  padding-right:0!important
 }
 .mobile-padded--card .linked-bank--card .margin-right-5 {
  margin-right:0!important
 }
 .mobile-padded--card .linked-bank--card:hover {
  transform:none!important;
  -webkit-transform:none!important;
  -moz-transform:none!important;
  -ms-transform:none!important;
  -o-transform:none!important;
  transition:none!important;
  -webkit-transition:none!important;
  -moz-transition:none!important;
  -ms-transition:none!important;
  -o-transition:none!important
 }
 .mobile-padded--card {
  padding:32px 20px 45px
 }
 .full-width--mobile {
  width:100%
 }
 .linked-bank--card .flex-column.margin-right-5 {
  margin-right:0!important;
  width:100%
 }
 .linked-bank--card {
  grid-gap:18px;
  padding-left:20px!important;
  padding-right:20px!important
 }
 .el-tran--items {
  margin-right:auto
 }
 .card-secret-details {
  width:auto
 }
 .el-result--heading {
  padding-left:15px;
  padding-right:15px
 }
 .dashboard-swap {
  display:flex;
  flex-direction:column-reverse
 }
 .dashboard-swap .el-account--summary {
  margin-bottom:0
 }
 .dashboard-swap .savings-type {
  margin-bottom:22px
 }
 .accept {
  margin-top:42px
 }
 .list-card-heading {
  font-size:1rem!important;
  line-height:1.3!important;
  margin-top: 0!important;
  margin-bottom: 0!important;
 }
 
 h4.list-card-heading {
  /*font-size:1rem!important;
  line-height:1.3!important;*/
  margin-top: 0!important;
  margin-bottom: 0!important;
 }
 .homeSkel .balance-icon {
  display:none!important
 }
 .summary-skeleton {
  flex-direction:row!important
 }
 .dashboard-friends-item {
  margin-right:28px
 }
 .transaction-avatar {
  height:32px;
  min-width:32px;
  width:32px
 }
 .flag-icon svg {
  height:45px;
  width:45px
 }
 .balance-icon {
  margin-right:10px
 }
 .transactionSummary .el-account--summary {
  flex-direction:row
 }
}
@media only screen and (max-width:375px) {
 .el-header--wrap .el-cta {
  font-size:13px;
  width:140px
 }
 .el-header--actions .el-dayLight--toggle {
  left:-33px
 }
}
@media only screen and (max-width:320px) {
 .el-header--wrap .el-cta {
  font-size:12px;
  margin-top:15px;
  width:131px
 }
 .auth-layout--header:not(.authenticated-header) {
  height:110px
 }
 .auth-layout--header:not(.authenticated-header) .el-header--wrap {
  align-items:start!important;
  flex-direction:column
 }
 .auth-layout--header .el-header--actions .el-dayLight--toggle {
  left:auto;
  right:20px;
  top:17px
 }
 .auth-layout--header .el-header--actions {
  position:static
 }
 .auth-layout--header+.el-preview {
  margin-top:115px;
  min-height:calc(100vh - 135px)!important
 }
 .el-header--actions {
  width:100%
 }
 .el-header--wrap .el-cta {
  max-width:100%
 }
}
@media only screen and (min-width:768px) and (max-width:1024px) {
 html {
  font-size:14.5px
 }
 .el-nav--icon a,
 .el-nav--icon p {
  margin-right:34px
 }
 .el-nav--icon svg {
  width:19px
 }
 .el-dayLight--toggle svg {
  width:18px
 }
 .el-dayLight--toggle {
  left:-30px
 }
 .el-header--wrap,
 footer .container {
  padding-left:40px;
  padding-right:40px
 }
 .el-brand svg {
  height:18px;
  width:auto
 }
 .el-payments .card-summary-text p {
  font-size:15px
 }
 .percentage-save {
  margin-right:12px
 }
 .el-skeleton-span+.budget-heading--summary {
  margin-bottom:45px
 }
}
@media only screen and (max-width:575px) {
 .el-transaction--tabs {
  margin-left:-20px;
  margin-right:-20px;
  overflow-y:hidden
 }
 .account-skeleton span.margin-bottom-2.bottom>span>span {
  width:100%!important
 }
 .account-skeleton .balance-summary,
 .account-skeleton span.margin-bottom-2.bottom {
  width:100%
 }
 .budget-heading--summary .category-budget--highlighted .card-main--heading {
  line-height:1.4;
  position:relative;
  top:-2px
 }
 .budget-card .card-main--heading {
  font-size:1rem
 }
 .el-auth--illustration {
  display:none
 }
 .el-auth--wrap,
 .el-auth--wrap-ext {
  max-width:100%
 }
 .el-tran--items,
 .el-tran--items>div {
  display:flex;
  max-width:100%!important
 }
 .el-tran--items>div {
  Justify-content:space-between!important;
  width:100%
 }
 .el-tran--items>div>* {
  margin:0!important;
  width:calc(50% - 10px)!important
 }
 .el-tran--items button {
  white-space:nowrap;
  width:100%
 }
 .balance:not(.homeSkel) {
  display:flex;
  flex-direction:row-reverse;
  justify-content:space-between;
  width:100%
 }
 .mini-drop--icon {
  margin-right:0
 }
 .el-tran--items {
  margin-right:auto;
  margin-top:22px
 }
 .transactionSummary .el-account--summary {
  flex-direction:column;
  width:auto!important
 }
}
@media only screen and (min-width:768px) {
 .el-hamburger {
  display:none!important
 }
 .no-bottom--margin-desktop {
  margin-bottom:0!important
 }
 .skeleton-card--top .el-card--wrap {
  padding-bottom:57px!important
 }
 .plan-margin {
  margin-top:8px
 }
 .el-add--active .el-preview {
  max-width:959px
 }
 .el-add--active .el-add--options {
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between
 }
 .el-add--active .el-add--options>* {
  margin-left:0;
  margin-right:0;
  max-width:100%;
  width:calc(50% - 10px)
 }
 .account-image {
  margin-top:-4px
 }
 .modal-overdraft .el-budget--form+.block-buttons .el-cta {
  max-width:175px!important
 }
 .el-mobile--menu {
  display:none!important
 }
}
@media only screen and (max-width:1200px) {
 .auth-layout-wrap>div:last-child {
  width:calc(100% - 420px)
 }
 .auth-layout--header+.el-preview {
  min-height:calc(100vh - 100px)
 }
}
@media only screen and (max-width:320px) {
 body,
 html {
  font-size:13px
 }
 .auth-layout--header+.el-preview,
 .auth-wrap,
 .el-header--wrap,
 .el-layout---card,
 .el-preview,
 .modal-content,
 .phoneupdate-wrap,
 footer .container {
  padding-left:14px;
  padding-right:14px
 }
 .el-cta {
  font-size:13px;
  height:44px
 }
 .el-transaction--details,
 .single-savings .transaction-name {
  padding-right:10px
 }
 .summary-card {
  padding:20px 15px 32px
 }
}
























html {
 tab-size:4;
 line-height:1.15;
 -webkit-text-size-adjust:100%
}
body {
 font-family:system-ui,-apple-system,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"
}
hr {
 height:0;
 color:inherit
}
abbr[title] {
 -webkit-text-decoration:underline dotted;
 text-decoration:underline dotted
}
b,
strong {
 font-weight:bolder
}
code,
kbd,
pre,
samp {
 font-family:ui-monospace,SFMono-Regular,Consolas,"Liberation Mono",Menlo,monospace;
 font-size:1em
}
small {
 font-size:80%
}
sub,
sup {
 font-size:75%;
 line-height:0;
 position:relative;
 vertical-align:baseline
}
sub {
 bottom:-.25em
}
sup {
 top:-.5em
}
table {
 text-indent:0;
 border-color:inherit
}
button,
input,
optgroup,
select,
textarea {
 font-family: 'Montserrat';
 font-size:100%;
 line-height:1.15;
 margin:0
}
button,
select {
 text-transform:none
}
[type=button],
[type=reset],
[type=submit],
button {
 -webkit-appearance:button
}
::-moz-focus-inner {
 border-style:none;
 padding:0
}
legend {
 padding:0
}
progress {
 vertical-align:baseline
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
 height:auto
}
[type=search] {
 -webkit-appearance:textfield;
 outline-offset:-2px
}
::-webkit-search-decoration {
 -webkit-appearance:none
}
::-webkit-file-upload-button {
 -webkit-appearance:button;
 font:inherit
}
summary {
 display:list-item
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
 margin:0
}
button {
 background-color:transparent;
 background-image:none
}
fieldset,
ol,
ul {
 margin:0;
 padding:0
}
ol,
ul {
 list-style:none
}
html {
 font-family:ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
 line-height:1.5
}
body {
 font-family:inherit;
 line-height:inherit
}
*,
:after,
:before {
 box-sizing:border-box;
 border:0 solid
}
hr {
 border-top-width:1px
}
img {
 border-style:solid
}
textarea {
 resize:vertical
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
 opacity:1;
 color:#9ca3af
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
 opacity:1;
 color:#9ca3af
}
input::placeholder,
textarea::placeholder {
 opacity:1;
 color:#9ca3af
}
[role=button],
button {
 cursor:pointer
}
table {
 border-collapse:collapse
}
h1,
h2,
h3,
h4,
h5,
h6 {
 font-size:inherit;
 font-weight:inherit
}
a {
 color:inherit;
 text-decoration:inherit
}
button,
input,
optgroup,
select,
textarea {
 padding:0;
 line-height:inherit;
 color:inherit
}
code,
kbd,
pre,
samp {
 font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
 display:block;
 vertical-align:middle
}
img,
video {
 max-width:100%;
 height:auto
}
[hidden] {
 display:none
}
*,
:after,
:before {
 --tw-border-opacity:1;
 border-color:rgba(229,231,235,var(--tw-border-opacity))
}
.container {
 width:100%;
 margin-right:auto;
 margin-left:auto;
 padding-right:1.5rem;
 padding-left:1.5rem
}
@media (min-width:640px) {
 .container {
  max-width:640px;
  padding-right:2rem;
  padding-left:2rem
 }
}
@media (min-width:768px) {
 .container {
  max-width:768px
 }
}
@media (min-width:1024px) {
 .container {
  max-width:1024px;
  padding-right:4rem;
  padding-left:4rem
 }
}
@media (min-width:1280px) {
 .container {
  max-width:1280px;
  padding-right:5rem;
  padding-left:5rem
 }
}
@media (min-width:1440px) {
 .container {
  max-width:1440px
 }
}
@media (min-width:1536px) {
 .container {
  max-width:1536px
 }
}
@media (min-width:2300px) {
 .container {
  max-width:2300px
 }
}
.pointer-events-none {
 pointer-events:none
}
.visible {
 visibility:visible
}
.fixed {
 position:fixed
}
.absolute {
 position:absolute
}
.relative {
 position:relative
}
.sticky {
 position:-webkit-sticky;
 position:sticky
}
.top-0 {
 top:0
}
.top-5 {
 top:1.25rem
}
.top-14 {
 top:3.5rem
}
.right-5 {
 right:1.25rem
}
.bottom-0 {
 bottom:0
}
.bottom-3 {
 bottom:.75rem
}
.bottom-20 {
 bottom:5rem
}
.left-0 {
 left:0
}
.z-10 {
 z-index:10
}
.z-20 {
 z-index:20
}
.z-40 {
 z-index:40
}
.z-50 {
 z-index:50
}
.z-1000 {
 z-index:1000
}
.col-span-1 {
 grid-column:span 1/span 1
}
.col-span-2 {
 grid-column:span 2/span 2
}
.col-span-3 {
 grid-column:span 3/span 3
}
.col-span-4 {
 grid-column:span 4/span 4
}
.col-span-6 {
 grid-column:span 6/span 6
}
.col-span-7 {
 grid-column:span 7/span 7
}
.col-span-full {
 grid-column:1/-1
}
.m-0 {
 margin:0
}
.mx-auto {
 margin-left:auto;
 margin-right:auto
}
.mt-0 {
 margin-top:0
}
.mt-2 {
 margin-top:.5rem
}
.mt-3 {
 margin-top:.75rem
}
.mt-4 {
 margin-top:1rem
}
.mt-5 {
 margin-top:1.25rem
}
.mt-6 {
 margin-top:1.5rem
}
.mt-10 {
 margin-top:2.5rem
}
.mt-0\.5 {
 margin-top:.125rem
}
.mr-2 {
 margin-right:.5rem
}
.mr-3 {
 margin-right:.75rem
}
.mb-0 {
 margin-bottom:0
}
.mb-2 {
 margin-bottom:.5rem
}
.mb-3 {
 margin-bottom:.75rem
}
.mb-4 {
 margin-bottom:1rem
}
.mb-5 {
 margin-bottom:1.25rem
}
.mb-8 {
 margin-bottom:2rem
}
.mb-10 {
 margin-bottom:2.5rem
}
.mb-0\.5 {
 margin-bottom:.125rem
}
.ml-3 {
 margin-left:.75rem
}
.ml-4 {
 margin-left:1rem
}
.ml-auto {
 margin-left:auto
}
.box-border {
 box-sizing:border-box
}
.block {
 display:block
}
.inline-block {
 display:inline-block
}
.inline {
 display:inline
}
.flex {
 display:flex
}
.inline-flex {
 display:inline-flex
}
.table {
 display:table
}
.grid {
 display:grid
}
.hidden {
 display:none
}
.h-1 {
 height:.25rem
}
.h-2 {
 height:.5rem
}
.h-11 {
 height:2.75rem
}
.h-12 {
 height:3rem
}
.h-56 {
 height:14rem
}
.h-96 {
 height:24rem
}
.h-auto {
 height:auto
}
.h-full {
 height:100%
}
.h-maxContent {
 height:-webkit-max-content;
 height:max-content
}
.h-screen-60 {
 height:60vh
}
.max-h-screen-80 {
 max-height:80vh
}
.min-h-screen {
 min-height:100vh
}
.w-2 {
 width:.5rem
}
.w-10 {
 width:2.5rem
}
.w-12 {
 width:3rem
}
.w-14 {
 width:3.5rem
}
.w-20 {
 width:5rem
}
.w-24 {
 width:6rem
}
.w-44 {
 width:11rem
}
.w-64 {
 width:16rem
}
.w-3\/4 {
 width:75%
}
.w-full {
 width:100%
}
.min-w-screen-100 {
 min-width:100vw
}
.max-w-xs {
 max-width:20rem
}
.max-w-sm {
 max-width:24rem
}
.max-w-md {
 max-width:28rem
}
.max-w-lg {
 max-width:32rem
}
.max-w-2xl {
 max-width:42rem
}
.max-w-full {
 max-width:100%
}
.max-w-screen-sm {
 max-width:640px
}
.max-w-screen-md {
 max-width:768px
}
.max-w-1\/2 {
 max-width:50%
}
.max-w-3\/4 {
 max-width:75%
}
.flex-1 {
 flex:1 1
}
.flex-grow {
 flex-grow:1
}
.transform {
 --tw-translate-x:0;
 --tw-translate-y:0;
 --tw-rotate:0;
 --tw-skew-x:0;
 --tw-skew-y:0;
 --tw-scale-x:1;
 --tw-scale-y:1;
 -webkit-transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
 transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.translate-x-0 {
 --tw-translate-x:0px
}
.-translate-x-full {
 --tw-translate-x:-100%
}
@-webkit-keyframes spin {
 to {
  -webkit-transform:rotate(1turn);
  transform:rotate(1turn)
 }
}
@-webkit-keyframes ping {
 75%,
 to {
  -webkit-transform:scale(2);
  transform:scale(2);
  opacity:0
 }
}
@keyframes ping {
 75%,
 to {
  -webkit-transform:scale(2);
  transform:scale(2);
  opacity:0
 }
}
@-webkit-keyframes pulse {
 50% {
  opacity:.5
 }
}
@keyframes pulse {
 50% {
  opacity:.5
 }
}
@-webkit-keyframes bounce {
 0%,
 to {
  -webkit-transform:translateY(-25%);
  transform:translateY(-25%);
  -webkit-animation-timing-function:cubic-bezier(.8,0,1,1);
  animation-timing-function:cubic-bezier(.8,0,1,1)
 }
 50% {
  -webkit-transform:none;
  transform:none;
  -webkit-animation-timing-function:cubic-bezier(0,0,.2,1);
  animation-timing-function:cubic-bezier(0,0,.2,1)
 }
}
@keyframes bounce {
 0%,
 to {
  -webkit-transform:translateY(-25%);
  transform:translateY(-25%);
  -webkit-animation-timing-function:cubic-bezier(.8,0,1,1);
  animation-timing-function:cubic-bezier(.8,0,1,1)
 }
 50% {
  -webkit-transform:none;
  transform:none;
  -webkit-animation-timing-function:cubic-bezier(0,0,.2,1);
  animation-timing-function:cubic-bezier(0,0,.2,1)
 }
}
@-webkit-keyframes grow {
 0%,
 to {
  -webkit-transform:scale(1);
  transform:scale(1)
 }
 50% {
  -webkit-transform:scale(.8);
  transform:scale(.8)
 }
}
@keyframes grow {
 0%,
 to {
  -webkit-transform:scale(1);
  transform:scale(1)
 }
 50% {
  -webkit-transform:scale(.8);
  transform:scale(.8)
 }
}
@keyframes spin {
 to {
  -webkit-transform:rotate(1turn);
  transform:rotate(1turn)
 }
}
.animate-grow-2 {
 -webkit-animation:grow 2s linear infinite;
 animation:grow 2s linear infinite
}
.animate-spin-2 {
 -webkit-animation:spin 2s linear infinite;
 animation:spin 2s linear infinite
}
.cursor-pointer {
 cursor:pointer
}
.resize {
 resize:both
}
.grid-cols-1 {
 grid-template-columns:repeat(1,minmax(0,1fr))
}
.grid-cols-2 {
 grid-template-columns:repeat(2,minmax(0,1fr))
}
.grid-cols-3 {
 grid-template-columns:repeat(3,minmax(0,1fr))
}
.grid-cols-12 {
 grid-template-columns:repeat(12,minmax(0,1fr))
}
.flex-row {
 flex-direction:row
}
.flex-col {
 flex-direction:column
}
.flex-wrap {
 flex-wrap:wrap
}
.flex-nowrap {
 flex-wrap:nowrap
}
.place-content-start {
 place-content:start
}
.items-start {
 align-items:flex-start
}
.items-end {
 align-items:flex-end
}
.items-center {
 align-items:center
}
.justify-start {
 justify-content:flex-start
}
.justify-end {
 justify-content:flex-end
}
.justify-center {
 justify-content:center
}
.justify-between {
 justify-content:space-between
}
.gap-2 {
 grid-gap:.5rem;
 gap:.5rem
}
.gap-3 {
 grid-gap:.75rem;
 gap:.75rem
}
.gap-4 {
 grid-gap:1rem;
 gap:1rem
}
.gap-5 {
 grid-gap:1.25rem;
 gap:1.25rem
}
.gap-6 {
 grid-gap:1.5rem;
 gap:1.5rem
}
.gap-8 {
 grid-gap:2rem;
 gap:2rem
}
.gap-10 {
 grid-gap:2.5rem;
 gap:2.5rem
}
.gap-x-2 {
 -webkit-column-gap:.5rem;
 grid-column-gap:.5rem;
 column-gap:.5rem
}
.gap-x-4 {
 -webkit-column-gap:1rem;
 grid-column-gap:1rem;
 column-gap:1rem
}
.gap-x-6 {
 -webkit-column-gap:1.5rem;
 grid-column-gap:1.5rem;
 column-gap:1.5rem
}
.gap-y-3 {
 grid-row-gap:.75rem;
 row-gap:.75rem
}
.gap-y-6 {
 grid-row-gap:1.5rem;
 row-gap:1.5rem
}
.gap-y-10 {
 grid-row-gap:2.5rem;
 row-gap:2.5rem
}
.space-x-2>:not([hidden])~:not([hidden]) {
 --tw-space-x-reverse:0;
 margin-right:calc(0.5rem*var(--tw-space-x-reverse));
 margin-left:calc(0.5rem*(1 - var(--tw-space-x-reverse)))
}
.space-x-3>:not([hidden])~:not([hidden]) {
 --tw-space-x-reverse:0;
 margin-right:calc(0.75rem*var(--tw-space-x-reverse));
 margin-left:calc(0.75rem*(1 - var(--tw-space-x-reverse)))
}
.space-x-4>:not([hidden])~:not([hidden]) {
 --tw-space-x-reverse:0;
 margin-right:calc(1rem*var(--tw-space-x-reverse));
 margin-left:calc(1rem*(1 - var(--tw-space-x-reverse)))
}
.space-x-5>:not([hidden])~:not([hidden]) {
 --tw-space-x-reverse:0;
 margin-right:calc(1.25rem*var(--tw-space-x-reverse));
 margin-left:calc(1.25rem*(1 - var(--tw-space-x-reverse)))
}
.space-x-6>:not([hidden])~:not([hidden]) {
 --tw-space-x-reverse:0;
 margin-right:calc(1.5rem*var(--tw-space-x-reverse));
 margin-left:calc(1.5rem*(1 - var(--tw-space-x-reverse)))
}
.space-x-8>:not([hidden])~:not([hidden]) {
 --tw-space-x-reverse:0;
 margin-right:calc(2rem*var(--tw-space-x-reverse));
 margin-left:calc(2rem*(1 - var(--tw-space-x-reverse)))
}
.space-y-1>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(0.25rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(0.25rem*var(--tw-space-y-reverse))
}
.space-y-2>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(0.5rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(0.5rem*var(--tw-space-y-reverse))
}
.space-y-3>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(0.75rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(0.75rem*var(--tw-space-y-reverse))
}
.space-y-4>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(1rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(1rem*var(--tw-space-y-reverse))
}
.space-y-5>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(1.25rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(1.25rem*var(--tw-space-y-reverse))
}
.space-y-6>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(1.5rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(1.5rem*var(--tw-space-y-reverse))
}
.space-y-7>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(1.75rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(1.75rem*var(--tw-space-y-reverse))
}
.space-y-8>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(2rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(2rem*var(--tw-space-y-reverse))
}
.space-y-10>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(2.5rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(2.5rem*var(--tw-space-y-reverse))
}
.space-y-12>:not([hidden])~:not([hidden]) {
 --tw-space-y-reverse:0;
 margin-top:calc(3rem*(1 - var(--tw-space-y-reverse)));
 margin-bottom:calc(3rem*var(--tw-space-y-reverse))
}
.divide-y>:not([hidden])~:not([hidden]) {
 --tw-divide-y-reverse:0;
 border-top-width:calc(1px*(1 - var(--tw-divide-y-reverse)));
 border-bottom-width:calc(1px*var(--tw-divide-y-reverse))
}
.divide-solid>:not([hidden])~:not([hidden]) {
 border-style:solid
}
.divide-gray-100>:not([hidden])~:not([hidden]) {
 --tw-divide-opacity:1;
 border-color:rgba(243,244,246,var(--tw-divide-opacity))
}
.divide-gray-200>:not([hidden])~:not([hidden]) {
 --tw-divide-opacity:1;
 border-color:rgba(229,231,235,var(--tw-divide-opacity))
}
.divide-gray-300>:not([hidden])~:not([hidden]) {
 --tw-divide-opacity:1;
 border-color:rgba(209,213,219,var(--tw-divide-opacity))
}
.divide-el-gray-light>:not([hidden])~:not([hidden]) {
 --tw-divide-opacity:1;
 border-color:rgba(97,97,97,var(--tw-divide-opacity))
}
.divide-el-light-blue>:not([hidden])~:not([hidden]) {
 --tw-divide-opacity:1;
 border-color:rgba(242,245,252,var(--tw-divide-opacity))
}
.divide-opacity-10>:not([hidden])~:not([hidden]) {
 --tw-divide-opacity:0.1
}
.self-center {
 align-self:center
}
.overflow-hidden {
 overflow:hidden
}
.overflow-y-scroll {
 overflow-y:scroll
}
.break-all {
 word-break:break-all
}
.rounded-sm {
 border-radius:.125rem
}
.rounded {
 border-radius:.25rem
}
.rounded-md {
 border-radius:.375rem
}
.rounded-lg {
 border-radius:.5rem
}
.rounded-2xl {
 border-radius:1rem
}
.rounded-full {
 border-radius:9999px
}
.rounded-t {
 border-top-left-radius:.25rem;
 border-top-right-radius:.25rem
}
.border-0 {
 border-width:0
}
.border-2 {
 border-width:2px
}
.border {
 border-width:1px
}
.border-t-0 {
 border-top-width:0
}
.border-t {
 border-top-width:1px
}
.border-r-0 {
 border-right-width:0
}
.border-r {
 border-right-width:1px
}
.border-b-0 {
 border-bottom-width:0
}
.border-b-2 {
 border-bottom-width:2px
}
.border-b {
 border-bottom-width:1px
}
.border-l-0 {
 border-left-width:0
}
.border-solid {
 border-style:solid
}
.border-none {
 border-style:none
}
.border-transparent {
 border-color:transparent
}
.border-white {
 --tw-border-opacity:1;
 border-color:rgba(255,255,255,var(--tw-border-opacity))
}
.border-gray-100 {
 --tw-border-opacity:1;
 border-color:rgba(243,244,246,var(--tw-border-opacity))
}
.border-gray-200 {
 --tw-border-opacity:1;
 border-color:rgba(229,231,235,var(--tw-border-opacity))
}
.border-gray-400 {
 --tw-border-opacity:1;
 border-color:rgba(156,163,175,var(--tw-border-opacity))
}
.border-el-border-gray {
 --tw-border-opacity:1;
 border-color:rgba(204,204,204,var(--tw-border-opacity))
}
.border-el-dark-red {
 --tw-border-opacity:1;
 border-color:rgba(171,11,75,var(--tw-border-opacity))
}
.border-el-gray-light {
 --tw-border-opacity:1;
 border-color:rgba(97,97,97,var(--tw-border-opacity))
}
.border-el-light-blue {
 --tw-border-opacity:1;
 border-color:rgba(242,245,252,var(--tw-border-opacity))
}
.border-el-red {
 --tw-border-opacity:1;
 border-color:var(--color-primary)
}
.focus-within\:border-el-red:focus-within {
 --tw-border-opacity:1;
 border-color:var(--color-primary)
}
.hover\:border-white:hover {
 --tw-border-opacity:1;
 border-color:rgba(255,255,255,var(--tw-border-opacity))
}
.focus\:border-gray-400:focus {
 --tw-border-opacity:1;
 border-color:rgba(156,163,175,var(--tw-border-opacity))
}
.border-opacity-0 {
 --tw-border-opacity:0
}
.border-opacity-10 {
 --tw-border-opacity:0.1
}
.bg-transparent {
 background-color:transparent
}
.bg-black {
 --tw-bg-opacity:1;
 background-color:rgba(0,0,0,var(--tw-bg-opacity))
}
.bg-white {
 --tw-bg-opacity:1;
 background-color:rgba(255,255,255,var(--tw-bg-opacity))
}
.bg-gray-300 {
 --tw-bg-opacity:1;
 background-color:rgba(209,213,219,var(--tw-bg-opacity))
}
.bg-el-blue {
 --tw-bg-opacity:1;
 background-color:rgba(30,32,59,var(--tw-bg-opacity))
}
.bg-el-dark-red {
 --tw-bg-opacity:1;
 background-color:var(--color-primary)
}
.bg-el-faint-red {
 --tw-bg-opacity:1;
 background-color:rgba(248,232,232,var(--tw-bg-opacity))
}
.bg-el-gray-light {
 --tw-bg-opacity:1;
 background-color:rgba(97,97,97,var(--tw-bg-opacity))
}
.bg-el-light-blue {
 --tw-bg-opacity:1;
 background-color:rgba(242,245,252,var(--tw-bg-opacity))
}
.bg-el-light-red {
 --tw-bg-opacity:1;
 background-color:rgba(243,233,237,var(--tw-bg-opacity))
}
.bg-el-pink-red {
 --tw-bg-opacity:1;
 background-color:rgba(244,233,237,var(--tw-bg-opacity))
}
.bg-el-progress-gray {
 --tw-bg-opacity:1;
 background-color:rgba(233,236,239,var(--tw-bg-opacity))
}
.bg-el-red {
 --tw-bg-opacity:1;
 background-color:var(--color-primary)
}
.bg-el-tag-red {
 --tw-bg-opacity:1;
 background-color:rgba(246,230,235,var(--tw-bg-opacity))
}
.bg-el-violet {
 --tw-bg-opacity:1;
 background-color:rgba(67,6,60,var(--tw-bg-opacity))
}
.hover\:bg-white:hover {
 --tw-bg-opacity:1;
 background-color:rgba(255,255,255,var(--tw-bg-opacity))
}
.hover\:bg-gray-50:hover {
 --tw-bg-opacity:1;
 background-color:rgba(249,250,251,var(--tw-bg-opacity))
}
.hover\:bg-el-light-blue:hover {
 --tw-bg-opacity:1;
 background-color:rgba(242,245,252,var(--tw-bg-opacity))
}
.hover\:bg-el-red:hover {
 --tw-bg-opacity:1;
 background-color:var(--color-primary);
}
.focus\:bg-gray-100:focus {
 --tw-bg-opacity:1;
 background-color:rgba(243,244,246,var(--tw-bg-opacity))
}
.bg-opacity-10 {
 --tw-bg-opacity:0.1
}
.bg-opacity-20 {
 --tw-bg-opacity:0.2
}
.bg-opacity-25 {
 --tw-bg-opacity:0.25
}
.bg-opacity-50 {
 --tw-bg-opacity:0.5
}
.bg-opacity-95 {
 --tw-bg-opacity:0.95
}
.bg-cover {
 background-size:cover
}
.bg-center {
 background-position:50%
}
.p-1 {
 padding:.25rem
}
.p-2 {
 padding:.5rem
}
.p-3 {
 padding:.75rem
}
.p-4 {
 padding:1rem
}
.p-5 {
 padding:1.25rem
}
.p-6 {
 padding:1.5rem
}
.p-8 {
 padding:2rem
}
.p-9 {
 padding:2.25rem
}
.p-2\.5 {
 padding:.625rem
}
.px-1 {
 padding-left:.25rem;
 padding-right:.25rem
}
.px-2 {
 padding-left:.5rem;
 padding-right:.5rem
}
.px-3 {
 padding-left:.75rem;
 padding-right:.75rem
}
.px-4 {
 padding-left:1rem;
 padding-right:1rem
}
.px-5 {
 padding-left:1.25rem;
 padding-right:1.25rem
}
.px-6 {
 padding-left:1.5rem;
 padding-right:1.5rem
}
.px-8 {
 padding-left:2rem;
 padding-right:2rem
}
.px-9 {
 padding-left:2.25rem;
 padding-right:2.25rem
}
.px-10 {
 padding-left:2.5rem;
 padding-right:2.5rem
}
.px-1\.5 {
 padding-left:.375rem;
 padding-right:.375rem
}
.py-1 {
 padding-top:.25rem;
 padding-bottom:.25rem
}
.py-2 {
 padding-top:.5rem;
 padding-bottom:.5rem
}
.py-3 {
 padding-top:.75rem;
 padding-bottom:.75rem
}
.py-4 {
 padding-top:1rem;
 padding-bottom:1rem
}
.py-8 {
 padding-top:2rem;
 padding-bottom:2rem
}
.py-10 {
 padding-top:2.5rem;
 padding-bottom:2.5rem
}
.py-12 {
 padding-top:3rem;
 padding-bottom:3rem
}
.py-14 {
 padding-top:3.5rem;
 padding-bottom:3.5rem
}
.py-20 {
 padding-top:5rem;
 padding-bottom:5rem
}
.pt-2 {
 padding-top:.5rem
}
.pt-3 {
 padding-top:.75rem
}
.pt-6 {
 padding-top:1.5rem
}
.pr-1 {
 padding-right:.25rem
}
.pr-4 {
 padding-right:1rem
}
.pr-5 {
 padding-right:1.25rem
}
.pr-28 {
 padding-right:7rem
}
.pr-1\.5 {
 padding-right:.375rem
}
.pb-3 {
 padding-bottom:.75rem
}
.pb-14 {
 padding-bottom:3.5rem
}
.pl-3 {
 padding-left:.75rem
}
.pl-5 {
 padding-left:1.25rem
}
.pl-6 {
 padding-left:1.5rem
}
.pl-8 {
 padding-left:2rem
}
.text-left {
 text-align:left
}
.text-center {
 text-align:center
}
.text-right {
 text-align:right
}
.text-xs {
 font-size:.75rem;
 line-height:1rem
}
.text-sm {
 font-size:.875rem;
 line-height:1.25rem
}
.text-base {
 font-size:1rem;
 line-height:1.5rem
}
.text-lg {
 font-size:1.125rem
}
.text-lg,
.text-xl {
 line-height:1.75rem
}
.text-xl {
 font-size:1.25rem
}
.text-2xl {
 font-size:1.5rem;
 line-height:2rem
}
.text-6xl {
 font-size:3.75rem;
 line-height:1
}
.font-light {
 font-weight:300
}
.font-normal {
 font-weight:400
}
.font-medium {
 font-weight:500
}
.font-semibold {
 font-weight:600
}
.font-bold {
 font-weight:700
}
.font-extrabold {
 font-weight:800
}
.uppercase {
 text-transform:uppercase
}
.lowercase {
 text-transform:lowercase
}
.capitalize {
 text-transform:capitalize
}
.text-black {
 --tw-text-opacity:1;
 color:rgba(0,0,0,var(--tw-text-opacity))
}
.text-white {
 --tw-text-opacity:1;
 color:rgba(255,255,255,var(--tw-text-opacity))
}
.text-gray-300 {
 --tw-text-opacity:1;
 color:rgba(209,213,219,var(--tw-text-opacity))
}
.text-gray-400 {
 --tw-text-opacity:1;
 color:rgba(156,163,175,var(--tw-text-opacity))
}
.text-gray-500 {
 --tw-text-opacity:1;
 color:rgba(107,114,128,var(--tw-text-opacity))
}
.text-gray-600 {
 --tw-text-opacity:1;
 color:rgba(75,85,99,var(--tw-text-opacity))
}
.text-gray-800 {
 --tw-text-opacity:1;
 color:rgba(31,41,55,var(--tw-text-opacity))
}
.text-el-dark-red {
 --tw-text-opacity:1;
 color:rgba(171,11,75,var(--tw-text-opacity))
}
.text-el-gray {
 --tw-text-opacity:1;
 color:rgba(77,77,77,var(--tw-text-opacity))
}
.text-el-gray-light {
 --tw-text-opacity:1;
 color:rgba(97,97,97,var(--tw-text-opacity))
}
.text-el-green {
 --tw-text-opacity:1;
 color:rgba(0,193,110,var(--tw-text-opacity))
}
.text-el-light-blue {
 --tw-text-opacity:1;
 color:rgba(242,245,252,var(--tw-text-opacity))
}
.text-el-light-red {
 --tw-text-opacity:1;
 color:rgba(243,233,237,var(--tw-text-opacity))
}
.text-el-red {
 --tw-text-opacity:1;
 color:var(--color-primary);
}
.hover\:text-white:hover {
 --tw-text-opacity:1;
 color:rgba(255,255,255,var(--tw-text-opacity))
}
.text-opacity-50 {
 --tw-text-opacity:0.5
}
.hover\:underline:hover,
.underline {
 text-decoration:underline
}
.opacity-50 {
 opacity:.5
}
.opacity-60 {
 opacity:.6
}
.opacity-100 {
 opacity:1
}
.hover\:opacity-85:hover {
 opacity:.85
}
*,
:after,
:before {
 --tw-shadow:0 0 transparent
}
.shadow-md {
 --tw-shadow:0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)
}
.shadow-el,
.shadow-md {
 box-shadow:0 0 transparent,0 0 transparent,var(--tw-shadow);
 box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow)
}
.shadow-el {
 --tw-shadow:1px 0 20px rgba(0,0,0,0.08)
}
.shadow-custom {
 --tw-shadow:0 5px 20px rgba(0,0,0,0.05);
 box-shadow:0 0 transparent,0 0 transparent,var(--tw-shadow);
 box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow)
}
.focus\:outline-none:focus,
.outline-none {
 outline:2px solid transparent;
 outline-offset:2px
}
*,
:after,
:before {
 --tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);
 --tw-ring-offset-width:0px;
 --tw-ring-offset-color:#fff;
 --tw-ring-color:rgba(59,130,246,0.5);
 --tw-ring-offset-shadow:0 0 transparent;
 --tw-ring-shadow:0 0 transparent
}
.filter {
 --tw-blur:var(--tw-empty,/*!*/ /*!*/);
 --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
 --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
 --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
 --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
 --tw-invert:var(--tw-empty,/*!*/ /*!*/);
 --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
 --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
 --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
 -webkit-filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
 filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.blur {
 --tw-blur:blur(8px)
}
.backdrop-filter {
 --tw-backdrop-blur:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-brightness:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-contrast:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-grayscale:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-invert:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-opacity:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-saturate:var(--tw-empty,/*!*/ /*!*/);
 --tw-backdrop-sepia:var(--tw-empty,/*!*/ /*!*/);
 -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
 backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}
.backdrop-blur-sm {
 --tw-backdrop-blur:blur(4px)
}
.transition-all {
 transition-property:all;
 transition-timing-function:cubic-bezier(.4,0,.2,1);
 transition-duration:.15s
}
.transition {
 transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
 transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
 transition-property:background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
 transition-timing-function:cubic-bezier(.4,0,.2,1);
 transition-duration:.15s
}
.duration-200 {
 transition-duration:.2s
}
.duration-300 {
 transition-duration:.3s
}
.duration-500 {
 transition-duration:.5s
}
.ease-in-out {
 transition-timing-function:cubic-bezier(.4,0,.2,1)
}
@media (min-width:768px) {
 .md\:col-span-2 {
  grid-column:span 2/span 2
 }
 .md\:col-span-5 {
  grid-column:span 5/span 5
 }
 .md\:col-span-6 {
  grid-column:span 6/span 6
 }
 .md\:mb-0 {
  margin-bottom:0
 }
 .md\:w-auto {
  width:auto
 }
 .md\:w-1\/2 {
  width:50%
 }
 .md\:w-full {
  width:100%
 }
 .md\:max-w-3\/4 {
  max-width:75%
 }
 .md\:grid-cols-2 {
  grid-template-columns:repeat(2,minmax(0,1fr))
 }
 .md\:grid-cols-3 {
  grid-template-columns:repeat(3,minmax(0,1fr))
 }
 .md\:grid-cols-4 {
  grid-template-columns:repeat(4,minmax(0,1fr))
 }
 .md\:grid-cols-11 {
  grid-template-columns:repeat(11,minmax(0,1fr))
 }
 .md\:flex-nowrap {
  flex-wrap:nowrap
 }
 .md\:space-x-4>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse:0;
  margin-right:calc(1rem*var(--tw-space-x-reverse));
  margin-left:calc(1rem*(1 - var(--tw-space-x-reverse)))
 }
 .md\:space-x-6>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse:0;
  margin-right:calc(1.5rem*var(--tw-space-x-reverse));
  margin-left:calc(1.5rem*(1 - var(--tw-space-x-reverse)))
 }
 .md\:space-x-10>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse:0;
  margin-right:calc(2.5rem*var(--tw-space-x-reverse));
  margin-left:calc(2.5rem*(1 - var(--tw-space-x-reverse)))
 }
 .md\:space-y-0>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse:0;
  margin-top:calc(0px*(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(0px*var(--tw-space-y-reverse))
 }
 .md\:p-5 {
  padding:1.25rem
 }
 .md\:p-8 {
  padding:2rem
 }
 .md\:pt-16 {
  padding-top:4rem
 }
}
@media (min-width:1024px) {
 .lg\:col-span-2 {
  grid-column:span 2/span 2
 }
 .lg\:col-span-3 {
  grid-column:span 3/span 3
 }
 .lg\:col-span-4 {
  grid-column:span 4/span 4
 }
 .lg\:col-span-5 {
  grid-column:span 5/span 5
 }
 .lg\:col-span-6 {
  grid-column:span 6/span 6
 }
 .lg\:col-span-7 {
  grid-column:span 7/span 7
 }
 .lg\:col-span-8 {
  grid-column:span 8/span 8
 }
 .lg\:grid {
  display:grid
 }
 .lg\:w-3\/4 {
  width:75%
 }
 .lg\:w-8\/12 {
  width:66.666667%
 }
 .lg\:max-w-3\/4 {
  max-width:75%
 }
 .lg\:grid-cols-2 {
  grid-template-columns:repeat(2,minmax(0,1fr))
 }
 .lg\:grid-cols-3 {
  grid-template-columns:repeat(3,minmax(0,1fr))
 }
 .lg\:grid-cols-6 {
  grid-template-columns:repeat(6,minmax(0,1fr))
 }
 .lg\:grid-cols-10 {
  grid-template-columns:repeat(10,minmax(0,1fr))
 }
 .lg\:space-y-8>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse:0;
  margin-top:calc(2rem*(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(2rem*var(--tw-space-y-reverse))
 }
 .lg\:p-7 {
  padding:1.75rem
 }
}
@media (min-width:1280px) {
 .xl\:grid-cols-3 {
  grid-template-columns:repeat(3,minmax(0,1fr))
 }
 .xl\:grid-cols-4 {
  grid-template-columns:repeat(4,minmax(0,1fr))
 }
}
* {
 scroll-behavior:smooth
}
:focus {
 outline-color:#43063c!important
}
.noScrollbar::-webkit-scrollbar {
 width:0;
 background:transparent
}
.remove-scrollbar {
 overflow-y:scroll;
 scrollbar-width:none;
 -ms-overflow-style:none
}
.remove-scrollbar::-webkit-scrollbar {
 width:0;
 height:0
}
body {
 margin:0;
 font-weight:500;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 font-display:swap;
 font-size:1rem
}
code {
 font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace
}
button:focus {
 outline:none!important
}
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
 -webkit-transition:"color 9999s ease-out, background-color 9999s ease-out";
 transition:"color 9999s ease-out, background-color 9999s ease-out";
 transition-delay:9999s
}
.loading-dot:after {
 content:" .";
 -webkit-animation:dots 2s steps(5) infinite;
 animation:dots 2s steps(5) infinite
}
@-webkit-keyframes dots {
 0%,
 20% {
  color:transparent;
  text-shadow:.25em 0 0 transparent,.5em 0 0 transparent
 }
 40% {
  color:#fff;
  text-shadow:.25em 0 0 transparent,.5em 0 0 transparent
 }
 60% {
  text-shadow:.25em 0 0 #fff,.5em 0 0 transparent
 }
 80%,
 to {
  text-shadow:.25em 0 0 #fff,.5em 0 0 #fff
 }
}
@keyframes dots {
 0%,
 20% {
  color:transparent;
  text-shadow:.25em 0 0 transparent,.5em 0 0 transparent
 }
 40% {
  color:#fff;
  text-shadow:.25em 0 0 transparent,.5em 0 0 transparent
 }
 60% {
  text-shadow:.25em 0 0 #fff,.5em 0 0 transparent
 }
 80%,
 to {
  text-shadow:.25em 0 0 #fff,.5em 0 0 #fff
 }
}
.terms-conditions-text {
 font-weight:400
}
.terms-conditions-text .feature-text {
 font-weight:600
}
.terms-conditions-sub-title {
 font-weight:700;
 margin-bottom:0
}
.no-outline {
 outline:none!important
}
.home-banner {
 background-repeat:no-repeat!important;
 background:transparent;
 background-size:cover
}
.banner {
  position: relative;
  background: var(--color-primary);
  background-size: cover;
}
.cropper-crop-container {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0
}
.cropper-controls {
 position:absolute;
 bottom:0;
 left:50%;
 width:100%;
 -webkit-transform:translateX(-50%);
 transform:translateX(-50%);
 height:80px;
 display:flex;
 align-items:center
}
.cropper-slider {
 padding:1rem 0;
 height:auto;
 position:relative;
 width:100%;
 overflow:hidden;
 border:none;
 box-shadow:none;
 background:none;
 margin:0 auto
}
.uploadBox {
 width:300px;
 height:300px;
 border-radius:0;
 background-color:#eee;
 margin-bottom:10px;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 position:relative;
 background-position:50%;
 background-size:cover;
 background-repeat:no-repeat;
 z-index:1;
 max-width:100%
}
.uploadBox.rectangle {
 width:100%;
 width:-webkit-fill-available;
 height:224.999px
}
.uploadBox.circle {
 width:300px;
 height:300px;
 border-radius:50%;
 overflow:hidden
}
.uploadBox.circle:before {
 content:"";
 display:block;
 position:absolute;
 background-color:transparent;
 top:0;
 left:0;
 width:100%;
 height:100%;
 z-index:0;
 transition:all .3s ease-in-out
}
.uploadBox.circle:hover:before {
 background-color:rgba(0,0,0,.5)
}
.uploadBox.circle button {
 cursor:pointer;
 text-align:center;
 color:#868686;
 opacity:1;
 font-size:1rem;
 border-radius:15px;
 min-width:100px;
 padding:.25rem .5rem;
 outline:none!important;
 background-color:transparent;
 transition:all .3s ease-in-out;
 border-color:#868686;
 z-index:1;
 border-style:solid
}
.uploadBox.circle:hover button {
 color:#fff;
 border-color:#fff;
 opacity:1
}
.card-bg {
 background-repeat:no-repeat!important;
 background:transparent;
 background-size:cover
}
.card-bg.red {
 background:transparent;
 background-color:#9d0f3e;
 color:#fff
}
.card-bg.black {
 background:transparent;
 background-color:#000;
 color:#fff
}
.card-bg.gradient {
 background:linear-gradient(150.04deg,#ab0b28,rgba(127,6,78,.94953) 98.67%,rgba(126,6,79,.864442));
 background-image:linear-gradient(150.04deg,#ab0b28,rgba(127,6,78,.94953) 98.67%,rgba(126,6,79,.864442));
 color:#fff
}
.cardt-bg {
 background-repeat:no-repeat!important;
 background:transparent;
 background-size:cover
}
.cardt-bg.red {
 background:transparent;
 background-color:#9d0f3e;
 color:#fff
}
.cardt-bg.black {
 background:transparent;
 background-color:#000;
 color:#fff
}
.date {
 width:30.934345245361328px;
 left:553.6435546875px
}
.date,
.datenum {
 height:21.208478927612305px;
 top:602.0277099609375px;
 border-radius:nullpx
}
.datenum {
 width:48.120094299316406px;
 right:584.57861328125px
}
.successbox {
 position:absolute;
 width:500px;
 height:400px;
 left:700px;
 top:333px;
 background:#fff;
 border:1px solid #ededed;
 box-shadow:0 4px 10px rgba(0,0,0,.06);
 border-radius:6px
}
.success {
 height:36px;
 left:46.6%;
 right:46.53%;
 top:466px;
 font-weight:600;
 font-size:24px;
 line-height:146.28%;
 color:var(--color-primary);
}
.success,
.successtext {
 position:absolute;
 font-family:Montserrat;
 font-style:normal;
 text-align:center
}
.successtext {
 width:383px;
 height:44px;
 left:540;
 top:508;
 font-weight:500;
 font-size:18px;
 line-height:155%;
 letter-spacing:-.25px;
 color:#000
}
.successbtn {
 position:absolute;
 left:36.46%;
 right:36.46;
 top:80.04%;
 bottom:34.27%;
 background:#9f083e
}
.successicon {
 position:absolute;
 left:0;
 right:0;
 top:0;
 bottom:0
}

.elSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.3928571428571428rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
}
.elSvgIcon-colorPrimary {
  color: #ab2656;
}
.elSvgIcon-colorSecondary {
  color: #43063C;
}
.elSvgIcon-colorAction {
  color: rgba(0, 0, 0, 0.54);
}
.elSvgIcon-colorError {
  color: #ab2656;
}
.elSvgIcon-colorDisabled {
  color: #A6A6A6;
}
.elSvgIcon-fontSizeInherit {
  font-size: inherit;
}
.elSvgIcon-fontSizeSmall {
  font-size: 1.1607142857142858rem;
}
.elSvgIcon-fontSizeLarge {
  font-size: 2.03125rem;
}


.jss10 {
  padding: 8px;
}
.jss11 {
  color: #757575;
  width: 100%;
  display: flex;
  position: relative;
  font-size: 0.9285714285714286rem;
  transform: translate(0, 0) scale(1);
  align-items: center;
  margin-bottom: 8px;
  justify-content: Space-between;
}
.jss11.el-disabled {
  color: #757575;
}
.jss11.el-error {
  color: #ab2656;
}
.jss11.el-focused {
  color: #43063C;
}
.jss12 {
  color: #757575;
  width: 100%;
  border: 1px solid #ced4da;
  height: fit-content;
  padding: 5px 0 5px 12px;
  position: relative;
  font-size: 1rem;
  min-width: 100px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  background-color: #fff;
}
.jss12 .elInputBase-input {
  height: 100%;
}
.jss12 input[type=number] {
  -moz-appearance: textfield;
}
.jss12.el-disabled {
  color: #757575;
  background-color: #fafafa;
}
.jss12.el-error {
  border-color: #ab2656;
}
.jss12.el-focused {
  border-color: #9AA19E;
  background-color: #fafafa;
}
.jss12.password-field {
  padding: 5px 5px 5px 12px;
}
.jss12.text-center .elInputBase-input {
  text-align: center;
}
.jss12 input[type=number]::-webkit-inner-spin-button, .jss12 input[type=number] ::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.jss12 .elInputBase-input::placeholder {
  color: #bdbdbd;
  font-weight: 400;
}

.elTypography-root {
  margin: 0;
  line-height: 1.5;
}
.elTypography-body2 {
  color: #4D4D4D;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.43;
}
.elTypography-body1 {
  color: #4D4D4D;
  font-size: 0.9285714285714286rem;
  font-weight: 400;
  line-height: 1.5;
}
.elTypography-caption {
  color: #43063C;
  font-size: 0.6964285714285714rem;
  font-weight: 400;
  line-height: 1.66;
}
.elTypography-button {
  color: #444444;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
}
.elTypography-h1 {
  color: #444444;
  font-size: 3.2857142857142856rem;
  font-weight: 700;
  line-height: 1.167;
}
@media (min-width:640px) {
  .elTypography-h1 {
    font-size: 4.7129rem;
  }
}
@media (min-width:768px) {
  .elTypography-h1 {
    font-size: 4.9272rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h1 {
    font-size: 5.5698rem;
  }
}
.elTypography-h2 {
  color: #444444;
  font-size: 2.241071428571429rem;
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width:640px) {
  .elTypography-h2 {
    font-size: 2.9167rem;
  }
}
@media (min-width:768px) {
  .elTypography-h2 {
    font-size: 3.125rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h2 {
    font-size: 3.5417rem;
  }
}
.elTypography-h3 {
  color: #444444;
  font-size: 1.8928571428571428rem;
  font-weight: 700;
  line-height: 1.167;
}
@media (min-width:640px) {
  .elTypography-h3 {
    font-size: 2.3565rem;
  }
}
@media (min-width:768px) {
  .elTypography-h3 {
    font-size: 2.5707rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h3 {
    font-size: 2.7849rem;
  }
}
.elTypography-h4 {
  color: #444444;
  font-size: 1.4866071428571428rem;
  font-weight: 700;
  line-height: 1.235;
}
@media (min-width:640px) {
  .elTypography-h4 {
    font-size: 1.8219rem;
  }
}
@media (min-width:768px) {
  .elTypography-h4 {
    font-size: 1.8219rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h4 {
    font-size: 2.0243rem;
  }
}
.elTypography-h5 {
  color: #444444;
  font-size: 1.1964285714285714rem;
  font-weight: 700;
  line-height: 1.334;
}
@media (min-width:640px) {
  .elTypography-h5 {
    font-size: 1.3118rem;
  }
}
@media (min-width:768px) {
  .elTypography-h5 {
    font-size: 1.3118rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h5 {
    font-size: 1.3118rem;
  }
}
.elTypography-h6 {
  color: #444444;
  font-size: 1.0803571428571428rem;
  font-weight: 500;
  line-height: 1.6;
}
@media (min-width:640px) {
  .elTypography-h6 {
    font-size: 1.0938rem;
  }
}
@media (min-width:768px) {
  .elTypography-h6 {
    font-size: 1.0938rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h6 {
    font-size: 1.0938rem;
  }
}
.elTypography-subtitle1 {
  color: #444444;
  font-size: 0.9285714285714286rem;
  font-weight: 400;
  line-height: 1.75;
}
.elTypography-subtitle2 {
  color: #43063C;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.57;
}
.elTypography-overline {
  color: #43063C;
  font-size: 0.6964285714285714rem;
  font-weight: 400;
  line-height: 2.66;
  text-transform: uppercase;
}
.elTypography-srOnly {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}
.elTypography-alignLeft {
  text-align: left;
}
.elTypography-alignCenter {
  text-align: center;
}
.elTypography-alignRight {
  text-align: right;
}
.elTypography-alignJustify {
  text-align: justify;
}
.elTypography-noWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elTypography-gutterBottom {
  margin-bottom: 10px;
}
.elTypography-paragraph {
  margin-bottom: 16px;
}
.elTypography-colorInherit {
  color: inherit;
}
.elTypography-colorPrimary {
  color: #ab2656;
}
.elTypography-colorSecondary {
  color: #43063C;
}
.elTypography-colorTextPrimary {
  color: #444444;
}
.elTypography-colorTextSecondary {
  color: #43063C;
}
.elTypography-colorError {
  color: #ab2656;
}
.elTypography-displayInline {
  display: inline;
}
.elTypography-displayBlock {
  display: block;
}


.elButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  -moz-user-select: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  min-width: 0;
}
.elButtonBase-root::-moz-focus-inner {
  border-style: none;
}
.elButtonBase-root.el-disabled {
  cursor: default;
  pointer-events: none;
}
@media print {
  .elButtonBase-root {
    print-color-adjust: exact;
  }
}


@-moz-keyframes mui-auto-fill {}
@-moz-keyframes mui-auto-fill-cancel {}
.elInputBase-root {
  color: #444444;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 0.9285714285714286rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
}
.elInputBase-root.el-disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}
.elInputBase-multiline {
  padding: 6px 0 7px;
}
.elInputBase-multiline.elInputBase-marginDense {
  padding-top: 3px;
}
.elInputBase-fullWidth {
  width: 100%;
}
.elInputBase-input:focus {
	outline: none !important;
}
	
.elInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  outline: none;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}
.elInputBase-input::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.elInputBase-input::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.elInputBase-input:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.elInputBase-input::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.elInputBase-input:focus {
  outline: 0;
}
.elInputBase-input:invalid {
  box-shadow: none;
}
.elInputBase-input::-webkit-search-decoration {
  -webkit-appearance: none;
}
.elInputBase-input.el-disabled {
  opacity: 1;
}
.elInputBase-input:-webkit-autofill {
  animation-name: mui-auto-fill;
  animation-duration: 5000s;
}
.elInputBase-input::placeholder {
  color: #43063C;
  opacity: 1;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input::-webkit-input-placeholder {
  opacity: 0 !important;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input::-moz-placeholder {
  opacity: 0 !important;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input:-ms-input-placeholder {
  opacity: 0 !important;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input::-ms-input-placeholder {
  opacity: 0 !important;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input:focus::-webkit-input-placeholder {
  opacity: 0.42;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input:focus::-moz-placeholder {
  opacity: 0.42;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input:focus:-ms-input-placeholder {
  opacity: 0.42;
}
label[data-shrink=false] + .elInputBase-formControl .elInputBase-input:focus::-ms-input-placeholder {
  opacity: 0.42;
}
.elInputBase-inputMarginDense {
  padding-top: 3px;
}
.elInputBase-inputMultiline {
  height: auto;
  resize: none;
  padding: 0;
}
.elInputBase-inputTypeSearch {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}


.elButton-root {
  color: #444444;
  width: fit-content;
  height: fit-content;
  padding: 6px 16px;
  font-size: 0.8125rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  border-radius: 5px;
  line-height: 1.75;
}
.elButton-root:hover {
  text-decoration: none;
  background-color: rgba(68, 68, 68, 0.04);
}
.elButton-root.el-disabled {
  color: #A6A6A6;
  cursor: not-allowed;
  pointer-events: auto;
}
@media (hover: none) {
  .elButton-root:hover {
    background-color: transparent;
  }
}
.elButton-root:hover.el-disabled {
  background-color: transparent;
}
.elButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  margin-top: 0;
  margin-bottom: 0;
}
.elButton-text {
  padding: 6px 8px;
}
.elButton-textPrimary {
  color: var(--color-primary);
}
.elButton-textPrimary:hover {
  background-color: rgba(171, 38, 86, 0.04);
}
@media (hover: none) {
  .elButton-textPrimary:hover {
    background-color: transparent;
  }
}
.elButton-textSecondary {
  color: #43063C;
}
.elButton-textSecondary:hover {
  background-color: rgba(67, 6, 60, 0.04);
}
@media (hover: none) {
  .elButton-textSecondary:hover {
    background-color: transparent;
  }
}
.elButton-outlined {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 15px;
  text-transform: capitalize;
}
.elButton-outlined.el-disabled {
  border: 1px solid #EDEFF0;
}
.elButton-outlinedPrimary {
  color: var(--color-primary);
  border: 1px solid rgba(171, 38, 86, 0.5);
}
.elButton-outlinedPrimary:hover {
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}
@media (hover: none) {
  .elButton-outlinedPrimary:hover {
    background-color: transparent;
  }
}
.elButton-outlinedSecondary {
  color: #43063C;
  border: 1px solid rgba(67, 6, 60, 0.5);
}
.elButton-outlinedSecondary:hover {
  border: 1px solid #43063C;
  background-color: rgba(67, 6, 60, 0.04);
}
.elButton-outlinedSecondary.el-disabled {
  border: 1px solid #A6A6A6;
}
@media (hover: none) {
  .elButton-outlinedSecondary:hover {
    background-color: transparent;
  }
}
.elButton-contained {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  text-transform: capitalize;
  background-color: #FFFFFF;
}
.elButton-contained:hover {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  background-color: #d5d5d5;
}
.elButton-contained.el-focusVisible {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}
.elButton-contained:active {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}
.elButton-contained.el-disabled {
  color: #A6A6A6;
  box-shadow: none;
  background-color: #EDEFF0;
}
@media (hover: none) {
  .elButton-contained:hover {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
  }
}
.elButton-contained:hover.el-disabled {
  background-color: #EDEFF0;
}
.elButton-containedPrimary {
  color: #f2f2f2;
  background-color: var(--color-primary);
}
.elButton-containedPrimary:hover {
  background-color: var(--color-primary);
}
@media (hover: none) {
  .elButton-containedPrimary:hover {
    background-color: var(--color-primary);
  }
}
.elButton-containedSecondary {
  color: #FFFFFF;
  background-color: #43063C;
}
.elButton-containedSecondary:hover {
  background-color: rgb(46, 4, 42);
}
@media (hover: none) {
  .elButton-containedSecondary:hover {
    background-color: #43063C;
  }
}
.elButton-disableElevation {
  box-shadow: none;
}
.elButton-disableElevation:hover {
  box-shadow: none;
}
.elButton-disableElevation.el-focusVisible {
  box-shadow: none;
}
.elButton-disableElevation:active {
  box-shadow: none;
}
.elButton-disableElevation.el-disabled {
  box-shadow: none;
}
.elButton-colorInherit {
  color: inherit;
  border-color: currentColor;
}
.elButton-textSizeSmall {
  padding: 4px 5px;
  font-size: 0.7544642857142857rem;
}
.elButton-textSizeLarge {
  padding: 8px 11px;
  font-size: 0.8705357142857143rem;
}
.elButton-outlinedSizeSmall {
  padding: 3px 9px;
  font-size: 0.7544642857142857rem;
}
.elButton-outlinedSizeLarge {
  padding: 7px 21px;
  font-size: 0.8705357142857143rem;
}
.elButton-containedSizeSmall {
  padding: 4px 10px;
  font-size: 0.7544642857142857rem;
}
.elButton-containedSizeLarge {
  padding: 8px 22px;
  font-size: 0.8705357142857143rem;
}
.elButton-fullWidth {
  width: 100%;
}
.elButton-startIcon {
  display: inherit;
  margin-left: -4px;
  margin-right: 8px;
}
.elButton-startIcon.elButton-iconSizeSmall {
  margin-left: -2px;
}
.elButton-endIcon {
  display: inherit;
  margin-left: 8px;
  margin-right: -4px;
}
.elButton-endIcon.elButton-iconSizeSmall {
  margin-right: -2px;
}
.elButton-iconSizeSmall > *:first-child {
  font-size: 18px;
}
.elButton-iconSizeMedium > *:first-child {
  font-size: 20px;
}
.elButton-iconSizeLarge > *:first-child {
  font-size: 22px;
}

.elTypography-root {
  margin: 0;
  line-height: 1.5;
}
.elTypography-body2 {
  color: #4D4D4D;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.43;
}
.elTypography-body1 {
  color: #4D4D4D;
  font-size: 0.9285714285714286rem;
  font-weight: 400;
  line-height: 1.5;
}
.elTypography-caption {
  color: #43063C;
  font-size: 0.6964285714285714rem;
  font-weight: 400;
  line-height: 1.66;
}
.elTypography-button {
  color: #444444;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
}
.elTypography-h1 {
  color: #444444;
  font-size: 3.2857142857142856rem;
  font-weight: 700;
  line-height: 1.167;
}
@media (min-width:640px) {
  .elTypography-h1 {
    font-size: 4.7129rem;
  }
}
@media (min-width:768px) {
  .elTypography-h1 {
    font-size: 4.9272rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h1 {
    font-size: 5.5698rem;
  }
}
.elTypography-h2 {
  color: #444444;
  font-size: 2.241071428571429rem;
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width:640px) {
  .elTypography-h2 {
    font-size: 2.9167rem;
  }
}
@media (min-width:768px) {
  .elTypography-h2 {
    font-size: 3.125rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h2 {
    font-size: 3.5417rem;
  }
}
.elTypography-h3 {
  color: #444444;
  font-size: 1.8928571428571428rem;
  font-weight: 700;
  line-height: 1.167;
}
@media (min-width:640px) {
  .elTypography-h3 {
    font-size: 2.3565rem;
  }
}
@media (min-width:768px) {
  .elTypography-h3 {
    font-size: 2.5707rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h3 {
    font-size: 2.7849rem;
  }
}
.elTypography-h4 {
  color: #444444;
  font-size: 1.4866071428571428rem;
  font-weight: 700;
  line-height: 1.235;
}
@media (min-width:640px) {
  .elTypography-h4 {
    font-size: 1.8219rem;
  }
}
@media (min-width:768px) {
  .elTypography-h4 {
    font-size: 1.8219rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h4 {
    font-size: 2.0243rem;
  }
}
.elTypography-h5 {
  color: #444444;
  font-size: 1.1964285714285714rem;
  font-weight: 700;
  line-height: 1.334;
}
@media (min-width:640px) {
  .elTypography-h5 {
    font-size: 1.3118rem;
  }
}
@media (min-width:768px) {
  .elTypography-h5 {
    font-size: 1.3118rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h5 {
    font-size: 1.3118rem;
  }
}
.elTypography-h6 {
  color: #444444;
  font-size: 1.0803571428571428rem;
  font-weight: 500;
  line-height: 1.6;
}
@media (min-width:640px) {
  .elTypography-h6 {
    font-size: 1.0938rem;
  }
}
@media (min-width:768px) {
  .elTypography-h6 {
    font-size: 1.0938rem;
  }
}
@media (min-width:1024px) {
  .elTypography-h6 {
    font-size: 1.0938rem;
  }
}
.elTypography-subtitle1 {
  color: #444444;
  font-size: 0.9285714285714286rem;
  font-weight: 400;
  line-height: 1.75;
}
.elTypography-subtitle2 {
  color: #43063C;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.57;
}
.elTypography-overline {
  color: #43063C;
  font-size: 0.6964285714285714rem;
  font-weight: 400;
  line-height: 2.66;
  text-transform: uppercase;
}
.elTypography-srOnly {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
}
.elTypography-alignLeft {
  text-align: left;
}
.elTypography-alignCenter {
  text-align: center;
}
.elTypography-alignRight {
  text-align: right;
}
.elTypography-alignJustify {
  text-align: justify;
}
.elTypography-noWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.elTypography-gutterBottom {
  margin-bottom: 10px;
}
.elTypography-paragraph {
  margin-bottom: 16px;
}
.elTypography-colorInherit {
  color: inherit;
}
.elTypography-colorPrimary {
  color: var(--color-primary);
}
.elTypography-colorSecondary {
  color: #43063C;
}
.elTypography-colorTextPrimary {
  color: #444444;
}
.elTypography-colorTextSecondary {
  color: #43063C;
}
.elTypography-colorError {
  color: #ab2656;
}
.elTypography-displayInline {
  display: inline;
}
.elTypography-displayBlock {
  display: block;
}


.elSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.3928571428571428rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
}
.elSvgIcon-colorPrimary {
  color: var(--color-primary);
}
.elSvgIcon-colorSecondary {
  color: #43063C;
}
.elSvgIcon-colorAction {
  color: rgba(0, 0, 0, 0.54);
}
.elSvgIcon-colorError {
  color: #ab2656;
}
.elSvgIcon-colorDisabled {
  color: #A6A6A6;
}
.elSvgIcon-fontSizeInherit {
  font-size: inherit;
}
.elSvgIcon-fontSizeSmall {
  font-size: 1.1607142857142858rem;
}
.elSvgIcon-fontSizeLarge {
  font-size: 2.03125rem;
}

.elTouchRipple-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}
.elTouchRipple-ripple {
  opacity: 0;
  position: absolute;
}
.elTouchRipple-rippleVisible {
  opacity: 0.3;
  animation: elTouchRipple-keyframes-enter 550ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(1);
}
.elTouchRipple-ripplePulsate {
  animation-duration: 200ms;
}
.elTouchRipple-child {
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
  border-radius: 50%;
  background-color: currentColor;
}
.elTouchRipple-childLeaving {
  opacity: 0;
  animation: elTouchRipple-keyframes-exit 550ms cubic-bezier(0.4, 0, 0.2, 1);
}
.elTouchRipple-childPulsate {
  top: 0;
  left: 0;
  position: absolute;
  animation: elTouchRipple-keyframes-pulsate 2500ms cubic-bezier(0.4, 0, 0.2, 1) 200ms infinite;
}
@-moz-keyframes elTouchRipple-keyframes-enter {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
}
@-moz-keyframes elTouchRipple-keyframes-exit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes elTouchRipple-keyframes-pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  100% {
    transform: scale(1);
  }
}


.elInputAdornment-root {
  height: 0.01em;
  display: flex;
  max-height: 2em;
  align-items: center;
  white-space: nowrap;
}
.elInputAdornment-filled.elInputAdornment-positionStart:not(.elInputAdornment-hiddenLabel) {
  margin-top: 16px;
}
.elInputAdornment-positionStart {
  margin-right: 8px;
}
.elInputAdornment-positionEnd {
  margin-left: 8px;
}
.elInputAdornment-disablePointerEvents {
  pointer-events: none;
}


.elSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.3928571428571428rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
}
.elSvgIcon-colorPrimary {
  color: var(--color-primary);
}
.elSvgIcon-colorSecondary {
  color: #43063C;
}
.elSvgIcon-colorAction {
  color: rgba(0, 0, 0, 0.54);
}
.elSvgIcon-colorError {
  color: #ab2656;
}
.elSvgIcon-colorDisabled {
  color: #A6A6A6;
}
.elSvgIcon-fontSizeInherit {
  font-size: inherit;
}
.elSvgIcon-fontSizeSmall {
  font-size: 1.1607142857142858rem;
}
.elSvgIcon-fontSizeLarge {
  font-size: 2.03125rem;
}


.elIconButton-root {
  flex: 0 0 auto;
  color: #546e7a;
  padding: 0;
  overflow: visible;
  font-size: 1.3928571428571428rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
.elIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.elIconButton-root.el-disabled {
  color: #A6A6A6;
  background-color: transparent;
}
@media (hover: none) {
  .elIconButton-root:hover {
    background-color: transparent;
  }
}
.elIconButton-edgeStart {
  margin-left: -12px;
}
.elIconButton-sizeSmall.elIconButton-edgeStart {
  margin-left: -3px;
}
.elIconButton-edgeEnd {
  margin-right: -12px;
}
.elIconButton-sizeSmall.elIconButton-edgeEnd {
  margin-right: -3px;
}
.elIconButton-colorInherit {
  color: inherit;
}
.elIconButton-colorPrimary {
  color: var(--color-primary);
}
.elIconButton-colorPrimary:hover {
  background-color: var(--color-primary);
}
@media (hover: none) {
  .elIconButton-colorPrimary:hover {
    background-color: transparent;
  }
}
.elIconButton-colorSecondary {
  color: #43063C;
}
.elIconButton-colorSecondary:hover {
  background-color: rgba(67, 6, 60, 0.04);
}
@media (hover: none) {
  .elIconButton-colorSecondary:hover {
    background-color: transparent;
  }
}
.elIconButton-sizeSmall {
  padding: 3px;
  font-size: 1.0446428571428572rem;
}
.elIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.jss13 {
  color: #f2f2f2;
}
.jss13.danger-button {
  color: #FFFFFF;
  background-color: var(--color-primary);
}
.jss14 {
  padding: 8px 16px;
  font-size: 0.9285714285714286rem;
  min-width: 112px;
  font-weight: 600;
  border-radius: 3px;
}
@media (min-width:768px) {
  .jss14 {
    padding: 8px 32px;
    min-width: 160px;
  }
}
.jss15 {
  color: var(--color-primary);
  border-color: #e4e4e4;
  background-color: #FFFFFF;
}
.jss15.el-disabled {
  color: #A6A6A6;
  background-color: #EDEFF0;
}
.jss15:hover {
  border-color: var(--color-primary);
  background-color: #f2f5fc;
}
.jss15.el-disabled:hover {
  color: #A6A6A6;
  border-color: #EDEFF0;
  background-color: #EDEFF0;
}



















._2H1xmkEO8YyPfcJK2JBPRf {
  margin-bottom: 11px;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  position: relative;
}
._3d6nC894F8WmQuCr07KBmV {
  align-items: center;
  display: flex;
  justify-content: column-reverse;
  position: relative;
  text-align: center;
}
.pM13OFAYII2NPEKQyOhw0 {
  margin-left: auto;
}
._2j28j12Hog5LpqnjU_RN_6 {
  font-family: var(--font-mont);
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  margin-left: auto;
  min-width: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: inline-end;
  margin-top: -5px;
  display: none;
}

._3cy9d0UulZVYEbBYfzpqOI {
  padding-bottom: 16px;
  padding-top: 16px;
  position: relative;
  vertical-align: top;
}
.Ic_d9HedAzQOW2XGCAfTt {
  margin: 0;
}
.PEPfcZBRW0x-qBmIZymWP {
  display: flex;
  font-family: var(--font-mont);
  font-size: 12px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 1.5;
  margin-top: 11px;
}
._3MgjRlaF4xz3pfsIgczfD2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  position: relative;
  transition-duration: .25s;
  transition-property: margin;
  transition-timing-function: ease;
  width: 100%;
}

.QHHd4zMwtY4PbS57ENGbH, ._355vsd42sm3bOJ-pF_EohX {
  align-items: center;
  display: flex;
  margin-top: 12px;
  width: 100%;
  height: 25px;
}
._355vsd42sm3bOJ-pF_EohX {
  color: var(--color-black);
  font-size: 14px;
}

.DbddfTcdzWAtgD3V_wtui {
  flex: 1 1;
}

._39F2vqpsBE7Hjqa_LlkXfW, .kdBYgFY2CJ3leNObcIYh9 {
  font-family: var(--font-mont);
  font-size: 14px;
  line-height: 22px;
}
._39F2vqpsBE7Hjqa_LlkXfW {
  color: var(--color-black);
  font-weight: 500;
}

.kdBYgFY2CJ3leNObcIYh9 {
  color: #525150;
  font-weight: 400;
  margin-left: 8px;
}
._39F2vqpsBE7Hjqa_LlkXfW, .kdBYgFY2CJ3leNObcIYh9 {
  font-family: var(--font-mont);
  font-size: 14px;
  line-height: 22px;
}
._2zFESOr6H1nuRM7FMEh4f3 {
  font-family: var(--font-mont);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

._1j90V-dQzY-_uE7jVkJKOq {
  align-items: center;
  background-color: rgba(33,30,30,.08);
  border-radius: 6px;
  color: #4c4c4c;
  color: var(--color-black);
  cursor: pointer;
  display: flex;
  font-family: var(--font-mont);
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.33;
  margin-right: 8px;
  padding: 7px 17.5px;
  width: 100%;
}
._1j90V-dQzY-_uE7jVkJKOq[data-active="true"] {
  background-color: var(--color-black);
  color: #fff;
}

._2f3eYR_d4YoVfew7WnuhRo {
  height: 0;
  left: 0;
  margin-top: 12px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 110px;
  transition-duration: .25s;
  transition-property: height,opacity;
  transition-timing-function: ease;
  width: 100%;
}

.a6kXrE6Oy-4wrSbVyCQRr, .sFOqemvNTee_5M0DSKEOV {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.a6kXrE6Oy-4wrSbVyCQRr {
  display: flex;
  font-family: var(--font-mont);
  font-weight: 400;
  justify-content: space-between;
  margin-top: 60px;
  padding-top: 26px;
}
.sFOqemvNTee_5M0DSKEOV {
  font-family: var(--font-mont);
  text-align: right;
}
.a6kXrE6Oy-4wrSbVyCQRr, .sFOqemvNTee_5M0DSKEOV {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.bkzPZTxP-TFLWInTcLG0E {
  background: #ffffff; /*#faf9f8;*/
  color: #525150;
  display: flex;
  font-family: var(--font-mont);
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: normal;
  /*min-height: 100vh;*/
  position: relative;
  width: 100%;
}

.ThwrxgtcmnwqtSWRYuHD0 {
  background-color: #ffffff; /*#faf9f8;*/
  padding: 0;
  width: 100%;
}
._125ifHKgzgBJTAaupIqJjX {
  margin-bottom: 24px;
  margin-top: 26px;
}
._1eXXGcEHjyQF0xRM14r9HC {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}
._3qb-vJ1rjTtHeMxHTM7DQM {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}
.sivN0QB-MWF-_Ey25UGyT {
  flex-basis: 100%;
  margin-top: 16px;
}
._1zkNrlyq1nIHf6FPeLNrFv:first-child {
  padding-top: 0;
}
._1zkNrlyq1nIHf6FPeLNrFv {
  padding: 16px 0;
  width: 100%;
}

._2cSvW8v2jbyUfNR57xA3JZ {
  align-items: center;
  color: #6a6e78;
  display: flex;
  font-family: var(--font-mont);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 16px;
  position: absolute;
  right: 0;
  text-transform: capitalize;
  top: 16px;
}

._3miEcpP8Qv2aH97wlosF4O {
  background-color: #ffffff; /*#faf9f8;*/
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
._3qmRovatNE8F-poVd71z1x {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  position: relative;
}
._3ouqELkl11SiS8A3aYXY9j {
  color: #525150;
  font-size: 18px;
  line-height: 22px;
}
._1UJmr8r-9CVonU_bipit_V, ._3ouqELkl11SiS8A3aYXY9j {
  font-family: var(--font-mont);
  font-weight: 900;
  text-transform: uppercase;
}
._1UJmr8r-9CVonU_bipit_V {
  color: var(--color-black);
  font-size: 20px;
  line-height: 28px;
}

._14rnwwwsXq4gI8JwNsN3rt {
  border-top: 4px solid #f5f3f0;
}
._3vnr7L1M-rxrDc-wQY15Rd {
  border-bottom-width: 4px;
  border-bottom: 4px solid #f5f3f0;
}
.GV-4U04e-OPDRfnLwJZrA {
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
}
._1oS3fILK4ycPhObmii7Xy5 {
  color: var(--color-black);
  font-family: var(--font-mont);
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 16px;
  padding-left: 0;
  padding-right: 0;
  text-transform: uppercase;
  white-space: nowrap;
}

.kWIBU2YWlCYMwPcuZ1LGo {
	padding: 24px !important;
    line-height: 1px !important;
}

@media (max-width: 1100px){
	
	._125ifHKgzgBJTAaupIqJjX {
	  padding: 32px;
	}
}

@media (max-width: 1100px){
	.bkzPZTxP-TFLWInTcLG0E {
	  align-items: center;
	  flex-direction: column;
	}
	._125ifHKgzgBJTAaupIqJjX {
	  padding: 32px;
	}
	._2H1xmkEO8YyPfcJK2JBPRf {
	  order: 10;
	}
}
@media (min-width: 768px) and (max-width: 1101px){
	.bkzPZTxP-TFLWInTcLG0E {
	  padding-bottom: 60px;
	}
}

@media (min-width: 768px){
	._3ouqELkl11SiS8A3aYXY9j {
	  font-size: 20px;
	}
	
}
@media (min-width: 1100px){
	._1oS3fILK4ycPhObmii7Xy5 {
	  flex-basis: 110px;
	  margin-right: 30px;
	}
}
@media (min-width: 768px){
	._1oS3fILK4ycPhObmii7Xy5 {
	  font-family: var(--font-mont);
	  line-height: 22px;
	  font-size: 14px;
	  font-weight: 900;
	}
}

@media (min-width: 1337px){
	.ThwrxgtcmnwqtSWRYuHD0 {
	  margin-right: 90px;
	}
}
@media (min-width: 1100px){
	.ThwrxgtcmnwqtSWRYuHD0 {
	  max-width: 720px;
	  padding: 0 64px 80px;
	}
	._14rnwwwsXq4gI8JwNsN3rt {
	  border-top: none;
	}
}

@media (min-width: 1101px){
	.GV-4U04e-OPDRfnLwJZrA {
	  flex-direction: row;
	  padding-left: 0 !important;
	  padding-right: 0 !important;
	}
}
@media (min-width: 835px){
	.GV-4U04e-OPDRfnLwJZrA {
	  padding-bottom: 20px;
	  padding-top: 20px;
	}
}
@media (min-width: 391px){
	.GV-4U04e-OPDRfnLwJZrA {
	  padding-left: 32px;
	  padding-right: 32px;
	}
}

@media (min-width: 1100px){
	._1oS3fILK4ycPhObmii7Xy5 {
	  flex-basis: 110px;
	  margin-right: 30px;
	}
}
@media (min-width: 768px){
	._1oS3fILK4ycPhObmii7Xy5 {
	  font-family: var(--font-mont);
	  font-size: 14px;
	  font-weight: 900;
	  line-height: 22px;
	}
}
 
@media (min-width: 768px){
	._3qb-vJ1rjTtHeMxHTM7DQM {
	  margin-top: 0;
	}
}

@media (min-width: 1100px){
	._3miEcpP8Qv2aH97wlosF4O {
	  background-color: rgba(249,251,250,1); /*hsla(42%,20%,98%);*/
	  max-width: 544px;
	  min-width: 512px;
	}
}

@media (min-width: 1100px){
	._3qmRovatNE8F-poVd71z1x {
	  max-width: 728px;
	  padding-left: 0;
	  padding-right: 0;
	  padding-top: 24px;
	}
}
@media (max-width: 480px){
	._1j90V-dQzY-_uE7jVkJKOq {
	  padding: 8px 14.5px;
	  font-size: 12px;
	  margin-right: 6px;
	}
}

@media (max-width: 768px){
	._3miEcpP8Qv2aH97wlosF4O {
	  padding: 32px;
	}
	
	._3cy9d0UulZVYEbBYfzpqOI {
		padding-bottom: 0;
	}
	.a6kXrE6Oy-4wrSbVyCQRr, .sFOqemvNTee_5M0DSKEOV {
	  line-height: 0px;
	}
}

















































.ant-tabs-nav {
	width: 100%;
  position: sticky !important;
  top: 0;
	background-color: rgb(249,251,250);
  z-index: 2;
	opacity: 1;
}

.ant-tabs-tab-btn{
	font-size: 16px;
	font-family: 'Montserrat';
}


.restaurant {
  display: flex;
  flex-direction: column; 
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
  padding: 13px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 25px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  border: 1px solid var(--borderOff);
  /* align-items: center; */
}

.restaurant-title {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: var(--color-black);
  line-height: 28px;
}

.restaurant-mainimage:before {
  border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	content: "";
	height: 100%;
	width: 100%;
	display: block;
	background: rgba(0,0,0,.4);
	position: absolute;
	z-index: 1;
}

.restaurant-mainimage {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	content: "";
	height: 100%;
	width: 100%;
	display: flex;
	background: rgba(0,0,0,.4);
	position: relative;
}

.restaurant-mainimage svg {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.restaurant-image {
  width: 100%;
  max-width: 60px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.restaurant-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 2px;
}

.restaurant-address {
  font-size: 14px;
  margin-bottom: 2px;
}

.restaurant-opening-time {
  font-size: 14px;
  margin-bottom: 2px;
}

.restaurant-cuisine {
  font-size: 14px;
  margin-bottom: 2px;
}

.restaurant-promo {
  font-size: 14px;
  color: green;
  margin-bottom: 2px;
}

.restaurant-list-wrapper {
  position: relative;
  background: rgb(249,251,250);
  border: 1px solid var(--borderOff);
  border-radius: 5px;
  box-shadow: 15px 30px 40px var(--boxShadow);
  padding: 40px 40px 40px;
  height: 100%;  
  width: 530px;
}

.restaurant-map-wrapper button{
	min-width: 0px !important;
}

.restaurant-map-wrapper {
	display: none;
}

@media only screen and (max-width: 768px) {
	.restaurant-wrapper {
	  display: flex;
	  justify-content: center;
	  padding: 20px 0;
	}
}

@media only screen and (min-width: 768px) {
  .restaurant {
    flex-direction: row;
		border-bottom: 1px solid #0ec8b1;
  }
  
  .restaurant:hover {
		background-color: rgba(222,222,222,0.2);
	}

  .restaurant-image {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }

  .restaurant-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
		margin-right: 20px;	
  }
  
	.restaurant-wrap {
	  /* 
		margin-bottom: 20px;
	  border-top-left-radius: 10px;
	  border-top-right-radius: 10px;
		*/
	  cursor: pointer;
		width: 100%;
		margin-left: 2.5%;
		font-family: var(--font-mont);
	}
	
	.restaurant-wrap:hover {
		background-color: #dedede !important;
		z-index: 10;
	}
		
	.restaurant-wrapper {
	  display: flex;
	  justify-content: center;
	}
}

.c-box-width{	
	max-width: 533px;
}
.c-box{	
	max-width: 533px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	border-top: 4px solid #0ec8b1;
}

@media screen and (max-width: 1150px) {
	
	.restaurant-wrapper {
	  display: flex;
	  justify-content: center;
	  padding: 50px 0;
	}
}

@media screen and (min-width: 1150px) {
	
	.restaurant-wrapper {
	  display: flex;
	  justify-content: space-between;
	}
	
	.restaurant-list-wrapper {
	  width: 30%;
	  overflow-y: auto;
	  left: 70%;
	  position: relative;
	  background: rgb(249,251,250);
	  border: 1px solid var(--borderOff);
      border-radius: 5px;
      box-shadow: 15px 30px 40px var(--boxShadow);
      padding: 40px 30px 40px;
	  height: calc(100vh - 80px - 2rem);
	}

	.restaurant-map-wrapper {
	  width: 70%;
	  position: fixed;
	  height: calc(100vh - 80px);
	  display: block;
	}
	
	.ant-tabs-nav {
		width: 85%;
		position: fixed !important;
		top: auto;
	}
}

































:root {
	--dvh: 7.4px;
}

body {
  margin: 0;
  font-family: var(--font-mont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
	border-bottom: 2px dashed #1890ff;
}
#components-table-demo-drag-sorting tr.drop-over-upward td {
	border-top: 2px dashed #1890ff;
}
.ant-table-column-sorters{
	display: inline-flex !important;
	align-items: center !important;
}
.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 500px;
  padding: 48px;
  overflow: auto;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}
.ant-modal-root .ant-modal-mask, .ant-drawer .ant-drawer-mask{
  animation-name: k3;
  animation-duration: 0.25s;
  animation-timing-function: ease;
  background-color: rgba(0, 0, 0, 0.6) !important;
  backdrop-filter: blur(4px);
}
.logo{
	width: calc(100% - 18px);	
	height: 70px;	
	margin: 10px auto;
	margin-top: 30px;
	display: flex;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
}
.ant-menu-title-content{
	font-size: 18px;
}
.ant-form-item-no-bottom-margin {
  margin-bottom: 0;
}

.ant-divider-horizontal:after{
	/*width: 85% !important;*/
}

/*
.ant-menu-item-active, .ant-menu-item-selected{
	border-radius: 50px;
	background-color: #2b3c19 !important;
}
.ant-layout-sider, .ant-menu, .ant-layout-header{
	background-color: #3e5725 !important;
}
.ant-menu-sub{
	background-color: #080a05 !important;
}
*/

.site-layout-background{
	padding: 24px;
}
._6wlw6o {
  margin-right: 473px;
  margin-bottom: 20px;
  margin-left: auto;
}

._ruof1q {
  padding-left: 10%;
  padding-right: 12.2%;
  margin-bottom: 40px;
}
._86m4 {
    flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    align-items: flex-start;
    -moz-box-align: flex-start;
    overflow: hidden;    
    align-items: center;
    -moz-box-align: center;
    display: flex;
    flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    position: sticky;
    top: 0px;
    background-color: var(--color-white);
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

._oj9oqm {
  border-bottom: 3px solid #257a80 !important;  
}

._obgv77 {
	font-size: 18px;
    line-height: 1.29;
    padding: 11px 16px 12px;
    color: var(--color-black);
    font-family: var(--font-mont);
    font-weight: 600;
    line-height: 1.5;
    border-bottom: 1px solid rgb(235, 233, 230);
    white-space: nowrap;
    background-color: var(--color-white);
    text-decoration: none;
	cursor: pointer;
}

div._1jx0i45 {
  width: 100%;
  display: flex;
  scrollbar-width: none;
  overflow: auto;
  white-space: nowrap;
}

div._1jx0i45 span {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div._1jx0i45 span:hover {
  background-color: #777;
}

div._1jx0i45::-webkit-scrollbar {
  width: 0px;
}

/* Track */
div._1jx0i45::-webkit-scrollbar-track {
  background: none; 
}
 
/* Handle */
div._1jx0i45::-webkit-scrollbar-thumb {
  background: none; 
}

/* Handle on hover */
div._1jx0i45::-webkit-scrollbar-thumb:hover {
  background: none; 
}

.ant-modal-close{
	min-width: 16px !important;
}
.ant-modal-header{
	padding-bottom: 15px;
	border-bottom: 1px solid black;
}

.ant-modal-body p{
	font-weight: 500;
	font-size: 16px;
	font-family: var(--font-mont);
	padding: 10px !important;
}

.ant-modal-body>p{
	background-color: rgba(204, 220, 205, 0.2); 
}

.ant-drawer-footer{
	display: flex;
	justify-content: space-between;
	
}

.c__button {
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 20px;
  padding: 0.5rem 1.5rem 2.2rem;
  border-radius: 4px;
  border: 1px solid var(--color-black);
  outline: none;
  cursor: pointer;
}
.c__button:hover{
	border: 1px solid var(--color-black);
	color: var(--color-white) !important;
	background-color: var(--color-black);
}

.cus_input{
	flex: 1 1 0%; 
	font-family: var(--font-mont); 
	font-size: 18px; 
	border: 1px solid rgb(14, 203, 129); 
	border-radius: 5px; 
	position: relative; 
	z-index: 0; 
	padding-left: 10px !important; 
	padding-right: 10px !important;
	height: auto; 
	line-height: 2.5;
	text-align: left; 
	width: 100%; 
	outline: none;
}
.cus_select{
	flex: 1 1 0%; 
	font-family: var(--font-mont); 
	font-size: 18px;
	position: relative; 
	z-index: 0; 
	padding-left: 10px !important; 
	padding-right: 10px !important; 
	padding-top: 11px !important; 
	padding-bottom: 11px !important; 
	height: auto; 
	line-height: 2.5;
	text-align: left; 
	width: 100%; 
	outline: none;
	border: 1px solid var(--color-primary);
	border-radius: 5px;
}
.cus_textarea{
	flex: 1 1 0%; 
	font-family: var(--font-mont); 
	font-size: 18px;
	position: relative; 
	z-index: 0; 
	padding-left: 10px !important; 
	padding-right: 10px !important; 
	padding-top: 10px !important; 
	padding-bottom: 10px !important; 
	height: 100%; 
	text-align: left;
	width: 100%; 
	outline: none;
	border: 1px solid var(--color-primary);
	border-radius: 5px;
	resize: none;
}

.cus_input_half{
	width: 98% !important;
	outline: none;
	padding-top: 0 !important; 
	padding-bottom: 0 !important;
}
.cus_button{
	color: var(--color-primary);
	cursor: pointer;
	outline: none;
	background: var(--color-white);
	border: solid 1px var(--color-primary);
	border-radius: 0.2rem;
	width: 100%;
	height: 3rem;
	margin-top: 1rem;
	text-transform: uppercase;
	font-weight: bolder;
	font-family: var(--font-mont);
}
.cus_button:hover{
	border: 1px solid var(--color-primary);
	color: var(--color-white) !important;
	background-color: var(--color-primary);
}

.cus_add_button{
	color: var(--color-black);
	cursor: pointer;
	outline: none;
	border: solid 1px var(--color-black);
	border-radius: 0.2rem;
	height: 100%; 
	width: 100%; 
	min-width: 100px;
	text-transform: uppercase;
	font-weight: 500;
	font-family: var(--font-mont);
}

.cus_add_button_wrap{
	width: 160px;
	height: 2rem;
}

.cus_add_button_wrap:hover{
	background: rgb(255, 255, 255) none repeat scroll 0% 0%;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px;
}

.cus_title_wrap h2{
	padding-left: 0px;
	padding-right: 10px;
	font-weight: 500;
	font-size: 18px;
	text-transform: uppercase;
	color: var(--custom-dark);
}

.cus_back_button{
	color: var(--custom-dark);
	cursor: pointer;
	outline: none;
	border: solid 1px var(--custom-dark);
	border-radius: 0.2rem;
	width: 100%; 
	min-width: 80px;
	height: 100%;
	text-transform: uppercase;
	font-weight: 500;
	font-family: var(--font-mont);
}
.cus_back_button_wrap{
	margin: 0px 32px 20px;
	width: 80px;
	height: 2rem;
}
.cus_back_button_wrap:hover{
	background: rgb(255, 255, 255) none repeat scroll 0% 0%;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px;
}

.cus_error {
	border: 1px solid var(--error-dark);
	border-radius: 5px;
	position: relative;
	z-index: 0;
	padding: 10px;
	color: var(--error-dark);
	background: var(--error-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: block;
	font-size: 16px;
	font-weight: 300;
}

.cus_info{
	border: 1px solid var(--info-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--info-dark);
	background: var(--info-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: block;
	font-size: 16px;
	font-weight: 300;
}

.cus_success{
	border: 1px solid var(--success-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--success-dark);
	background: var(--success-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: block;
	font-size: 16px;
	font-weight: 300;
}


.incsizespansms {
    border: 1px solid var(--custom-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--custom-dark);
	background: var(--custom-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: flow-root;
	width: 100%;
	font-size: 16px;
	font-weight: 300;
}


.incsizespan {
    border: 1px solid var(--custom-dark);
    border-radius: 5px;
    position: relative;
    z-index: 0;
    padding: 10px;
    color: var(--custom-dark);
	background: var(--custom-med);
	line-height: 1rem;
	letter-spacing: 0.02rem;
	display: none;
	font-size: 16px;
	font-weight: 300;
}

.continue{
	padding: 5px 10px;
	color: var(--color-white);	
	border-radius: 4px;
	line-height: 16px;
	font-size: 16px;
	height: 40px;
	width: 260px;
}
.continue-fb{
	background-color: #3b5998;
	border: 1px solid rgba(67,41,163,.2);
}
.continue-apple{
	background-color: var(--color-black);
	border: 1px solid black;
}
.continue-google{
	border-color: 1px solid rgba(67,41,163,.2);
	background-color: #4285f4;
}
.continue-amazon{
	background: linear-gradient(#ffe8ab, #f5c645);
	border: 1px solid #b38b22;
	color: var(--color-black);
	z-index: 1;
}
.continue-wrap{
	margin-top: 0px;
	margin-bottom: 0px;
}



.slide-in-bottom {
  animation: slide-in-bottom 0.5s ease-in-out;
  animation-fill-mode: both;
}

@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}


._as2sev {
  margin-left: 10%;
  margin-right: 10%;
  flex-grow: 1;
  -moz-box-flex: 1;
}

._yq7j8e {
  display: flex;
}

._yq7j8e:first-of-type [data-name="title"] {

    margin-top: 0px;

}

._rnsmcq {
  display: block;
  position: relative;
  top: -44px;
  visibility: hidden;
  height: 0px;
  width: 0px;
}

._18z5nku {
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  width: 100%;
}


._1gg3ue {
    font-family: var(--font-mont);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    margin-left: 15px;
    color: var(--color-black);
    text-transform: uppercase;
}

._1yc6rdp {
    font-size: 18px;
    line-height: 1.25;
    color: var(--color-black);
    font-family: var(--font-mont);
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    -moz-box-align: center;
    background-color: rgb(249,251,250); /*var(--color-lightgreen);*/
    margin: 0px 24px 18px 0px;
    padding: 10px;
}

._1dcg472 {
    margin: 0px 10px;
}

._1743i26 {
	display: flex;
    flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    background: none;
} 
._19hi95f {
    background: none;
    display: flex;
    flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    background: var(--color-white);
    flex: 1 1 0%;
}

._194c2bm {
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(0, 0, 0, 0.1);
    margin-right: 24px;
    margin-bottom: 20px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    -moz-box-pack: justify;
    height: 150px !important;
    cursor: pointer;
    background: var(--color-white);
    align-items: flex-start;
    -moz-box-align: flex-start;
    overflow: hidden;
}

._194c2bm:hover{
	border-width: 1px;
	border-color: rgb(86, 140, 91, 0.9);
	/*background-color: rgb(86, 140, 91, 0.1);*/
}

._15q0jjh {
  box-sizing: border-box;
  height: 150px !important;
  padding: 16px;
}

._qsnllp {
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  justify-content: space-between;
  -moz-box-pack: justify;
  height: 100%;
}


._5lfk5c {
  display: none;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-left: medium none;
  border-bottom: medium none;
  width: calc(100% - 32px);
}

._1pbw5a2 {
    aspect-ratio: 1 / 1;
	display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
	height: 100%;
	min-width: 142px;
	width: 142px;
	border: medium none;
	margin: 0px;

}

._190hxbq {
  width: 100%;
  height: 100%;
}


._1n57n8z {
    font-family: var(--font-mont);
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: var(--color-black);
	opacity: 0.9;
    padding-bottom: 4px;
    display: -webkit-box;
    height: 19px;
    max-height: 19px;
    text-overflow: ellipsis;
	display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
   -moz-box-orient: vertical;
   overflow: hidden;
}

._vlgzaz {
    
    font-family: var(--font-mont);
    font-weight: normal;
    font-size: 14px;
	height: 44px;
    line-height: 17px;
	max-height: 36px;
    color: var(--color-gray);    
    overflow: hidden;
    margin-bottom: 4px;
	text-overflow: ellipsis;
	display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
   -moz-box-orient: vertical;
}

._lu8b5g {
  font-family: var(--font-mont);
  font-weight: 600;
  opacity: 0.9;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-black);
  margin-top: 16px;
  margin-bottom: 0;
}
.M0cnph9mBkjQ7sKnRJoCn {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  box-sizing: border-box;
  width: 473px;
  height: 100vh;
  padding: 30px 56px 30px 40px;
  background: rgb(249, 251 ,250);
  /*border-left: 1px solid var(--color-lightgreen);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px 0px;*/
}


._2mfDffMxberUHEHnzJbsqf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 70%;
  text-align: center;
}
.c5PRj4_tt33kyMy30ibSp {
  line-height: 24px;
  font-size: 18px;
  font-weight: 900;
  color: #181218;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebe9e6;
}
._3gouMfJy504Yjz5rHjoHpu {
  margin: 20px 0;
}
._1lSOQ6q2xcOkaxVT1smcn7 {
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0, 0.4);
}
._1lSOQ6q2xcOkaxVT1smcn7:hover {
  border: 1px solid rgb(86, 140, 91, 0.9);
  background-color: rgb(86, 140, 91, 0.1);  
}
._bucj1r {
  font-size: 16px;
  line-height: 1.25;
  padding: 16px 0px;
  color: rgb(133, 133, 133);
  font-family: var(--font-mont);
  font-weight: 400;
  align-items: center;
  -moz-box-align: center;
  display: flex;
  max-width: none;
  flex: 1 1 0%;
}
._17lw9oo {
  padding-right: 10px;
  margin: auto 20px;
  width: 35px;
}
._1ucqjmf {
  display: flex;
  flex: 1 1 0%;
  justify-content: space-between !important;
	align-items: center;
}
._15mkp10 {
  font-weight: 500;
  color: rgb(24, 18, 24);
}
._1upj7ie, ._1upj7ie > p {
  color: rgb(24, 18, 24);
  font-family: var(--font-mont);
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
}

._1upj7ie > div {
  color: rgb(24, 18, 24);
  font-family: var(--font-mont);
  font-weight: 600;
  font-size: 14px;
  display: flex;
}

._1upj7ie > p {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600 !important;
  margin-bottom: 0;
}


._17y0oyu {
  font-size: 14px;
  color: rgb(105, 114, 128);
  font-family: var(--font-mont);
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 0;
  margin-right: 20px;
}
._1r1eulg {
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  margin-top: 5px;
}

._1SEARqWERjoS6g0rgsTmMe {
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-gray) !important;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--color-black);
  padding: 4px 12px;
  text-decoration: none;
  transition: border-color .25s ease,background-color .25s ease;
  min-width: 100px; 
  margin-right: 20px;
}
._1JdD97nX-WGcPASVXFJXKw {
  background-color: var(--color-black);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
._164jj58 {
  padding: 32px 16px;
  display: flex;
  justify-content: space-between;
  -moz-box-pack: justify;
  align-items: center;
  -moz-box-align: center;
  width: 100%;
  background: none;
  border: medium none;
  cursor: pointer !important;
}
._1e55dq5 {
  font-size: 17px;
  line-height: 1.15;
  color: var(--color-black);
  font-family: var(--font-mont);
  font-weight: 400;
  margin-bottom: 0;  
}


._1wozt5f {
  margin-right: 473px;
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  padding: 24px !important;
}

._14a9t3k {
  background: var(--color-black);
}

._12atkkn {
  display: flex;
}

._1gbkmlt {
    background-position: center center;
    background-size: cover;
    display: flex;
    height: auto;
    width: 100%;
    object-fit: cover;
    min-height: 180px;
	flex-direction: column;
}

._11ochor {
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: multiply;
}
._bxoxda {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  justify-content: space-between;
  -moz-box-pack: justify;
}

._zdt81h {
  margin-left: 10% !important;
  display: flex;
  flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  align-items: flex-start;
  -moz-box-align: flex-start;
  margin: 50px 0px 40px;
}

._1dogarg {
  display: flex;
  align-items: flex-start;
  -moz-box-align: flex-start;
  flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  max-width: 363px;
}

._5if1ut {
  max-width: 363px;
  box-shadow: rgb(104, 114, 130) 0px 2px 10px -3px;
  border-radius: 10px;
  display: flex;
  background: var(--color-white);
  flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

._dfgcxn {
  padding: 28px;
}

._nngzzd {

    left: 28px;
    top: 0px;
    max-height: 70px;
    max-width: 85px;
    position: absolute;
    left: 16px;
    top: 0px;
    transform: translateY(-60%);
    border-radius: 5px;
}
._1qulqfk {
  font-size: 20px;
  line-height: 1.2 !important;
  color: var(--color-black);
  font-family: var(--font-mont);
  font-weight: 700;
}

._8mmg2s {
  line-height: 1.2 !important;
  color: var(--color-gray);
  font-family: var(--font-mont);
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
  margin-bottom: 8px;
}

._g4kti3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  -moz-box-align: center;
  line-height: 1.2 !important;
  margin-bottom: 8px;
}

._63g2va {
    color: var(--color-gray);
    font-family: var(--font-mont);
    font-weight: 600;
    font-size: 15px;
    line-height: 1.2 !important;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
}

._xg6t39 {
  font-size: 10px;
  width: 35px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  -moz-box-align: center;
  justify-content: center;
  -moz-box-pack: center;
  letter-spacing: 0.3px;
  color: rgb(46, 133, 64);
  border-radius: 3px;
  text-transform: uppercase;
  border: 1px solid rgb(46, 133, 64);
  font-weight: 700;
  font-family: var(--font-mont);
}

._1si8x2j {
  color: rgb(236, 79, 56);
  font-family: var(--font-mont);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin-right: 8px;
}

._7e87360585 {
  font-size: 15px;
  color: var(--color-gray);
  font-family: var(--font-mont);
  font-weight: 400;
  background-color: var(--color-white);
  padding: 16px;
}

._ae94fc8bda {

    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px 0px;
    height: auto;
    max-height: calc(94 * var(--dvh, 1vh));
    top: 25px;
    border-radius: 5px;
    width: 520px !important;
    animation-name: k7;
    animation-duration: 0.75s;
    animation-timing-function: ease;
    background-color: var(--color-lightgreen); /*#f0f3fa;*/
    position: absolute;
    inset: 0px;
    z-index: 300;
    overflow: auto;

}


@keyframes k7 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}



._80e672dd38 {
  padding: 19px 20px 15px;
  background-color: var(--color-black) !important;
  border-bottom: 1px solid var(--color-crimson);
  position: sticky;
  top: 0px;
  z-index: 100;
  height: 80px;
}

._e4016299f6 {
  display: flex;
  justify-content: space-between;
  -moz-box-pack: justify;
  align-items: baseline;
}

._c59f054837 {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--font-base);
  line-height: 1.13;
  color: var(--color-white);
}
._238884994a{
  float: right;
  display: inline-flex;
  right: 0;
  position: absolute;
}
._8e0332f3ad {
  cursor: pointer;
  height: 15px !important;
  width: 15px !important;
  margin: 0px !important;
  padding: 0px !important;
  border: medium none;
  background: none;
  max-width: 15px !important;
  min-width: 15px !important;
}

._7113bb624e {
  stroke: rgb(133, 133, 133);
  stroke-width: 5;
}

._2e1869a6f2 {
  display: flex;
  flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  position: relative;
  padding: 16px 20px;
  align-items: center;
  -moz-box-align: center;
  top: 0px;
}

._5d92860664 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-mont);
  line-height: 1;
  color: var(--color-black);
  width: auto;
}

._7651043074 {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-mont);
  line-height: 1;
  color: var(--color-black);
  width: 53%;
}

._ed0ebb9231 {
  color: rgb(117, 117, 117);
  font-weight: normal;
  font-family: var(--font-mont);
  font-size: 14px;
  padding-top: 1px;
  text-align: right;
  width: 47%;
}


._d44445365a {
  background-color: var(--color-white);
  margin: 0px 16px;
  border-radius: 10px;
  box-shadow: rgba(213, 213, 213, 0.5) 0px 4px 4px 0px;
  padding: 0px 24px;
}

._d44445365a > div {
  padding: 16px 0px;
}
._a1bc089b68 {
  display: flex;
  align-items: center;
  -moz-box-align: center;
  justify-content: space-between;
  -moz-box-pack: justify;
}

._d44445365a > div:not(:last-child) {
  border-bottom: 1px solid var(--color-crimson);
}
._d44445365a > div {
  padding: 16px 0px;
}
._b4e34f9f22 {
  margin-bottom: 0px;
}

._3daf1fd212 {
  display: flex;
  position: relative;
  align-items: center;
  -moz-box-align: center;
}

._416bffa7a8[data-disabled="true"] {
  opacity: 0.4;
}

._416bffa7a8 {
  cursor: pointer;
  display: block;
  opacity: 1;
  height: 16px;
  width: 16px;
  transition-duration: 0.125s;
  transition-timing-function: ease;
  transition-property: background-color, border-color, border-width;
}

._cd87b6a0b5 {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(196, 196, 196);
  border-radius: 2px;
  width: 14px;
  height: 14px;
}
._416bffa7a8[data-disabled="false"] ._cd87b6a0b5:hover {
  border-color: var(--color-black) !important;
  
}
._416bffa7a8[data-disabled="true"]:hover {
  cursor: default !important;  
}

.popcheck{
	position: relative; 
	/*top: -13px;*/
}

._238110507a[data-shift="true"] {

    padding-left: 10px;

}
._238110507a[data-clickable="true"] {

    cursor: pointer;

}
._238110507a[data-shift="true"] {

    padding-left: 10px;

}
._238110507a[data-clickable="true"] {

    cursor: pointer;

}

._238110507a[data-disabled="true"] {
  opacity: 0.4;
  cursor: default;
}

._b92fb3c088[aria-checked="true"] {
  /*visibility: visible;*/
  display: block;
}

._b92fb3c088[aria-checked="false"] {
  display: none;
  /*visibility: hidden;*/
  height: 0px !important;
  max-height: 0px !important;
  overflow-y: hidden;
}


._238110507a {

    font-family: var(--font-mont);
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
    color: #4c4c4c;
    margin-bottom: 0;
    position: relative;
    user-select: none;
    cursor: pointer;
	margin-top: -2px;

}

._e935b4713a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

._8f1fb05477:disabled {

    cursor: default;
    opacity: 40%;

}
._8f1fb05477 {

    background-color: var(--color-white);
    border: 2px solid var(--color-black);
    box-sizing: content-box;
    cursor: pointer;
    user-select: none;

}
._bcf9817d1f {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    margin-right: 11px;
	min-width: 0px !important;
}

._6b394e808e {
  position: relative;
  top: -1px;
}
._9a4188831d {
  font-family: var(--font-mont) !important;
  font-size: 35px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-black);
  text-align: center;
  margin-bottom: 16px;
	margin-top: 13px;
}
 
._5e34f0d7a7 {
  font-family: var(--font-mont) !important;
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  color: var(--color-white);
  background-color: var(--color-black);
  text-align: center;
  margin-top: 2px;
}
._53b08ed90d {
  font-size: 20px;
  line-height: 1.25;
  color: var(--color-black);
  font-family: var(--font-mont) !important;
  font-weight: 600;
}

._b92fb3c088 {
  padding-bottom: 16px;
}

._c8f3d41eb2 {
  padding-bottom: 5px;
}

._130bb3cfbc {
  padding: 20px;
  background-color: var(--color-white);
  box-sizing: border-box;
  position: sticky;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border-top: 2px solid var(--color-black);
  bottom: 0;
}

._fc720ca0eb{
    font-family: var(--font-mont);
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--color-black) !important;
    border-radius: 4px;
    color: var(--color-white);
    padding: 2px 24px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .25s ease,background-color .25s ease;

}
._758d82ec45 {

    /*width: 100%;*/

}
._d24c591e23 {

    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    cursor: pointer;
	font-size: 20px;
    font-weight: inherit;        
    line-height: inherit;
    font-family: inherit;

}
._2fe64c4d36 {
  padding: 0px 16px 16px;
}

._63c33e3a1b {
  font-family: var(--font-mont);
  line-height: 2.25;
  font-size: 12px;
  font-weight: 400;
  color: #4c4c4c;
}

._da39275192::placeholder {

    color: #adadad;

}

._49a7b28354 {

    height: 70px;

}

._da39275192 {

    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    appearance: none;
    resize: none;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    transition: border-color .125s ease;
    font-family: var(--font-mont);
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
	color: #4c4c4c;
}

._cbf9b207b0[data-alwayson="true"] {

    display: inline;

}
._cbf9b207b0 {

    font-family: var(--font-mont) !important;
    display: none;
    padding-left: .5em;
    user-select: none;
	color: var(--color-black) !important;
	font-weight: 600 !important;
	font-size: 14px;
}

._51fd6a7116 {
  display: flex;
  height: 16px;
  margin: 0px;
  align-items: center;
  -moz-box-align: center;
  color: var(--color-black);
}

._8b6e596e6a[data-checked="true"] {
  background-color: var(--color-white);
  border-color: var(--color-black);
}
._8b6e596e6a {
  border-color: rgb(196, 196, 196);
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;  
  overflow: hidden;
  transition-duration: 0.125s;
  transition-timing-function: ease;
  transition-property: border-color;  
  position: relative;
  min-height: 18px;
  min-width: 18px;
}

._18ac26ac61 {
  background-color: var(--color-black);
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
  height: 8px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 8px;
}

._8n9wjm {

    opacity: 0;
    bottom: -100px;
    max-height: 0px;
    padding: 0px;
    transition: all 200ms ease-in 0s, padding 200ms ease-in 250ms, max-height 200ms ease-in 250ms;

}
._1fedyu0 {

    display: flex;
    position: sticky;
    bottom: 101px;
    background: rgb(253, 247, 241);
    color: var(--color-black);
    border: 1px solid rgb(195, 174, 138);
    border-radius: 4px;
    padding: 16px;
    margin: 0px 16px;
    font-family: var(--font-mont);
    overflow: hidden;
    max-height: 500px;
    transition: all 400ms ease-out 200ms, padding 400ms ease-out 0s, max-height 400ms ease-out 0s;
}

._12e5920ed3 {
  min-height: 281px;
  padding: 20px 20px 0px;
  display: flex;
  justify-content: center;
  -moz-box-pack: center;
  background-color: var(--color-white);
}

._794b886035 {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  align-self: center;
}

._2ead4658a5{
  display: flex;
  align-items: center;
  -moz-box-align: center;
  justify-content: space-between;
  -moz-box-pack: justify;
}

._3acb1be439{
	background-color: var(--color-black);
	padding: 3px 24px;
	border-radius: 5px;
	min-width: 160px;
	align-items: flex-start;
	justify-content: center;
}

._2r-vdmLSoI1KSdsmnMT_l9 {
  padding-top: 20px;
  margin-top: auto;
}

._1qovpV8LpPfvmFSsbXj-DR.eSlRH0G6u0haaQUiGCluU {

    background-color: var(--color-lightgreen);

}
._2uN2zP5QFqNQqEbJS8CzOu {

    margin-bottom: 8px;

}
._1qovpV8LpPfvmFSsbXj-DR {

    display: flex;
    padding: 12px;
    border-radius: 4px;

}
._38hkkZ9YqW58C_n3Ke-lJV {
  display: flex;
  align-items: flex-end;
  width: 14px;
  height: 19px;
  margin-right: 8px;
}
._3VpT59o1JAnGF5Luv-ZduG {
  font-family: var(--font-mont);
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}


._1CwjZK_2Wbc4SetuxezVf9:hover:disabled, ._1CwjZK_2Wbc4SetuxezVf9:disabled {

    background-color: #ebe9e6;
    color: #a3a2a0;

}
._1JdD97nX-WGcPASVXFJXKw:disabled {

    cursor: not-allowed;

}
._1xPD7V9facbrjoKQLjyWQL {

    display: flex;

}



._1CwjZK_2Wbc4SetuxezVf9 {

    font-family: var(--font-mont);
    line-height: 20px;
    font-size: 16px;
    font-weight: 900;
    background-color: var(--color-black) !important;
    border-radius: 4px;
    color: var(--color-white);
    padding: 12px 24px;
    padding-bottom: 50px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .25s ease,background-color .25s ease;

}
._2s2DfQ5MrOu2S-UPSvVNvV {

    width: 100%;

}

._1JdD97nX-WGcPASVXFJXKw {

    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-sizing: border-box;
    cursor: pointer;
	font-size: 18px;
}


._15EJq4yLpZOO3b1FQ32X32 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

._2T1auHz6PdoVoXHFFUbElm {
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray2);
  margin-top: 0;
  margin-bottom: 0;
}

._3sB4RNUJzURH4mii1IcjPI {
  margin: auto 0 auto auto;
}

._2jB9r-9rzlZqqWUH1E8muO {

    opacity: 50%;

}

._3CTlUEiPeJKa55n-Ucpoki {

    font-family: var(--font-mont);
    line-height: 20px;
    font-size: 20px;
    font-weight: 900;
    background: var(--color-white);
    color: var(--color-black);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    line-height: 20px !important;
    border-radius: 100%;
}


._1pclOaC04dlaY0UHl3zyWt {
  overflow-y: auto;
  width: 100%;
}


.B7a69Q2IoGnNLBxAiDcbR:last-child
{
  border-bottom-width: 0;
  padding-bottom: 0;
}

.B7a69Q2IoGnNLBxAiDcbR {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
    padding-bottom: 15px;
  border-bottom: 1px solid #ebe9e6;
    border-bottom-width: 1px;
}

._25s2omhUhQfcHCtikP0XBk {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  font-family: var(--font-mont);
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
}


._8Tpso4fqXWiM2ktK2uB2K {

    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 12px;
    font-family: var(--font-mont);
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-white);
    background: var(--color-black);

}

._28IBU17s5t7XL-a2xuGSRc {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  max-width: calc(100% - 44px);
}

._3KjnRbm5_nWZGCD1uQPzjP {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.h8oaw1War6h7kCEs3RATY {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


._3KsO2l-LNdW3dDO2uuHK6N {

    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    min-height: 32px;
    padding-top: 4px;
    font-family: var(--font-mont);
    line-height: 22px;
    font-size: 14px;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-black);

}

._3eybxaF9nXFCPI55vgY_Gx {
  display: flex;
  align-items: center;
  height: 32px;
  margin-left: 10px;
  font-family: var(--font-mont);
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-black);
}
._3qdtGWGgRqWfao-VslXx0u {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

._3ylDuZwTTDugvTa4GQcSov {
  display: flex;
  flex-direction: column;
}

.MIwqEkDopWWLPJ0sgK7Sa {
  margin: 1px 0;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}

._30zFN38AZ0D4RcGWr47sgk {
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #525150;
  display: flex;
  word-break: break-all;
}

._2w2jqstPutCrrmGTTRZg5X:not(:last-child) {
  margin-right: 12px;
}
._2w2jqstPutCrrmGTTRZg5X {
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #525150;
  text-decoration: underline;
  border: none;
  background: 0 0;
  margin: 4px 0 0;
    margin-right: 0px;
  padding: 0;
  cursor: pointer;
  min-width: 0px ;
}

._1CwjZK_2Wbc4SetuxezVf9:hover:disabled, ._1CwjZK_2Wbc4SetuxezVf9:disabled {
  background-color: #ebe9e6;
  color: #a3a2a0;
}
._1JdD97nX-WGcPASVXFJXKw:disabled {
  cursor: not-allowed;
}











p{
	padding: 0 !important;
}

ul.addressDisplay {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  position: absolute;
  z-index: 2;
  width: 98%;
}

.addressDisplay li {
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.addressDisplay li:hover {
  background-color: #ccc;
}

.addressDisplay p {
  font-weight: bold;
  margin-top: 20px;
}



@media (min-width: 1100px){
	._2mfDffMxberUHEHnzJbsqf {
	  padding: 50px 0;
	}
	._1wozt5f{
		margin-right: 473px;
	}
}

@media (max-width: 1100px){
	._as2sev {
	  margin-right:-20px;
	}
	
	._ruof1q {
	  padding-left: 0;
	  padding-right: 0;
	}
	.M0cnph9mBkjQ7sKnRJoCn {
	  display: none;
	}
	._6wlw6o {
		margin-right: auto !important;
		margin-bottom: 20px !important;
		margin-left: auto!important;
	}
	._1wozt5f{
		margin-right: 0;
		padding: 0 !important;
		display: flex;
		flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
	}
	._zdt81h {
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 0 !important;
		margin-top: 70px;
	}
	._1dogarg, ._5if1ut{
		max-width: 100%;
		width: 100%;
		border-radius: 0;
	}
	
}

@media (max-width: 768px){
	._ae94fc8bda {
		width: 100% !important;
		position: absolute;
		inset: 0 !important;
		max-height: 100vh !important;
		margin: 0 !important;
	}
	._as2sev {
	  margin-right:0px !important;
	}

	._6wlw6o{
		margin-right:0;
	}	
	
	._1743i26 {
		display: flex;
		flex-direction: column;
		-moz-box-orient: horizontal;
		-moz-box-direction: normal;
		background: var(--color-white);
	} 
	._194c2bm {
	  display: flex;
	  box-sizing: border-box;
	  justify-content: space-between;
	  -moz-box-pack: justify;
	  height: max-content;
	  cursor: pointer;
	  background: var(--color-white);
	  align-items: flex-start;
	  -moz-box-align: flex-start;
	  overflow: hidden;
	  border-radius: 0;
	  border-top: 0;
	  border-left: 0;
	  border-right: 0;
	  margin-right: 0;
	  margin-bottom: 4px;
	}
	.site-layout-background{
		padding: 0 !important;
	}
	
	._1yc6rdp {
		margin: 15px 0px 18px 0px;
	}
	._ruof1q {
		padding-left: 0;
		padding-right: 0;
	}
}


@media (min-width: 1440px){
	._3KsO2l-LNdW3dDO2uuHK6N {

		font-family: var(--font-mont);
		line-height: 24px;
		font-size: 16px;
		font-weight: 600;
		opacity: 0.8;
	}
		
	._8Tpso4fqXWiM2ktK2uB2K {

		font-family: var(--font-mont);
		line-height: 24px;
		font-size: 16px;
		font-weight: 600;

	}
	._25s2omhUhQfcHCtikP0XBk {
	  font-family: var(--font-mont);
	  line-height: 24px;
	  font-size: 16px;
	  font-weight: 500;
	}
	.c5PRj4_tt33kyMy30ibSp {
	  line-height: 32px;
	  font-size: 20px;
	  font-weight: 700;
	}
	._2YWgulDMXDKTMsKU8JdXQs {
	  font-family: var(--font-mont);
	  line-height: 22px;
	  font-size: 16px;
	  font-weight: 600;
	  margin-top: 0;
	  margin-bottom: 0;
	}
	._3CTlUEiPeJKa55n-Ucpoki {

		font-family: var(--font-mont);
		line-height: 22px;
		font-size: 20px;
		font-weight: 900;

	}
	.ThwrxgtcmnwqtSWRYuHD0 {
		margin-right: 104px;
	}
	
}

@media (max-width: 1440px){
	._as2sev {
	  margin-left: 0;
	  margin-right: -20px;
	}
	._ruof1q {
	  padding-left: 0;
	  padding-right: 0;
	  margin-bottom: 40px;
	}
	._15EJq4yLpZOO3b1FQ32X32{
		margin-top: 0px !important;
	}
	._2YWgulDMXDKTMsKU8JdXQs{
		margin-bottom: 2px;
	}
	
}

@media (min-width: 834px){

	._3CTlUEiPeJKa55n-Ucpoki {

		font-family: var(--font-mont);
		line-height: 20px;
		font-size: 20px;
		font-weight: 900;

	}
}

@media (min-width: 768px){

	._238110507a {

		font-family: var(--font-mont);
		line-height: 1.3;
		font-size: 14px;
		font-weight: 400;

	}
	
	._1SEARqWERjoS6g0rgsTmMe {
		font-family: var(--font-mont);
		line-height: 22px;
		font-size: 14px;
		font-weight: 400;
	}

	._da39275192 {

		font-family: var(--font-mont);
		line-height: 1.29;
		font-size: 16px;
		font-weight: 400;

	}
	
	._fc720ca0eb {

		font-family: var(--font-mont);
		line-height: 20px;
		font-size: 16px;
		font-weight: 600;

	}
	
	._5e34f0d7a7 {
	  font-family: var(--font-mont) !important;
	  line-height: 1.25;
	  font-size: 16px;
	  font-weight: 700;
	  margin-top: 2px;
	}
	
	._1CwjZK_2Wbc4SetuxezVf9 {

		font-family: var(--font-mont);
		line-height: 22px;
		font-size: 16px;
		font-weight: 500;

	}	
	
	.bkzPZTxP-TFLWInTcLG0E {
	  font-size: 16px;
	  line-height: 1.25;
	}
	
	._2H1xmkEO8YyPfcJK2JBPRf, ._3beLBLiV4ldT2YmzmGen-7, ._3cy9d0UulZVYEbBYfzpqOI {
	  padding: 0 32px;
	}
	
	.PEPfcZBRW0x-qBmIZymWP {
		font-size: 14px;
		line-height: 1.29;
	}

}
.ant-row {
	display: block !important;
}
.ant-row label {
	font-family: var(--font-base);
    font-weight: 500 !important;
    font-size: 18px !important;
}

.mapupdate{
	border: 1px solid #000;
}
.mapupdate button{
	min-width: 0px !important;
	width: 100%;
}

/* CouponGenerator.css */

/* Form styling */
.form-container {
  margin-bottom: 20px;
}

.form-container label {
  display: block;
  margin-bottom: 10px;
}

.form-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
	margin-bottom: 5px;
}

.form-container button {
  background-color: var(--color-primary);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
	line-height: 0;
}

.form-container button:hover {
  background-color: #0056b3;
}

/* Coupon preview styling */
.coupon-preview {
  margin-top: 20px;
}

.coupon-tile {
  border: 1px solid #000;
  padding: 20px;
  margin-bottom: 20px;
  width: 210mm; /* A4 width */
  height: 297mm; /* A4 height */
  page-break-after: always; /* Ensure each coupon appears on a new page when printing */
}

.company-name {
  font-size: 24px;
  font-weight: bold;
}

.coupon-code, .discount {
  font-size: 18px;
  margin-top: 10px;
}



.adjust-button {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  /*cursor: pointer;*/
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.search-area-button {
  position: absolute;
  top: 10px;
  right: 10px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: var(--color-black);
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  /*cursor: pointer;*/
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}


.ant-modal-wrap {
	overflow: hidden !important;
}

.ant-modal-footer button svg{
	margin-top: -6px;
}


._a30bb3afbc {
  background-color: rgba(204,220,205,0.2);
  height: 100% !important;
  overflow: auto;
  z-index: 35;
  position: relative;
  max-height: 80vh !important;
}

._viewOrderBtn{
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 16px;
  font-weight: 900;
  background-color: var(--color-black) !important;
  border-radius: 4px;
  color: var(--color-white);
  padding: 35px 24px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color .25s ease,background-color .25s ease;
  flex-direction: column;
  bottom: 25px;
  position: fixed;
}

._viewOrderCount{
	margin: -40px 0 auto auto
}

._viewOrderSpan{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.closeViewOrder{
	float: right;
}

@media (max-width: 1440px){
	._viewOrderSpan{
	  margin-top: -19px;	  
	}
}

@media (min-width: 1100px){		
	.M0cnph9mBkjQ7sKnRJoCn{
		width: 473px !important;
	}
	.closeViewOrder{
		display: none;
	}
	._viewOrderBtn{
	  display: none !important;
	}
}


@media (max-width: 1100px){
	._viewOrderBtn{
	  bottom: 25px;
	  width: 500px;
	  left: 50%;
      margin-left: -250px;
	  display: flex !important;
	}
	.M0cnph9mBkjQ7sKnRJoCn{
		width: 100% !important;
	}
	.closeViewOrder{
		display: block;
	}	
}

@media (max-width: 768px){
	._viewOrderBtn{
	  bottom: 25px;
	  width: 300px;
	  left: 50%;
      margin-left: -150px;
	  display: flex !important;
	}	
	.M0cnph9mBkjQ7sKnRJoCn{
		width: 100% !important;
	}
	.closeViewOrder{
		display: block;
	}
}

.current-day-row {
  border-top-color: var(--color-black) !important;
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.ant-table-tbody > tr.current-day-row > td.ant-table-cell-row-hover{
  border-top-color: var(--color-black) !important;
  background: var(--color-black) !important;
  color: var(--color-white) !important;
}

.ant-alert{
	font-family: var(--font-mont);
}

.ant-alert-message{
	font-weight: 900;
}

.alertbutton {
	max-width: 533px; 
	width: 100%; 
	margin-left: auto; 
	margin-right: auto; 
	margin-bottom: 15px;
	display: block;
}
.alertbutton button{
	min-width: 0 !important;
	margin-top: -10px;	
}
.deliverypickup_switch{
  width: 180px;
  height: 40px;
  border: 1px solid #0ecb81;
  line-height: 36px;
}
.deliverypickup_switch > .ant-switch-inner-checked, .deliverypickup_switch > .ant-switch-inner-unchecked{
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
}

.deliverypickup_switch.ant-switch-checked, .deliverypickup_switch.ant-switch-checked:hover, .deliverypickup_switch.ant-switch-checked:focus {
  background: var(--color-black) !important;
}

.deliverypickup_switch .ant-switch-handle {
  position: absolute;
  top: 2px;
  inset-inline-start: 2px;
  width: 34px;
  height: 34px;
  transition: all 0.2s ease-in-out;
}
.deliverypickup_switch .ant-switch-handle::before {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  background-color: #0ecb81;
  border-radius: 18px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: "";
}

.deliverypickup_switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 36px);
}

.deliverypickup_switch .ant-switch-inner {
  display: block;
  overflow: hidden;
  border-radius: 100px;
  font-size: 30px;
}

.deliverypickup_switch .ant-switch-inner .ant-switch-inner-unchecked, .deliverypickup_switch .ant-switch-inner .ant-switch-inner-checked {
  font-size: 16px;
}

._3iQhJxftrPXNqjrlvxWD49 {
  color: #4c4c4c;
  font-size: 12px;
  margin-left: 5px;
  vertical-align: middle;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: none;
  border-radius: 0;
  line-height: 22px;
  margin-top: -5px;
  min-width: 16px !important;
}

._3x3rsGu1COgquP1_9hW0HR {
  height: 14px;
  width: 14px;
}

._2f3eYR_d4YoVfew7WnuhRo[data-active="true"] {
  height: 100%;
  opacity: 100%;
}
._2f3eYR_d4YoVfew7WnuhRo {
  height: 0;
  left: 0;
  margin-top: 12px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 225px;
  transition-duration: .25s;
  transition-property: height,opacity;
  transition-timing-function: ease;
  width: 100%;
}
.yNIm2lvE_j8Du8jBoXORx {
  position: relative;
  margin-bottom: 15px;
}
._2LHIV8HfhKnOukKsXBjlZC {
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #4c4c4c;
  position: relative;
  display: block;
}

._1FZmgafDZ0ZT6CJwZ1-Hbj {
  padding: 15px 25px;
  font-family: var(--font-mont);
  line-height: 1.29;
  font-size: 14px;
  font-weight: 400;
  color: #4c4c4c;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  transition: border-color .125s ease;
}

._7tUKIqpIbqdY6qltkAg4E {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 18px;
}


.VKqQFeqRw6Q1CqdO_U_lC {
  font-family: var(--font-mont);
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #697280;
  margin-top: 11px;
}

._1HwqfywdQZ6aGbCZptSHjl {
  color: #6a6e78;
  height: 10px;
  margin-left: 6px;
  width: 8px;
}

/* Set outline to none for the map and marker elements */
.gm-style div[tabindex="0"]:focus, .gm-style div:focus, .gm-style:focus {
  outline: none !important;
}
.gm-style img, iframe {
  outline: none  !important;
}

.restaurant-map-wrapper div:hover, .restaurant-map-wrapper div:focus,  .restaurant-map-wrapper iframe:hover,  .restaurant-map-wrapper iframe:focus {
	outline: none  !important;
}  

.payButton svg {
	margin-top: -7.5px !important;
}
.payButton {
	margin-left: 10px !important;
}

.app__navbar-login>div, .delivery_app__navbar-login>div {
  background: var(--color-primary);
  height: 30px;
  width: 1px;
}

.backShop .anticon{
	vertical-align: 0em;
}

.floatButton div.ant-float-btn-body, .floatButton div.ant-float-btn-body:hover{
	background-color: var(--color-primary);
}

.floatButton svg{
	color: #ffffff;
}

a {
	cursor: pointer;
}















header#header-bar.absolute-header.light .nav-primary .nav li a {

    color: #1f2960;

}
header#header-bar.header-fixed-top .nav-primary .nav li a, header#header-bar.light .nav-primary .nav li a {

    color: #1f2960;

}

header#header-bar.header-fixed-top, header#header-bar.light {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 150;
}

header#header-bar {
  transition: .2s ease;
}
header {
  background: transparent;
  box-shadow: transparent;
}
section.pt-lg-5{
	padding: 0 !important;
}

.project-content {
  font-family: var(--font-mont);
  font-weight: 300;
  color: #1f2960;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: .46px;
  background: #f8f8f8;
}
header .brand, header .project-container {
  position: relative;
}
@media (min-width: 1200px){
.project-container {
  max-width: 1140px;
}
}

@media (min-width: 992px){
.project-container {
  max-width: 960px;
}
}
@media (min-width: 768px){
.project-container {
  max-width: 720px;
}
}
@media (min-width: 576px){
.project-container {
  max-width: 540px;
}
}
.project-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

header #topbar {
  padding-left: 15px;
  padding-right: 15px;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}


[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}
[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}
[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}
[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}
[data-aos^="fade"][data-aos^="fade"] {
  opacity: 1;
  transition-property: opacity,transform;
}
header .brand {
  z-index: 10;
}
header .brand, header .project-container {
  position: relative;
}

.nav-primary {

    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .2 ease;

}

.nav-primary .nav {
  margin-left: -15px;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-primary .nav li {
  position: relative;
  transition: all .2s ease-in-out;
}
.nav-primary .nav li a {

    position: relative;
    color: #fff;
    padding: 55px 40px;
    display: inline-block;
    font-family: Montserrat,sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.57px;
    text-align: center;
    text-transform: uppercase;

}

.nav-primary .nav li:last-child a {
  background-color: #ffc425;
  color: #1f2960;
}

#content {
  min-height: 100vh;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;

}

@media (min-width: 992px){
.pt-lg-5, .py-lg-5 {
  padding-top: 1rem !important;
}
.mt-lg-5, .my-lg-5 {
  margin-top: 1rem !important;
}
}

.post-type-archive-projects #projects-list {

    margin-top: 250px;

}
#projects-list {

    margin-top: 150px;
    margin-bottom: 30px;

}

@media (min-width: 576px){

.project-container {

    max-width: 540px;

}
}
@media (min-width: 768px){

.project-container {

    max-width: 720px;

}
}

@media (min-width: 992px){

.project-container {

    max-width: 960px;
}
}

@media (min-width: 1200px){

.project-container {

    max-width: 1140px;
}
}

.project-container {

    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}



#projects-list .project-section-title {
  margin: 150px 0;
}

.project-h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.65;
  letter-spacing: 1.61px;
  font-weight: 900;
  letter-spacing: 1px;
  color: inherit;
  font-family: Montserrat,sans-serif;
  padding: 0;
  margin-bottom: 0.5em;
}

.project-h6:before {
  content: "";
  width: 100px;
  height: 1px;
  display: block;
  margin-right: 20px;
  border-bottom: 1px solid #1f2960;
}

.project-h6 {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 3.96px;
}

#projects-list .project-row .text-info {
  padding: 80px;
}
@media (max-width: 991.98px){
#projects-list .project-row .text-info {
  padding: 30px 0;
}
}

#projects-list .project-row .title {
  text-transform: uppercase;
}

#projects-list .project-row .text-info .categories {
  margin-bottom: 15px;
  display: inline-block;
}

#projects-list .project-row .text-info .tag {
  padding: 7.5px 12.5px;
  border-radius: 3px;
  border: 1px solid #7f86ac;
  color: #1f2960;
}

#projects-list .project-row {
  margin: 80px -15px;
  align-items: center;
}

#projects-list .project-row .image:before {
  content: "";
  display: block;
  width: calc(100% - 60px);
  height: 82px;
  opacity: .1;
  border-radius: 3px;
  filter: blur(33px);
  background-color: #000;
  position: absolute;
  top: calc(100% - 41px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}


#projects-list .project-row .image img {
    border-radius: 8px;
    z-index: 2;
    position: relative;
}

.project-row {
  display: flex !important;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.btn-outline-secondary {
  border: 1px solid #7f86ac;
  color: #1f2960;
  background: transparent;
  border-radius: 5px;
  padding: 18px 36px;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: .6px !important;
  font-family: Montserrat,sans-serif;
  text-transform: uppercase;
}

.project-p {
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: .46px;
  color: #525c79;
  font-weight: 400;
}
.project-p {
  margin-top: 0;
  margin-bottom: 3rem !important;
}

@media (min-width: 992px){
.project-p {
  font-size: 16px;
}
}


.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {

    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

}

@media (min-width: 992px){
.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}
}

[data-aos="fade-up"] {
    transform: translate3d(0,100px,0);
}

.img-fluid, .img-thumbnail, .wp-caption img {
    max-width: 100%;
    height: auto;
}
img, svg {
    vertical-align: middle;
}
img {
    border-style: none;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.text-info {
  color: #1f2960 !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.search-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.search-input {
  width: 70%;
  padding: 20px 70px 20px 20px;
  border-radius: 50px;
  border: none;
  font-size: 1.5rem;
  text-align: center;
  outline: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  font-family: var(--font-mont);
}

.submit-button {
  position: relative;
  margin-left: -78px;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  background-color: #0ecb81;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: "";
  height: 60px;
  width: 60px !important;
  color: #ffffff;
}

.submit-button .anticon {
  margin-top: -1px;
  font-size: 1.5rem;
  font-weight: 900;
}


@media (max-width: 768px) {
  .search-input {
    width: 90%;
    font-size: 1.2rem;
  }
}

.unavailableItem, .unavailableItemImg{
	background-color: rgba(0, 0, 0, 0.4);
	background-blend-mode: multiply;
	cursor: default;
}
.unavailableItem:after{
	content: "NOT AVAILABLE";
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
	margin-left: -120px;
	margin-top: 40px;
}

/* RestrictedPage.css */

.restricted-page-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.welcome-message {
  color: #007bff;
}

.restricted-message {
  color: #dc3545;
  text-align: center;
}

.restricted-message p {
  color: #6c757d;
}
