.app__footer {
	width: 100% !important;
	position: relative !important;
	z-index: 1 !important;
	
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
	flex-direction: column !important;
	
	background: var(--color-black) !important;
	padding-top: 0 !important;
}

.app__footer-links {
	width: 100% !important;
	display: flex !important;
	justify-content: space-between !important;
	align-items: flex-start !important;
	
	margin-top: 5rem !important;
	padding: 0 2rem !important;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
	flex: 1 !important;
	margin: 1rem !important;
	text-align: center !important;
}

.app__footer-links_logo img:first-child {
	width: 210px !important;
	margin-bottom: 0.75rem !important;
}


.app__footer-links_work p:nth-child(2n + 1) {
	margin-bottom: 1rem !important;
}

.app__footer-links_icons {
	margin-top: 0.5rem !important;
}

.app__footer-links_icons svg {
	color: var(--color-white) !important;
	margin: 0.5rem !important;
	font-size: 24px !important;
	cursor: pointer !important;
}

.app__footer-links_icons svg:hover {
	color: var(--color-golden) !important;
}

.app__footer-headtext {
	font-family: var(--font-base) !important;
	color: var(--color-white) !important;
	font-weight: 400 !important;
	letter-spacing: 0.04em !important;
	text-transform: capitalize !important;
	font-size: 32px !important;
	line-height: 41.6px !important;
	margin-bottom: 1rem !important;
}

.footer__copyright {
	margin-top: 3rem !important;
}

@media screen and (min-width: 2000px) {
	.app__footer-headtext {
		font-size: 51px !important;
		line-height: 61.6px !important;
	}
}

@media screen and (max-width: 1150px) {
	.app__footer-links {
		align-items: center !important;
		flex-direction: column !important;
		padding: 0 !important;
	}
	
	.app__footer-links_contact,
	.app__footer-links_logo,
	.app__footer-links_work {
		margin: 2rem 0 !important;
		width: 100% !important;
	}
}

@media screen and (max-width: 650px) {
	.app__footer {
		padding: 0 0 2rem 0 !important;
	}
}

@media screen and (max-width: 350px) {
	.app__footer-links_logo img:first-child {
		width: 80% !important;
	}
}









.pg-footer {
  font-family: var(--font-mont) !important;
}

.footer {
	background-color: var(--color-black) !important;
	color: var(--color-white) !important;
	padding: 0 !important;
}
.footer-wave-svg {
	background-color: transparent !important;
	display: block !important;
	height: 30px !important;
	position: relative !important;
	top: -1px !important;
	width: 100% !important;
}
.footer-wave-path {
	fill: #f8f8f8 !important;
}

.footer-content {
	margin-left: auto !important;
	margin-right: auto !important;
	margin-bottom: 200px !important;
	max-width: 1230px !important;
	padding: 40px !important;
	position: relative !important;
}

.footer-content-column {
	box-sizing: border-box !important;
	float: left !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	width: 100% !important;
	color: var(--color-white) !important;
}

.footer-content-column ul li a {
  color: #fffff2 !important;
  text-decoration: none !important;
}

.footer-logo-link {
	display: inline-block !important;
}
.footer-menu {
	margin-top: 30px !important;
}

.menu-item a{
	font-family: var(--font-mont) !important;
	
}

.footer-menu-name {
	color: var(--color-primary) !important;
	font-size: 15px !important;
	font-weight: 900 !important;
	letter-spacing: .1em !important;
	line-height: 18px !important;
	margin-bottom: 0 !important;
	margin-top: 0 !important;
	margin-left: -15px !important;
	text-transform: uppercase !important;
}
.footer-menu-list {
	list-style: none !important;
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	padding-left: 0 !important;
}
.footer-menu-list li {
	margin-top: 5px !important;
}

.footer-call-to-action-description {
	color: var(--color-white) !important;
	margin-top: 10px !important;
	margin-bottom: 20px !important;
}
.footer-call-to-action-button:hover {
	background-color: var(--color-white) !important;
	color: #00bef0 !important;
}
.button:last-of-type {
	margin-right: 0 !important;
}
.footer-call-to-action-button {
	background-color: var(--color-primary2) !important;
	border-radius: 21px !important;
	color: var(--color-white) !important;
	display: inline-block !important;
	font-size: 11px !important;
	font-weight: 900 !important;
	letter-spacing: .1em !important;
	line-height: 18px !important;
	padding: 12px 30px !important;
	margin: 0 10px 10px 0 !important;
	text-decoration: none !important;
	text-transform: uppercase !important;
	transition: background-color .2s !important;
	cursor: pointer !important;
	position: relative !important;
}
.footer-call-to-action {
	margin-top: 30px !important;
}
.footer-call-to-action-title {
	color: var(--color-white) !important;
	font-size: 14px !important;
	font-weight: 900 !important;
	letter-spacing: .1em !important;
	line-height: 18px !important;
	margin-bottom: 0 !important;
	margin-top: 0 !important;
	text-transform: uppercase !important;
}
.footer-call-to-action-link-wrapper {
	margin-bottom: 0 !important;
	margin-top: 10px !important;
	color: var(--color-white) !important;
	text-decoration: none !important;
}
.footer-call-to-action-link-wrapper a {
	color: var(--color-white) !important;
	text-decoration: none !important;
}





.footer-social-links {
	bottom: 0 !important;
	height: 54px !important;
	position: absolute !important;
	right: 0 !important;
	width: 236px !important;
}

.footer-social-amoeba-svg {
	height: 54px !important;
	left: 0 !important;
	display: block !important;
	position: absolute !important;
	top: 0 !important;
	width: 236px !important;
}

.footer-social-amoeba-path {
	fill: #027b9a !important;
}

.footer-social-link.linkedin {
	height: 26px !important;
	left: 3px !important;
	top: 11px !important;
	width: 26px !important;
}

.footer-social-link {
	display: block !important;
	padding: 10px !important;
	position: absolute !important;
}

.hidden-link-text {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px) !important;
	clip: rect(1px,1px,1px,1px) !important;
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%) !important;
	clip-path: inset(0px 0px 99.9% 99.9%) !important;
	overflow: hidden !important;
	height: 1px !important;
	width: 1px !important;
	padding: 0 !important;
	border: 0 !important;
	top: 50% !important;
}

.footer-social-icon-svg {
	display: block !important;
}

.footer-social-icon-path {
	fill: var(--color-white) !important;
	transition: fill .2s !important;
}

.footer-social-link.twitter {
	height: 28px !important;
	left: 62px !important;
	top: 3px !important;
	width: 28px !important;
}

.footer-social-link.youtube {
	height: 24px !important;
	left: 123px !important;
	top: 12px !important;
	width: 24px !important;
}

.footer-social-link.github {
	height: 34px !important;
	left: 172px !important;
	top: 7px !important;
	width: 34px !important;
}

.footer-copyright {
	background-color: var(--color-black) !important;
	color: var(--color-white) !important;
	padding: 15px 30px !important;
	text-align: center !important;
}

.footer-copyright-wrapper {
	margin-left: auto !important;
	margin-right: auto !important;
	max-width: 1200px !important;
}

.footer-copyright-text {
	color: var(--color-white) !important;
	font-size: 18px !important;
	font-weight: 400 !important;
	line-height: 18px !important;
	margin-bottom: 0 !important;
	margin-top: 0 !important;
}

.footer-copyright-link {
	color: var(--color-white) !important;
	text-decoration: none !important;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 650px !important;
    max-width: 1230px !important;
    padding: 40px !important;
    position: relative !important;
	}
}
@media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 650px !important;
    max-width: 1230px !important;
    padding: 40px !important;
    position: relative !important;
	}
}
@media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 200px !important;
    max-width: 1230px !important;
    padding: 40px !important;
    position: relative !important;
	}
}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
	
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
	
}
@media (min-width:1281px) { /* hi-res laptops and desktops */
	
}




@media (min-width: 760px) {
  .footer-content {
		margin-left: auto !important;
		margin-right: auto !important;
		margin-bottom: 200px !important;
		max-width: 1230px !important;
		padding: 40px !important;
		position: relative !important;
	}
	
  .footer-wave-svg {
		height: 50px !important;
	}
	
  .footer-content-column {
		width: 24.99% !important;
	}
}
@media (min-width: 568px) {
  /* .footer-content-column {
		width: 49.99% !important;
	} */
}
